
  .dropdown {
    cursor: pointer;
    user-select: none;
  }

  .no-padding-top {
    padding-top: 0px !important;
    padding-bottom: 0.375rem !important
  }

  .dashboard-ul {
    background-color: #2a2a2e !important;
    color: white !important;

  }
