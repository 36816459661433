/*
=====================
    Basic
=====================
*/

.timeline-line .item-timeline {
  display: flex;

  .t-dot {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      border: 2px solid $info;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      top: 15px;
      left: 50%;
      transform: translateX(-50%);
    }

    &:after {
      content: '';
      position: absolute;
      border-left: 2px solid $info;
      transform: translateX(-50%);
      width: 0;
      height: auto;
      top: 25px;
      left: 50%;
      bottom: -15px;
      border-radius: 0;
    }

    &.t-dot-primary:before {
      border-color: #1b55e2;
    }

    &.t-dot-success:before {
      border-color: #8dbf42;
    }

    &.t-dot-warning:before {
      border-color: $warning;
    }

    &.t-dot-info:before {
      border-color: $info;
    }

    &.t-dot-danger:before {
      border-color: $danger;
    }

    &.t-dot-dark:before {
      border-color: $dark;
    }

    &.t-dot-primary:after {
      border-color: #1b55e2;
    }

    &.t-dot-success:after {
      border-color: #8dbf42;
    }

    &.t-dot-warning:after {
      border-color: $warning;
    }

    &.t-dot-info:after {
      border-color: $info;
    }

    &.t-dot-danger:after {
      border-color: $danger;
    }

    &.t-dot-dark:after {
      border-color: $dark;
    }
  }

  &:last-child .t-dot:after {
    display: none;
  }

  .t-meta-time {
    margin: 0;
    min-width: 100px;
    max-width: 100px;
    font-size: 12px;
    font-weight: 700;
    color: $m-color_6;
    align-self: center;
  }

  .t-text {
    padding: 10px;
    align-self: center;
    margin-left: 10px;

    p {
      font-size: 13px;
      margin: 0;
      color: $dark;
      font-weight: 600;

      a {
        color: #1b55e2;
        font-weight: 600;
      }
    }
  }

  .t-time {
    margin: 0;
    min-width: 58px;
    max-width: 100px;
    font-size: 16px;
    font-weight: 600;
    color: $dark;
    padding: 10px 0;
  }

  .t-text .t-meta-time {
    margin: 0;
    min-width: 100px;
    max-width: 100px;
    font-size: 12px;
    font-weight: 700;
    color: $m-color_6;
    align-self: center;
  }
}
