
.pw-changed {
    display: block;
    font-weight: bold;
}

.bold-header {
    font-weight: bold;
}

.pw-reseted {
    display: block;
    padding-top: 10px;
    font-size: 12px;
}

.bold-green {
    font-weight: bold;
    color: limegreen;
}


