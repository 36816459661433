


/* Tab content animation */
.tab-content {
    display: flex; // to avoid horizontal scroll when animating
    .tab-pane {
        display: block;
        animation: fadeIn 0.5s;
        width: 100%;
    }
}

/**
      Extra niceties. Display error tabs and disable navigation unvisited tabs
     */
.wizard-navigation .nav-link {
    &.active,
    &.checked {
        cursor: pointer;
    }
}

.disabled-wizard-link {
    cursor: not-allowed;
}

.card-wizard {
    .card-header {
        padding-bottom: 10px;
    }
}

@mixin transitions($time, $type) {
    -webkit-transition: all $time $type;
    -moz-transition: all $time $type;
    -o-transition: all $time $type;
    -ms-transition: all $time $type;
    transition: all $time $type;
}

@mixin set-wizard-color($color) {
    .progress-with-circle .progress-bar {
        background: $color;
    }

    .nav-pills .nav-item .nav-link {
        color: $color;

        &.checked, &.active {
            background: $color;
            color: white;
        }

        &:hover {
            background: white;
        }
    }

    .nav-pills .nav-item .nav-link.active,
    .nav-pills .nav-item .nav-link.checked:focus,
    .nav-pills .nav-item .nav-link.checked:hover,
    .nav-pills .nav-item .nav-link.active:focus,
    .nav-pills .nav-item .nav-link.active:hover {
        background: $color;
    }

}

.card-wizard {
    min-height: 410px;
    opacity: 0;
    @include transitions(300ms, linear);

    &.active {
        opacity: 1;
    }


    @include set-wizard-color(#9acccb);

    &[data-color="primary"] {
        @include set-wizard-color(#4e73df);
    }

    &[data-color="green"] {
        @include set-wizard-color(darken(#00f2c3, 10%));
    }

    &[data-color="blue"] {
        @include set-wizard-color(#9acccb);
    }

    &[data-color="red"] {
        @include set-wizard-color(#fd5d93);
    }

    &[data-color="orange"] {
        @include set-wizard-color(#ff8d72);
    }

    .nav-pills .nav-item {
        position: relative;

        .nav-link {
            height: 45px;
            width: 45px;
            min-width: 45px;
            border-radius: 50% !important;
            position: absolute;
            left: 50%;
            top: -20px;
            transform: translate(-50%);
            background-color: lightgray;

            p {
                position: absolute;
                top: 110%;
                left: 50%;
                transform: translate(-50%);
                font-weight: 400;
            }

            &:hover, &:focus {
                background-color: #86eae9 !important;
                color: white !important;
            }

        }


    }

    .nav-pills .nav-item .nav-link.active,
    .nav-pills .nav-item .nav-link:hover,
    .nav-pills .nav-item .nav-link:focus,
    .nav-pills .nav-item .nav-link.active:focus,
    .nav-pills .nav-item .nav-link.active:hover {
        box-shadow: none;
    }

    .picture-container {
        position: relative;
        cursor: pointer;
        text-align: center;
    }

    .card-body {
        background: transparent;
        border-top-left-radius: 0.4285rem;
        border-top-right-radius: 0.4285rem;
    }

    .card-footer {
        background-color: transparent;
        border-bottom-left-radius: 0.4285rem;
        border-bottom-right-radius: 0.4285rem;


        .pull-right {
            padding-right: 80px;
        }

        .pull-left {
            padding-left: 80px;
        }
    }

    .wizard-navigation {
        position: relative;
        margin: 80px auto 60px;

        .nav-link {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 0;
            padding-right: 0;
        }

        .progress-with-circle {
            position: relative;
            top: 5px;
            height: 5px;
            border-radius: 0.4285rem;

            .progress-bar {
                height: 100%;
                border-radius: 0.4285rem;
                box-shadow: none;
                -webkit-transition: width .3s ease;
                -o-transition: width .3s ease;
                transition: width .3s ease;
            }
        }
    }

    .wizard-navigation .nav-link i,
    .moving-tab i {
        display: inline-block;
        font-size: 19px;
        line-height: initial;
        padding: 0;
        vertical-align: bottom;
    }

    .picture {
        width: 106px;
        height: 106px;
        background-color: #999999;
        border: 1px solid lightgray;
        color: #FFFFFF;
        border-radius: 50%;
        margin: 5px auto;
        overflow: hidden;
        transition: all 0.2s;
        -webkit-transition: all 0.2s;

        &:hover {
            border-color: #2ca8ff;
        }
    }


    .picture input[type="file"] {
        cursor: pointer;
        display: block;
        height: 100%;
        left: 0;
        opacity: 0 !important;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .picture-src {
        width: 100%;
    }

    .tab-content {
        display: block;
    }

    .wizard-footer {
        padding: 0 15px;

        .checkbox {
            margin-top: 16px;
        }
    }


    .wizard-header {
        text-align: center;
        padding: 25px 0 35px;

        h5 {
            margin: 5px 0 0;
        }
    }

    .nav-pills > li {
        text-align: center;
    }

    .btn {
        text-transform: uppercase;
    }

    .info-text {
        text-align: center;
        font-weight: 300;
        margin: 10px 0 30px;
    }

    .choice {
        text-align: center;
        cursor: pointer;
        margin-top: 20px;

        &[disabled] {
            pointer-events: none;
            cursor: not-allowed;
            opacity: .5;
        }

        .icon {
            text-align: center;
            vertical-align: middle;
            height: 116px;
            width: 116px;
            border-radius: 50%;
            color: #9ACCCB;
            margin: 0 auto 20px;
            border: 1px solid lightgray;
            transition: all 0.2s;
            -webkit-transition: all 0.2s;
        }

        i {
            font-size: 30px;
            line-height: 116px;
            position: absolute;
            left: 0;
            right: 0;
        }

        &:hover,
        &.active {
            .icon {
                border-color: #2ca8ff;
            }
        }

        input[type="radio"],
        input[type="checkbox"] {
            position: absolute;
            left: -10000px;
            z-index: -1;
        }
    }

    .btn-finish {
        display: none;
    }

    .card-title + .description {
        font-size: 17px;
        margin-bottom: 32px;
    }

    .wizard-title {
        margin: 0;
    }

    .nav-pills {
        background-color: white;
        border-radius: 0.4285rem;
        height: 5px;

        > li + li {
            margin-left: 0;
        }

        > li > a {
            border: 0 !important;
            border-radius: 0;
            line-height: 18px;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 500;
            min-width: 100px;
            text-align: center;
            color: #555555;
        }

        > li.active > a,
        > li.active > a:hover,
        > li.active > a:focus,
        > li > a:hover,
        > li > a:focus {
            background-color: inherit;
            box-shadow: none;
        }

        > li i {
            display: block;
            font-size: 30px;
            padding: 15px 0;
        }
    }

    .input-group.has-danger {
        .error {
            display: block;
            width: 100%;
            margin-top: 3px;
        }
    }
}

.icon-size {
    font-size: 20px;
}


