
.warning-text {
    display: flex;
    color: #FFB92A;
    margin-top: 5px;
}

.triangle {
    margin-top: 3px;
    margin-right: 10px;
    font-size: 1.3em;
}


.marquee {
    max-width: 100vw; /* iOS braucht das */
    white-space: nowrap;
    overflow: hidden;
    /* hier evtl. noch font-size, color usw. */
    color: #FFB92A;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 1.3em;
}


.marquee span {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 25s linear infinite;
}

/* Optional: mouseover (oder Tipp auf dem Touchscreen) pausiert die Laufschrift */
.marquee span:hover {
    animation-play-state: paused
}

/* Make it move */
@keyframes marquee {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

@media (max-width: 575px) {
    .btn-goTo {
        display: none;
    }
}
