

.custom-table {
    background: transparent;
}

.btn svg {
    margin-right: 8px;
}

.dark {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: white;
    --popper-theme-border-width: 0px;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 15px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

:deep(.popper #arrow::before) {
    left: 0px;
}

.popOverText {
    color: white;
    font-size: 14px;
    font-weight: 500;
}

.margin-left-5 {
    margin-left: 1px !important;
}

