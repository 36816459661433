
.no-scroll {
    overflow: hidden;
}

.gray-color {
    color: #858796 !important;
}

.gray-color:hover {
    color: #2E63BF !important;
}

.edit-account-wrap {
    position: absolute;
    display: block;
    width: calc(100% - 32px);
    height: calc(100vh - 106px);
    left: 0;
    margin-top: 10px;
    overflow: auto;
}

.col-td {
    width: 110px;
    text-align: center;
    white-space: nowrap;
}

.col-highlight {
    width: 200px;
    font-weight: bold;
}

