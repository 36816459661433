

.number-width {
    max-width: 80px;
}

.postcode-width {
    max-width: 140px;
}

.font-size-14 {
    font-size: 14px !important;

}

