

.dashboard {
    display: block;
    width: 100%;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: Auto;
}

.left-row {
    float: left !important;
    width: 50% !important;
    margin-left: 10px;
    padding-right: 10px;
}

.right-row {
    float: right !important;
    width: 50% !important;
    margin-right: 10px;
    padding-left: 10px;
}

.flex-item {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    width: 100% !important;
    margin-top: 20px;
    border: 1px solid #9acccb;
    border-radius: 6px;
}

.flex-item-left {
    padding-right: 0px !important;
}


.flex-item-right {
    padding-left: 0px !important;
}

.left-div {
    width: 100%
}

.right-div {
    width: 100%
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.title {
    font-weight: 600;
}

.counter-font-icon {
    height: 60px;
    width: 60px;
    top: 30%;
    position: relative;
    margin-left: 10px;
}

.mint {
    color: #9acccb;
}
