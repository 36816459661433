


.spacing-10 {
    @media (max-width: 991px) {
        margin-top: 20px;
    }

    @media (min-width: 992px) {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.spacing-remember {
    margin: 17px 5px;
    word-wrap: break-word;
    font-size: 12px;
}

.opacity {
    opacity: 100;
}

.auth-boxed {
    .form-form {
        .form-form-wrap {
            min-height: 100%;
        }
    }
}

.custom-checkbox .custom-control-label::before, .custom-checkbox .custom-control-label::after {
    top: 0rem;
    margin-top: 10px;
    margin-right: 5px;
}


