

.list-margin {
    padding-right: 10px;
    padding-left: 10px;
}

.icon-color {
    margin-right: 15px;
    margin-left: 10px;
    min-width: 30px;
    min-height: 30px;
}

.dark {
    .icon-color {
        color: #ffffff;
    }


    .list-text {
        color: #ffffff;
    }
}

.list-height {
    min-height: 60px;
    border-bottom: 1px solid #ffffff;
}

