body {
  font-family: 'Poppins' !important;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: #9ACCCB;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}


.bubbles {
  border: 1px solid #9acccb;
  padding: 5px;
  border-radius: 26px;
  background: #9acccb;
  color: black;
  font-weight: 600;
  margin: 10px;
  white-space: nowrap;
}

.error-icon {
  margin-right: 8px;
}

.bubbles-yellow {
  border: 1px solid #fdb82a;
  padding: 5px;
  border-radius: 26px;
  background: #fdb82a;
  color: black;
  font-weight: 600;
  margin: 10px;
  white-space: nowrap;
}


.bubble-div {
  display: flex;
  flex-wrap: wrap;
}

.bubble-yellow-div {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-left: 20px;
}

.table {

  tbody > tr {

    > td {

      &:hover {
        cursor: pointer !important;
      }
    }
  }
}

.dark {
  .search-box-label {
    label {
      color: black !important;
    }
  }
}

.search-box-label {
  label {
    color: black !important;
  }
}


hr {
  display: block;
  height: 1.5px;
  border: 0;
  border-top: 2px solid #ccc;
  margin: 1em 1em;
  padding-left: 5px;
  padding-right: 5px;
}

.disabled-overlay-advice {
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  padding-top: 42.5px
}

.purple,
.dark {
  .disabled-overlay-advice {
    color: #ffffff;
  }
}


.section-heading {
  padding: 10px;
}

.profile-picture {
  max-width: 200px;
  max-Height: 200px;
}

.profile-picture-small {
  max-width: 150px;
  max-Height: 150px;
}

.profile-picture-medium {
  max-width: 250px;
  max-Height: 250px;
}

.profile-picture-top {
  max-width: 400px;
  max-Height: 400px;
}

.profile-picture-office {
  max-width: 600px;
  max-Height: 600px;
}


.margin-top-30 {
  margin-top: 30px;
}

.btn-login {
  margin: 10px 30px !important;
  font-weight: 600;
  background-color: #9ACCCB;
  border-radius: 28px;
  border: none;
  font-size: 16px;
  max-width: 160px;
  color: black;

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background-color: #9ACCCB;
    color: black;
  }
}


.btn-yellow {
  background-color: #FFB92A !important;
  color: black !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  border-radius: 28px !important;
  background-image: none !important;

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background-color: #f6d58b !important;
  }
}

.btn-red {
  background-color: #E7515A !important;
  color: black;
  font-size: 16px;
  border-radius: 28px;
  border-color: #E7515A !important;

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background-color: #f39c9c !important;
  }
}

.btn-blue {
  background-color: #549190 !important;
  color: black;
  font-size: 16px;
  border-radius: 28px;

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background-color: #75cccb !important;
  }
}

.btn-mint {
  background-color: #9ACCCB !important;
  color: black;
  font-size: 16px;
  font-weight: 700 !important;
  border-radius: 28px;
  border-color: #9ACCCB !important;

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background-color: #d6fcf7 !important;
  }
}


.btn-just-icon {
  margin: 10px 10px !important;
  font-weight: 600;
  background-color: #9ACCCB;
  border-radius: 28px;
  border: none;
  font-size: 16px;
}

.btn-rounding {
  border-radius: 28px;
}

.user {
  @media (min-width: 700px) {
    display: flex;
  }

  @media (min-width: 1200px) {
    display: flex;
    min-width: 50%;
  }

}


.auto-login-wrap {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 100%;
}

.auto-login {
  color: #76B82A;
  font-size: 2em;
}

.spacing-10 {
  margin: 10px 15px;
  min-width: 180px;
}

.spacing-remember {
  margin: 17px 5px;
  word-wrap: break-word;
  font-size: 12px;
}


.btn-just-icon-2 {
  margin: 10px 10px !important;
  font-weight: 400;
  min-width: 140px;
  background-color: #9ACCCB;
  border-radius: 28px;
  border: none;
  font-size: 16px;

}

.flex-28-m10 {
  display: flex;
  font-size: 28px;
  margin-bottom: 10px;
}

.remember-me {
  padding-top: 8px;
  padding-left: 10px;
  margin-left: 10px;
}


.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-left-margin {
  margin-left: 15px !important;
}

.float-right-margin {
  margin-right: 15px !important;
}

.form-group {
  margin: 5px 0px;
}

.help-icon {
  font-size: 1.6em;
  left: -3px !important;

}

.dark {
  .help-icon {
    color: #FFFFFF !important;

  }
}

.input-group-xs > .form-control,
.input-group-xs > .input-group-addon,
.input-group-xs > .input-group-btn > .btn,
.input-group-xs > .input-group-append > .btn,
.input-group-xs > .input-group-prepend > .btn {
  height: 22px;
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 2px;
}


.input-group-ms > .form-control,
.input-group-ms > .input-group-addon,
.input-group-ms > .input-group-btn > .btn,
.input-group-ms > .input-group-append > .btn,
.input-group-ms > .input-group-prepend > .btn {
  height: 24px;
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 2px;
}

.display-flex {
  display: flex;
}

.text-align-left {
  text-align: left !important;
}

.text-align-right {
  text-align: right !important;
}

.input-rounded {
  border-radius: 28px !important;
  font-size: 14px;
}


.input-group-rounded-prepend > span {
  font-size: 14px !important;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  min-width: 12em;
}

.tab-pane.active {
  outline: 0;
}

.funnel-tab > i {
  color: #888888;
}

.btn-silent-submit {
  position: absolute;
  display: block;
  width: 1px;
  height: 1px;
  visibility: hidden;
  top: -99999em;
  left: -999999em;
}

.underlined {
  border-bottom: 1px solid #ddd;
}

.underlined-b-10 {
  border-bottom: 1px solid #9acccb;
  margin-bottom: 10px;
}

.underlined-b-m-20 {
  border-bottom: 1px solid #9acccb;
  margin-bottom: 20px;
  margin-top: 20px;
}

.navbar-right-dropdowns > a:first-child {
  padding-right: 0 !important;
}


.area-button-link {
  display: block;
  width: calc(15vw - 10px);
  min-height: calc(15vw - 10px);
  text-align: center;
  align-content: center;
  justify-content: center;
  border: 1px solid #dddddd;
  margin: 5px;
  transition: background-color .3s, color .3s;
}

.area-button-link:hover {
  background-color: #1E53AF;
  color: #ffffff;
}

.area-button-link-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(15vw - 10px);
  height: calc(15vw - 10px);
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
}


.area-button-link-flex .title, .area-button-link-flex i {
  display: block;
  width: 100%;
  flex-grow: 1;

}

.area-button-link-flex .title {

  font-size: 1.2em;
  margin-bottom: 10px;
}

.required-asterix {
  font-weight: bold;
  color: red;
}

.clickable {
  cursor: pointer;
}

.font-size-14 {
  font-size: 14px;
}


.no-decoration, .no-decoration:hover, .no-decoration:active, .no-decoration:visited {
  text-decoration: none !important;
}


.modal-xxl {
  width: 70vw;
  max-width: 80vw;
}

.modal-xxl .modal-body {
  min-height: 70vh;

}

.modal-l {
  width: 70vw;
  max-width: 70vw;
}

.center-box {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  text-align: center;

}

.nav-link-no-padding > a.nav-link {
  padding: 3px !important;
}

.nav-link.active {
  color: #000000 !important;
}

.nav-link.active:hover {
  color: #9ACCCB !important;
}

.nav-link {
  color: #858796 !important;
}

.nav-link:hover {
  color: #1e53af !important;
}

.dropdown .nav-link.active {
  color: #9acccb !important;
}

.dropdown .nav-link.active:hover {
  color: #9acccb !important;
}

.dropdown .nav-link {
  color: #fff !important;
}

.dropdown .nav-link:hover {
  color: #9acccb !important;
}

.pages-tabs > .card-header {
  display: inline-block;
}


/*sc- stand for standard Container so it is used everywhere for standard forms*/

$menu-width: 0px;
$breadcrumbs-height: 46px;
$bottom-menu-height: 0px;

.sc {
  display: flex;
  flex-direction: column;
  width: calc(100%);
  height: calc(100% - #{$breadcrumbs-height});
  top: 0;
  left: 0;
}

.sc-center-box {
  display: flex;
  width: 100%;
  height: calc(100% - 108px);
  padding: 0;
  margin: 0;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  text-align: center;
}

.sc-root {
  position: relative;
  display: block;
  flex-grow: 1;
}

.sc-wrap {
  position: absolute;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
}

.sc-content {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  flex: 1;
  padding: 10px;
  overflow: auto;
}


.display-flex {
  display: flex;
}

.sc-plus-input-wrap h6 {
  font-size: 12px;
  font-weight: bold;
}

.sc-mini-btn-delete {
  margin-left: 5px;
}

.sc-mini-btn-delete:hover {
  color: #e74a3b;
}


.overflowing {
  overflow-y: auto;
}

.sc-tabs-menu:hover > i {
  opacity: .5;
}

.sc-tabs-menu > li {
  position: absolute;
  display: none;
  right: -20px;
}

.sc-tabs-menu:hover > li {
  display: block;
  z-index: 99999 !important;
}

.breadcrumb-decorate-blue {
  color: #9ACCCB !important;
}

.breadcrumb-decorate-yellow {
  margin-left: 10px;
  color: #fdb82a !important;
}

.breadcrumb-decorate-gray {
  margin-left: 10px;
}

/* Menu container*/


.mc-content {
  margin: 0;
  padding: 0;
  height: 100%;
  float: left;
  height: 100% !important;
  display: inline-block;
  width: calc(100%);
  /*padding: 10px;*/
  transition: height .3s;
  background-color: #ffffff;
  overflow: auto;
}


.margin-bottom-15 {
  margin-bottom: 15px;
}

.inner-pane {
  position: relative;
  display: block;
  padding: 5px;
  margin: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}


// Creditcard infos


.validation-error {
  color: red;
  font-size: 11px;
}

.error-border {
  border-color: red !important;
  border-width: 1px !important;
}

.error-border-select {
  border-color: red !important;
  border-width: 1px !important;
}

.dark {
  .error-border {
    border-color: red !important;
    border-width: 2px !important;
  }

  .error-border-select {
    border-color: red !important;
    border-width: 2px !important;
  }

  .success-border {
    border-color: #28a745 !important;
    border-width: 2px !important;
  }

  .success-border-select {
    border-color: #28a745 !important;
    border-width: 2px !important;
  }

}

.success-border-select {
  border-color: #28a745 !important;
  border-width: 1px !important;
}

.success-border {
  border-color: #28a745 !important;
  border-width: 1px !important;
}

.success-border:focus {
  box-shadow: 0 0 0 0.1rem rgba(0, 220, 0, 0.55);
}

.success-border:hover {
  box-shadow: 0 0 0 0.1rem rgba(0, 220, 0, 0.55);
}

.error-border:focus {
  box-shadow: 0 0 0 0.1rem rgba(255, 0, 0, 0.55);
}

.error-border:hover {
  box-shadow: 0 0 0 0.1rem rgba(255, 0, 0, 0.55);
}

.success-border-select:focus {
  box-shadow: 0 0 0 0.1rem rgba(0, 220, 0, 0.55);
}

.success-border-select:hover {
  box-shadow: 0 0 0 0.1rem rgba(0, 220, 0, 0.55);
}

.error-border-select:focus {
  box-shadow: 0 0 0 0.1rem rgba(255, 0, 0, 0.55);
}

.error-border-select:hover {
  box-shadow: 0 0 0 0.1rem rgba(255, 0, 0, 0.55);
}

@media (min-width: 768px) {
  .has-success-field:after {

    font-family: 'nucleo';
    content: "\ea1b";
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 34px;
    color: limegreen;
    font-size: 1.2rem;
  }

  .has-error-field:after {
    font-family: 'nucleo';
    content: "\ea48";
    color: red;
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 34px;
    font-size: 1.2rem;
  }

  .has-success-field-margin:after {

    font-family: 'nucleo';
    content: "\ea1b";
    display: inline-block;
    position: absolute;
    right: 45px;
    top: 35px;
    color: limegreen;
    font-size: 1.2rem;
  }

  .has-error-field-margin:after {
    font-family: 'nucleo';
    content: "\ea48";
    color: red;
    display: inline-block;
    position: absolute;
    right: 45px;
    top: 35px;
    font-size: 1.2rem;
  }


  .has-success-field-top5:after {

    font-family: 'nucleo';
    content: "\ea1b";
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 5px;
    color: limegreen;
    font-size: 1.2rem;
  }

  .has-error-field-top5:after {
    font-family: 'nucleo';
    content: "\ea48";
    color: red;
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 5px;
    font-size: 1.2rem;
  }

  .has-success-field-margin-top5:after {

    font-family: 'nucleo';
    content: "\ea1b";
    display: inline-block;
    position: absolute;
    right: 45px;
    top: 5px;
    color: limegreen;
    font-size: 1.2rem;
  }

  .has-error-field-margin-top5:after {
    font-family: 'nucleo';
    content: "\ea48";
    color: red;
    display: inline-block;
    position: absolute;
    right: 45px;
    top: 5px;
    font-size: 1.2rem;
  }
}


.has-success-field-select:after {

  font-family: 'nucleo';
  content: "\ea1b";
  display: inline-block;
  position: absolute;
  right: 40px;
  top: 35px;
  color: limegreen;
  font-size: 1.2rem;
}

.has-error-field-select:after {
  font-family: 'nucleo';
  content: "\ea48";
  z-index: 100;
  color: red;
  display: inline-block;
  position: absolute;
  right: 40px;
  top: 35px;
  font-size: 1.2rem;
}


