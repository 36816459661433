

.td-bio {
    color: #000000;
}

.td-width {
    min-width: 200px;
}

.dark {
    .td-bio {
        color: #ffffff;
    }

}

.purple {
    .td-bio {
        color: #ffffff;
    }

}

.borderline {
    border: 1px solid #9acccb;
    padding: 5px;
    border-radius: 26px;
    color: #9acccb !important;
}

.header-cv {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 800;
    margin-top: 30px;
}


.videoPlayer {
    max-height: 200px;
    max-width: 320px;
}


