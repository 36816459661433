
//@import "../../scss/vars";

.card-body {
    position: relative;
}

.register-wrap {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 11;
    top: 0;
    left: 0;
}

.register-wrap > .container {
    display: flex;
    height: 100vh;
    width: 100vw;
}

.register-wrap > .container > .row {
    align-content: center;
    flex-direction: row;
    flex-grow: 1;
}

.register-wrap > .container > .row .card {
    width: 70vw;
    min-height: 80vh;
}

.language-selector-wrap {
    text-align: right;
    margin-right: 10px;
    /*margin-top: -32px;*/
    /*padding-bottom: 32px;*/
}

.alert {
    margin-bottom: 0px !important;
}


.form-panel {
    display: block;
}

.input-group-prepend > span {
    font-size: 14px !important;
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    min-width: 12em;
}

.error-alert {
    font-size: 0.8rem;
}


.relative-bottom-spacer {
    display: inline-block;
    width: 100%;
    height: 24px;
}


.required-advice {
    position: absolute;
    bottom: 5px;
    color: red;
    font-size: 0.8em;
}

.margin-top-20 {
    margin-top: 20px;
}
