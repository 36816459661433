//  =================
//      Imports
//  =================

@import '@/scss/jobineo/base/base'; // Base Variables

/*
    Simple Counter
*/
.dark {
  .simple--counter-container {
    .white {
      background-color: #ffffff;
      color: #000000 !important;
    }

    .yellow {
      background-color: #FFB92A;
      color: #000000 !important;
    }

    .red {
      background-color: #d07075;
      color: #000000 !important;
    }

    .mint {
      background-color: #9acccb;
      color: #000000 !important;
    }

    .s-counter {
      font-size: 35px;
      font-weight: 900;
      color: #000000 !important;
      margin-bottom: 0;
    }

    .s-counter-text {
      color: #000000 !important;
      font-size: 17px;
      font-weight: 900;
      float: right;
      margin-top: 20px !important;
      margin-right: 20px !important;
      margin-bottom: 0px !important;
    }
  }
}

.purple {
  .simple--counter-container {
    .white {
      background-color: #ffffff;
      color: #000000 !important;
    }

    .yellow {
      background-color: #FFB92A;
      color: #000000 !important;
    }

    .red {
      background-color: #d07075;
      color: #000000 !important;
    }

    .mint {
      background-color: #9acccb;
      color: #000000 !important;
    }

    .s-counter {
      font-size: 35px;
      font-weight: 900;
      color: #000000 !important;
      margin-bottom: 0;
    }

    .s-counter-text {
      color: #000000 !important;
      font-size: 17px;
      font-weight: 900;
      float: right;
      margin-top: 20px !important;
      margin-right: 20px !important;
      margin-bottom: 0px !important;
    }
  }
}

.simple--counter-container {
  display: flex;
  justify-content: space-around;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  .yellow {
    background-color: #FFB92A;
    color: #ffffff !important;
  }

  .white {
    background-color: #000000;
    color: #ffffff !important;
  }

  .red {
    background-color: #d07075;
    color: #ffffff !important;
  }

  .mint {
    background-color: #9acccb;
    color: #ffffff !important;
  }

  .counter-container {
    margin-bottom: 23px;
    max-width: 400px;
    border-radius: 12px;
    display: flex;


    .counter-content {
      width: 100% !important;
      min-height: 100px;
      -webkit-box-shadow: none;
      box-shadow: none;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      border: none !important;
    }
  }

  .s-counter {
    font-size: 35px;
    font-weight: 900;
    color: #ffffff !important;
    margin-bottom: 0;
  }

  .s-counter-text {
    color: #ffffff !important;
    font-size: 17px;
    font-weight: 900;
    float: right;
    margin-top: 20px !important;
    margin-right: 20px !important;
    margin-bottom: 0px !important;
  }
}

/*
    With Icon
*/

.icon--counter-container {
  display: flex;
  justify-content: space-around;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  .counter-container {
    margin-bottom: 23px;
  }

  .counter-ico {
    color: $primary;
    font-size: 28px;
    width: 30px;
    height: 30px;
    margin-bottom: 6px;
  }

  .counter-content {
    width: 85px;
    height: 85px;
    -webkit-box-shadow: 1px 2px 12px 0 rgba(31, 45, 61, 0.1);
    box-shadow: 1px 2px 12px 0 rgba(31, 45, 61, 0.1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border: 1px solid $m-color_3;
    margin: 0 0 12px 0;
  }

  .ico-counter {
    font-size: 25px;
    color: $dark;
    margin-bottom: 0;
  }

  .ico-counter-text {
    color: $m-color_6;
    font-size: 13px;
    font-weight: 100;
    letter-spacing: 1px;
  }
}
