
.dashboard-list {
    .text-muted {
        color: rgba(255, 255, 255, 0.8) !important;
    }

    .table {
        color: #fff !important;
    }

    p {
        margin-bottom: 0.5rem;
    }


    .table-responsive {
        min-height: 170px !important;
    }
}

