.mt-container {
  max-width: 800px;
}

.modern-timeline {
  list-style: none;
  position: relative;
  padding: 50px 0 50px;
  margin: 0;

  &:before {
    position: absolute;
    background: $m-color_2;
    bottom: 0;
    left: 50%;
    top: 0;
    content: "";
    width: 3px;
    margin-left: -1.5px;
  }

  > li {
    margin-bottom: 50px;
    position: relative;

    &:after, &:before {
      display: table;
      content: "";
    }

    > {
      .modern-timeline-badge {
        position: absolute;
        background: #fff;
        border: 3px solid $m-color_2;
        border-radius: 100%;
        height: 20px;
        width: 20px;
        margin-left: -10px;
        text-align: center;
        z-index: 1;
        left: 50%;
        top: 32px;
      }

      .modern-timeline-panel {
        position: relative;
        border: 1px solid $m-color_2;
        background: #fff;
        border-radius: .1875rem;
        box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
        box-shadow: 0px 20px 20px rgba(126, 142, 177, 0.12);
        transition: .3s ease-in-out;
        float: left;
        width: 46%;
        border-radius: 6px;

        &:before {
          position: absolute;
          background: $m-color_2;
          right: -37px;
          top: 40px;
          transition: .3s ease-in-out;
          content: " ";
          width: 37px;
          height: 3px;
          display: block;
        }
      }
    }

    &:nth-child(even) > .modern-timeline-panel:before {
      right: auto;
      left: -37px;
      width: 37px;
    }

    &:after {
      clear: both;
    }

    > .modern-timeline-panel {
      .modern-timeline-preview img {
        width: 100%;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }

      *:last-child {
        margin-bottom: 0;
      }
    }

    &:nth-child(even) > .modern-timeline-panel {
      border: 1px solid $m-color_2;
      float: right;
    }

    > .modern-timeline-panel {
      *:last-child {
        margin-bottom: 0;
      }

      .modern-timeline-body {
        padding: 30px 20px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        h4 {
          color: $m-color_16;
          margin-bottom: 20px;
          font-size: 1.125rem;
        }

        p {
          color: $dark;
          margin-bottom: 0;

          a {
            display: block;
          }
        }
      }

      *:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.modern-timeline-top:before, .modern-timeline-bottom:before {
  background: $m-color_2;
  position: absolute;
  height: 3px;
  width: 50px;
  display: block;
  content: "";
  bottom: 0;
  left: 50%;
  margin-left: -25px;
}

.modern-timeline-top:before {
  top: 0;
}

@media (max-width: 767px) {
  ul.modern-timeline > li > {
    .modern-timeline-panel {
      border: 1px solid $m-color_2;
      float: right;
      width: 100%;
    }

    .modern-timeline-badge {
      display: none;
    }
  }

  .modern-timeline > li > .modern-timeline-panel:before {
    display: none;
  }
}
