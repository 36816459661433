

.multiselect__tags {
    min-height: 45px !important;
}

.multiselect-jobineo {

    --ms-font-size: 0.8rem;
    --ms-line-height: 1.75;
    --ms-bg: #FFFFFF;
    --ms-bg-disabled: #F3F4F6;
    --ms-border-color: #9acccb;
    --ms-border-width: 1px;
    --ms-border-color-active: #9acccb;
    --ms-border-width-active: 1px;
    --ms-radius: 6px;
    --ms-py: 0.5rem;
    --ms-px: 0.875rem;
    --ms-ring-width: 3px;
    --ms-ring-color: #10B98130;
    --ms-placeholder-color: #9CA3AF;
    --ms-max-height: 10rem;

    --ms-spinner-color: #10B981;
    --ms-caret-color: #999999;
    --ms-clear-color: #999999;
    --ms-clear-color-hover: #000000;

    --ms-tag-font-size: 0.8rem;
    --ms-tag-line-height: 1.75rem;
    --ms-tag-font-weight: 500;
    --ms-tag-bg: #10B981;
    --ms-tag-bg-disabled: #9CA3AF;
    --ms-tag-color: #FFFFFF;
    --ms-tag-color-disabled: #FFFFFF;
    --ms-tag-radius: 4px;
    --ms-tag-py: 0.125rem;
    --ms-tag-px: 0.5rem;
    --ms-tag-my: 0.25rem;
    --ms-tag-mx: 0.25rem;

    --ms-tag-remove-radius: 4px;
    --ms-tag-remove-py: 0.25rem;
    --ms-tag-remove-px: 0.25rem;
    --ms-tag-remove-my: 0rem;
    --ms-tag-remove-mx: 0.125rem;

    --ms-dropdown-bg: #FFFFFF;
    --ms-dropdown-border-color: #D1D5DB;
    --ms-dropdown-border-width: 1px;
    --ms-dropdown-radius: 6px;

    --ms-group-label-py: 0.3rem;
    --ms-group-label-px: 0.75rem;
    --ms-group-label-line-height: 1.375;
    --ms-group-label-bg: #E5E7EB;
    --ms-group-label-color: #374151;
    --ms-group-label-bg-pointed: #D1D5DB;
    --ms-group-label-color-pointed: #374151;
    --ms-group-label-bg-disabled: #F3F4F6;
    --ms-group-label-color-disabled: #D1D5DB;
    --ms-group-label-bg-selected: #059669;
    --ms-group-label-color-selected: #FFFFFF;
    --ms-group-label-bg-selected-pointed: #0c9e70;
    --ms-group-label-color-selected-pointed: #FFFFFF;
    --ms-group-label-bg-selected-disabled: #75cfb1;
    --ms-group-label-color-selected-disabled: #D1FAE5;

    --ms-option-font-size: 0.8rem;
    --ms-option-line-height: 1.75;
    --ms-option-bg-pointed: #FFFFFF;
    --ms-option-color-pointed: #1F2937;
    --ms-option-bg-selected: #10B981;
    --ms-option-color-selected: #FFFFFF;
    --ms-option-bg-disabled: #FFFFFF;
    --ms-option-color-disabled: #D1D5DB;
    --ms-option-bg-selected-pointed: #26C08E;
    --ms-option-color-selected-pointed: #FFFFFF;
    --ms-option-bg-selected-disabled: #FFFFFF;
    --ms-option-color-selected-disabled: #D1FAE5;
    --ms-option-py: 0.5rem;
    --ms-option-px: 0.75rem;

    --ms-empty-color: #4B5563;
}

.dark {


    .multiselect-jobineo {
        --ms-bg: #FFFFFF;
        --ms-bg-disabled: #F3F4F6;
        --ms-border-color: #D1D5DB;
        --ms-border-color-active: #D1D5DB;
        --ms-ring-color: #10B98130;
        --ms-placeholder-color: #9CA3AF;

        --ms-spinner-color: #10B981;
        --ms-caret-color: #000000;
        --ms-clear-color: #000000;
        --ms-clear-color-hover: #000000;

        --ms-tag-bg: #10B981;
        --ms-tag-bg-disabled: #9CA3AF;
        --ms-tag-color: #FFFFFF;
        --ms-tag-color-disabled: #FFFFFF;

        --ms-dropdown-bg: #FFFFFF;
        --ms-dropdown-border-color: #D1D5DB;
        --ms-group-label-bg: #E5E7EB;
        --ms-group-label-color: #374151;
        --ms-group-label-bg-pointed: #D1D5DB;
        --ms-group-label-color-pointed: #374151;
        --ms-group-label-bg-disabled: #F3F4F6;
        --ms-group-label-color-disabled: #D1D5DB;
        --ms-group-label-bg-selected: #059669;
        --ms-group-label-color-selected: #FFFFFF;
        --ms-group-label-bg-selected-pointed: #0c9e70;
        --ms-group-label-color-selected-pointed: #FFFFFF;
        --ms-group-label-bg-selected-disabled: #75cfb1;
        --ms-group-label-color-selected-disabled: #D1FAE5;

        --ms-option-bg-pointed: #FFFFFF;
        --ms-option-color-pointed: #1F2937;
        --ms-option-bg-selected: #10B981;
        --ms-option-color-selected: #FFFFFF;
        --ms-option-bg-disabled: #FFFFFF;
        --ms-option-color-disabled: #D1D5DB;
        --ms-option-bg-selected-pointed: #26C08E;
        --ms-option-color-selected-pointed: #FFFFFF;
        --ms-option-bg-selected-disabled: #FFFFFF;
        --ms-option-color-selected-disabled: #D1FAE5;

        --ms-empty-color: #000000;
    }

}


