/*
=====================
    Modern
=====================
*/

.timeline-alter .item-timeline {
  display: flex;

  .t-time {
    padding: 10px;
    align-self: center;

    p {
      margin: 0;
      min-width: 58px;
      max-width: 100px;
      font-size: 16px;
      font-weight: 600;
      color: $dark;
      align-self: center;
    }
  }

  .t-img {
    position: relative;
    border-color: $m-color_2;
    padding: 10px;

    &:before {
      content: '';
      position: absolute;
      border: 2px solid $primary;
      border-color: inherit;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      top: 15px;
      left: 50%;
      transform: translateX(-50%);
    }

    &:after {
      content: '';
      position: absolute;
      border-left: 2px solid $primary;
      border-color: inherit;
      transform: translateX(-50%);
      width: 0;
      height: auto;
      top: 25px;
      left: 50%;
      bottom: -15px;
      border-radius: 0;
    }

    img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      z-index: 7;
      position: relative;
    }
  }

  .t-usr-txt {
    display: block;
    padding: 10px;
    position: relative;
    border-color: $m-color_2;

    &:before {
      content: '';
      position: absolute;
      border: 2px solid $primary;
      border-color: inherit;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      top: 15px;
      left: 50%;
      transform: translateX(-50%);
    }

    &:after {
      content: '';
      position: absolute;
      border-left: 2px solid $primary;
      border-color: inherit;
      transform: translateX(-50%);
      width: 0;
      height: auto;
      top: 25px;
      left: 50%;
      bottom: -15px;
      border-radius: 0;
    }

    p {
      margin: 0;
      background: #c2d5ff;
      height: 45px;
      width: 45px;
      border-radius: 50%;
      display: flex;
      align-self: center;
      justify-content: center;
      margin-bottom: 0;
      color: #1b55e2;
      font-weight: 700;
      font-size: 18px;
      z-index: 7;
      position: relative;
    }

    span {
      align-self: center;
    }
  }

  .t-meta-time {
    padding: 10px;
    align-self: center;

    p {
      margin: 0;
      min-width: 100px;
      max-width: 100px;
      font-size: 12px;
      font-weight: 700;
      color: $m-color_6;
    }
  }

  .t-text {
    padding: 10px;
    align-self: center;

    p {
      font-size: 13px;
      margin: 0;
      color: $dark;
      font-weight: 600;

      a {
        color: #1b55e2;
        font-weight: 600;
      }
    }
  }
}
