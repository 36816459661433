

.td-left {
    min-width: 100px;
    max-width: 300px;
    /*border-right: 1px solid #dddddd;*/
    font-weight: bold;
}

.bold-header {
    font-weight: bold;
}

.user-table {
    border: none;
    font-size: 16px;
}

.user-table tr, .user-table tr td {
    border: none !important;
}

.profile-preview {
    cursor: default !important;
    opacity: 1 !important;
}


