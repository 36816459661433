
//@import "../scss/sass/black-dashboard-pro";

.standardStyle {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 15px !important;
}

.btn-round {
    border-radius: 30px;
}

.btn-xs {
    padding: 0.25rem 0.4rem !important;
    font-size: 0.875rem !important;
    line-height: 0.5 !important;
    border-radius: 0.2rem !important;
}
