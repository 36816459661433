
//@import "../../scss/sass/black-dashboard-pro";

.ribbon-container {
  position: absolute;
  left: 0;
  top: -3px;
  width: 100%;
  height: 120px;
  overflow: hidden;
}

.ribbon-flag {
  background: radial-gradient(#313030, #515152);
  color: white;
  position: absolute;
  display: block;
  top: 15px;
  right: -45px;
  width: 170px;
  padding: 12px 20px 8px;
  margin: 0;
  transform: rotate(45deg);
  height: 50px;
  z-index: 2;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  text-align: center;
}

.card-footer {
  background: transparent !important;
  border: none !important;
}

.card-body {
  padding: 0 !important;
  //overflow: hidden !important;
}

