
.margin-top-15 {
    margin-top: 15px;
}

.videoPlayer {
    max-height: 200px;
    margin-top: 30px;
}

.margin-left-20 {
    margin-left: 20px;
}

input[type="range"]::-webkit-slider-thumb {
    background-color: #9acccb;

}

input[type="range"]::-webkit-slider-runnable-track {
    background: #053a5f;
    height: 0.5rem;
}

input[type="range"]::-moz-range-track {
    background: #053a5f;
    height: 0.5rem;
}

