

.font-size-14 {
    font-size: 14px !important;

}

.el-date-editor.el-input {
    width: 100% !important
}

.margin-bot-10 {
    margin-bottom: 10px;
}

.el-input__inner {
    line-height: 33px !important;
    height: 33px !important;
}

.el-date-editor {
    padding: 0 !important;
}

.el-input--prefix .el-input__inner {
    padding-left: 30px !important;
}

.el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    line-height: 33px !important;
}

.el-input--suffix .el-input__inner {
    padding-right: 10px !important;
}

.el-input__suffix-inner > .el-input__icon {
    margin-right: 20px !important;
}

.el-input__icon:after {
    content: "";
    height: 100%;
    width: 0;
    display: inline-block;
    vertical-align: middle;
}

.flex {
    display: flex;
}

.sizing-calendar {
    margin-left: 10px;
    margin-top: 11px;
    position: absolute;
    fill: rgba(0, 23, 55, 0.08);
    width: 20px;
    overflow: visible;
    box-sizing: content-box;
    vertical-align: -0.125em;
    height: 20px;
    color: black;
}

.inputField {
    display: inline-block;
    vertical-align: middle;
    border-radius: 6px;
    min-width: 50px;
    max-width: 635px;
    width: 100%;
    transition: all 0.2s ease-in-out 0s;
    color: #3b3f5c;
    font-weight: 500;
    font-size: 16px;
    padding: 13px 35px 13px 46px;
}

.errors {
    color: #fc0e0e;
}


