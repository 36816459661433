/*
=======================
    Timeline Simple
=======================
*/

.timeline-simple {
    margin-bottom: 45px;
    max-width: 1140px;
    margin-right: auto;
    margin-left: auto;

    h3 {
        font-size: 23px;
        font-weight: 600;
    }

    p.timeline-title {
        position: relative;
        font-size: 19px;
        font-weight: 600;
        color: #1b55e2;
        margin-bottom: 28px;

        &:before {
            position: absolute;
            content: '';
            height: 2px;
            width: 70px;
            background: #1b55e2;
            border-radius: 50px;
            bottom: -2px;
            left: 15px;
        }
    }

    .timeline-list {
        p.meta-update-day {
            margin-bottom: 24px;
            font-size: 16px;
            font-weight: 600;
            color: $m-color_6;
        }

        .timeline-post-content {
            display: flex;

            > div > div {
                margin-top: 28px;
            }

            &:not(:last-child) > div > div {
                margin-bottom: 70px;
            }

            div.user-profile {
                position: relative;
                z-index: 2;

                &:after {
                    content: '';
                    position: absolute;
                    border-left: 2px solid $m-color_2;
                    transform: translateX(-50%);
                    width: 0;
                    height: auto;
                    top: 48px;
                    left: 34%;
                    bottom: -15px;
                    border-radius: 0;
                    z-index: -1;
                }

                img {
                    width: 53px;
                    height: 53px;
                    border-radius: 50%;
                    margin-right: 30px;
                    -webkit-box-shadow: 0px 4px 9px 0px rgba(31, 45, 61, 0.31);
                    box-shadow: 0px 4px 9px 0px rgba(31, 45, 61, 0.31);
                }
            }

            h4 {
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 0;
                color: #1b55e2;
            }

            svg {
                color: $m-color_6;
                vertical-align: text-bottom;
                width: 21px;
                height: 21px;
                margin-right: 10px;
            }

            &:hover svg {
                color: #1b55e2;
                fill: rgba(27, 85, 226, 0.2392156863);
            }

            h6 {
                display: inline-block;
                font-size: 17px;
                font-weight: 600;
                margin-bottom: 11px;
            }

            &:hover h6 {
                color: $m-color_6;
            }

            p.post-text {
                padding-left: 31px;
                color: $m-color_6;
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 28px;
            }

            .post-contributers {
                padding-left: 31px;

                img {
                    width: 38px;
                    border-radius: 50%;
                    margin-right: 7px;
                    -webkit-box-shadow: 0px 6px 9px 2px rgba(31, 45, 61, 0.31);
                    box-shadow: 1px 3px 7px 2px rgba(31, 45, 61, 0.31);
                    -webkit-transition: all 0.35s ease;
                    transition: all 0.35s ease;
                    cursor: pointer;
                    margin-bottom: 5px;

                    &:hover {
                        -webkit-transform: translateY(-3px) scale(1.02);
                        transform: translateY(-3px) scale(1.02);
                        box-shadow: none;
                    }
                }
            }

            .post-gallery-img {
                padding-left: 31px;

                img {
                    width: 20%;
                    border-radius: 6px;
                    -webkit-box-shadow: 0px 6px 9px 2px rgba(31, 45, 61, 0.31);
                    box-shadow: 1px 3px 7px 2px rgba(31, 45, 61, 0.31);
                    -webkit-transition: all 0.35s ease;
                    transition: all 0.35s ease;
                    cursor: pointer;

                    &:hover {
                        -webkit-transform: translateY(-3px) scale(1.02);
                        transform: translateY(-3px) scale(1.02);
                        box-shadow: none;
                    }

                    &:not(:last-child) {
                        margin-right: 23px;
                    }
                }
            }
        }
    }
}
