

$--font-path: '~element-plus/theme-chalk/fonts';
@import "~element-plus/theme-chalk/src/index";

.el-select {
  width: 100%;
  margin-bottom: 30px;
}

.el-tag.el-tag--info {
  color: #6e707e !important;
}

