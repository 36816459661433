
//	===============================
//			@Import	Colors
//	===============================


$white: #fff;
$black: #000;

$primary: #4361ee;
$info: #2196f3;
$success: #1abc9c;
$warning: #e2a03f;
$danger: #e7515a;
$secondary: #805dca;
$dark: #3b3f5c;


$l-primary: #eaf1ff;
$l-info: #e7f7ff;
$l-success: #ddf5f0;
$l-warning: #fff9ed;
$l-danger: #fff5f5;
$l-secondary: #f3effc;
$l-dark: #e3e4eb;

// 	=================
//		More Colors
//	=================

$m-color_1: #f1f2f3;
$m-color_2: #ebedf2;

$m-color_3: #e0e6ed;
$m-color_4: #bfc9d4;
$m-color_5: #d3d3d3;

$m-color_6: #888ea8;
$m-color_7: #506690;

$m-color_8: #555555;
$m-color_9: #515365;
$m-color_11: #607d8b;

$m-color_12: #1b2e4b;
$m-color_18: #191e3a;
$m-color_10: #0e1726;

$m-color_19: #060818;
$m-color_13: #25d5e4;
$m-color_14: #009688;

$m-color_15: #ffbb44;
$m-color_16: #e95f2b;
$m-color_17: #f8538d;


$m-color_20: #fafafa;
$m-color_21: #445ede;
$m-color_22: #304aca;


$m-color_23: #030305;
$m-color_24: #151516;
$m-color_25: #61b6cd;
$m-color_26: #4cd265;




//	========================
//		Color Defination
//	========================


$body-color: $m-color_19;