

#app-wrapper {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;

}


#app {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;

}

.cookie-comply {
    position: fixed !important;
    bottom: 0 !important;
}

.cookie-comply__header-title {
    color: #000000 !important;
}

.cookie-comply__header-description {
    color: #000000 !important;
}

.cookie-comply__modal-content {
    color: #000000 !important;

    p {
        color: #000000 !important;
    }

    h2 {
        color: #000000 !important;
    }
}


.cookie-comply__modal-header {
    color: #000000 !important;

    h3 {
    }
}

.vue-notification {
    // styling margin: 0 5px 5px;
    padding: 20px !important;
    font-size: 15px !important;
    color: #000000 !important;
    font-weight: 300 !important;
    width: 100% !important;
    z-index: 99999 !Important;

    // default (blue) background: #44a4fc;
    border-left: 5px solid #ffffff !important;

    .notification-title {
        font-size: 18px !important;
        font-weight: 900 !important;
    }

    // types (green, amber, red)

    &.success {
        background: #9acccb !important;
        border-left-color: #62a2a1 !important;
    }

    &.warn {
        background: #fdb82a !important;
        border-left-color: #be8311 !important;
    }

    &.error {
        background: #ce6f74 !important;
        border-left-color: #c5575f !important;
    }
}

.vue-notification-group {
    display: block;
    position: fixed;
    left: 0 !important;
    margin-left: 50px !important;
    align-content: center !important;
    align-self: center !important;
    z-index: 99999 !important;
    width: 95% !important;
}

