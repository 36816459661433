

.td-bio {
    color: #000000;
}

.td-width {
    min-width: 200px;
}

.dark {
    .td-bio {
        color: #ffffff;
    }

}

.purple {
    .td-bio {
        color: #ffffff;
    }

}

.borderline {
    border: 1px solid #9acccb;
    padding: 5px;
    border-radius: 26px;
    color: #9acccb !important;
}

.header-cv {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 800;
    margin-top: 30px;
}


.videoPlayer {
    max-height: 200px;
    max-width: 320px;
}

.headerIcons {
    display: flex;
}

.iconMargin {
    margin-left: 5px;
}

.popOverText {
    color: white;
    font-size: 14px;
    font-weight: 500;
}

.info-container {
    border: 1px solid #9acccb;
    border-radius: 20px;
    margin: 5px auto;
    text-align: center;
}

.ul-black {
    max-width: 100% !important;
}

.li-size {
    font-size: 16px !important;
}

.ad-text {
    font-size: 16px !important;
}

.contact-header {
    font-size: 16px;
    font-weight: 600;
}

.contact-text {
    font-size: 16px;
}


.dark {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: white;
    --popper-theme-border-width: 0px;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 15px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);

    .contact-header {
        color: #9acccb;
    }

    .contact-text {
        color: #000000;
    }

    .ad-text {
        color: #000000;
    }

    .text-color-white {
        color: white;
    }

    .info-container {
        background-color: #070707;
        color: #FFFFFF;

    }

    .panelblue {
        background-color: #9acccb !important;
        color: #000000;
    }

    .panel-white {
        background-color: #FFFFFF !important;
        color: #000000;
    }

    .ul-black {
        color: #000000 !important;
    }

    .header-black {
        margin-top: 10px;
        color: #000000 !important;
        font-weight: 700;
    }

}


