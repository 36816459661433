


.margin-t-35 {
    margin-top: 35px;
}

.left-zero {
    padding-left: 0px !important
}

