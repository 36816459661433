

.custom-table {
    background: transparent;
}

.tree-header {
    position: relative;
    background-color: transparent;
    padding: 5px 10px 5px 10px;
    font-weight: bold;
    font-size: 20px;
    min-height: 40px;
    box-shadow: -2px 4px 10px -6px rgba(0, 0, 0, 0.45);
    border-bottom: 1px solid #9acccb;
}

.tree-header-label {
    font-size: 16px;
    padding-top: 4px;
    display: flex;
}

p {
    margin-bottom: 0px !important;
}

.no-left-padding > .input {
    padding-left: 0px !important;
}

.header-picture-company {
    min-height: 550px;
    display: grid;
    background-position: center center;
    align-self: center;
    align-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(~@/assets/images/header-bild-unternehmen.jpg);
}

.header-text-company {
    color: #000000;
    margin-left: 40px;
    background: white;
    width: 45%;
    border-radius: 12px;
    font-weight: 900;
    padding-left: 10px;
    letter-spacing: 0;
}

.margin-left {
    margin-left: 35px;
}

.mt-btn {
    margin-top: 38px;
}

.search-box {
    background: #9acccb !important;
}


