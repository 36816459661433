

.font-size-14 {
  font-size: 14px !important;

}

.margin-bot-10 {
  margin-bottom: 10px;
}
