/*main pluging css*/
@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'forms/theme-checkbox-radio.scss';
@import 'perfect-scrollbar.css';
@import 'main.scss';
@import 'structure.scss';
@import 'elements/alert.scss';
@import 'forms/switches.scss';
@import 'custom-loader.css';
@import 'animate.css';

@import 'tables/table-custom.scss';

/*dark css*/
@import 'dark.scss';
@import 'purple.scss';

/*custom css*/
@import 'style.scss';
