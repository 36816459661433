

.check-margin {
    margin-right: 4px;
}

.check-margin-gray {
    margin-right: 15px;
    color: rgba(#b2b0b0, 0.4);
}

.check-margin-10 {
    margin-right: 10px;
}

.x-margin-gray {
    color: rgba(#b2b0b0, 0.4);
    margin-right: 5px;
}

.grayout {
    color: rgba(#b2b0b0, 0.4);
}

.tss {
    display: inline-block;
    position: relative;
    user-select: none;
    background: #222a42;
    border-radius: 25px;
    color: white;
    height: 50px;

    input[type='radio'] {
        display: none;

        &:checked {
            & + label {
                span {
                    &:before {
                        display: inline-block;
                    }
                }
            }
        }
    }

    label {
        position: relative;
        display: inline-block;
        cursor: pointer;
        padding: 15px 10px;
        min-width: 50px;
        color: white;
        font-weight: bold;

        span {
            &:before {
                content: '';
                position: absolute;
                display: none;
                width: 100%;
                height: 100%;
                color: #000000;


                border-radius: 100%;
                background: transparentize(#FFB92A, 0.3);
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }
    }
}

.ribbon-container {
    position: absolute;
    left: 0;
    top: -3px;
    width: 100%;
    height: 120px;
    overflow: hidden;
}

.ribbon-flag {
    background: radial-gradient(#313030, #515152);
    color: white;
    position: absolute;
    display: block;
    top: 15px;
    right: -45px;
    width: 170px;
    padding: 12px 20px 8px;
    margin: 0;
    transform: rotate(45deg);
    height: 50px;
    z-index: 2;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    text-align: center;
}

.margin-top-60 {
    margin-top: 60px;
}

.container {
    display: block !important;
    margin-left: 0 !important;
    max-width: 100% !important;
    @media (min-width: 1400px) {
        max-width: 100%;
    }
}

.in-development {
    font-family: 'Poppins';
    margin-bottom: 30px;

}

.headline-box-black {
    font-weight: 700;
    font-size: 3em;
    text-align: center;
    margin-bottom: 30px;
}


.accordion {
    margin-bottom: 50px;
    align-self: center;

}

.grid-accord {
    display: flex !important;
    place-content: center;
}

.accordion {
    max-width: 1000px;
    @media (min-width: 1400px) {
        width: 1000px;
    }

}

.accordion-header {
    font-family: 'Poppins';
    color: black;
}

.accordion-body p {
    font-family: 'Poppins';
    color: black;
}

