//  =================
//      Imports
//  =================

@import '@/scss/jobineo/base/base'; // Base Variables

.layout-spacing {
  padding-bottom: 25px;
}

/*
===================
    User Profile
===================
*/
.user-profile-functions {

  h3 {

    &:after {
      position: absolute;
      content: '';
      height: 2px;
      width: 115px !important;
      background: $primary;
      border-radius: 50%;
      bottom: 9px;
      left: 15px;
    }
  }
}


.user-profile {
  border-radius: 6px;

  .edit-profile-invisible {
    height: 35px;
    width: 35px;
    display: flex;
    cursor: default;
    justify-content: center;
    align-self: center;
    background-color: transparent;
  }

  .edit-profile {
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-self: center;
    background-color: #9acccb;
    border-radius: 50%;
    box-shadow: 0 10px 15px rgba(0, 69, 255, 0.2);

    svg {
      font-size: 17px;
      vertical-align: middle;
      margin-right: 0;
      color: #fff;
      width: 19px;
      align-self: center;
    }
  }

  .edit-profile-done {
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-self: center;
    background-color: #fdb82a;
    border-radius: 50%;
    box-shadow: 0 10px 15px rgba(0, 69, 255, 0.2);

    svg {
      font-size: 17px;
      vertical-align: middle;
      margin-right: 0;
      color: #fff;
      width: 19px;
      align-self: center;
    }
  }

  .edit-profile-error {
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-self: center;
    background-color: #ce6f74;
    border-radius: 50%;
    box-shadow: 0 10px 15px rgba(0, 69, 255, 0.2);

    svg {
      font-size: 17px;
      vertical-align: middle;
      margin-right: 0;
      color: #fff;
      width: 19px;
      align-self: center;
    }
  }

  h3 {
    position: relative;
    font-size: 21px;
    font-weight: 600;
    color: $dark;
    margin: 6px 0px 0 0;

    &:after {
      position: absolute;
      content: '';
      height: 2px;
      width: 60px;
      background: $primary;
      border-radius: 50%;
      bottom: 9px;
      left: 15px;
    }
  }

  .user-info {
    margin-top: 40px;

    img {
      border-radius: 9px;
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
    }

    p {
      font-size: 20px;
      font-weight: 600;
      margin-top: 22px;
      color: $primary;
    }
  }

  .user-info-list ul.contacts-block {
    border: none;
    max-width: 335px;
    margin: 36px auto;

    li {
      margin-bottom: 13px;
      font-weight: 600;
      font-size: 13px;

      a {
        font-weight: 600;
        font-size: 15px;
        color: $primary;
      }
    }

    svg {
      width: 21px;
      margin-right: 15px;
      color: $m-color_6;
      vertical-align: middle;
      fill: rgba(0, 23, 55, 0.08);
    }

    li:hover svg {
      color: $primary;
      fill: rgba(27, 85, 226, 0.2392156863);
    }

    ul.list-inline {
      margin: 27px auto;

      div.social-icon {
        border: 2px solid $m-color_3;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        display: flex;
        justify-content: center;
        align-self: center;
      }

      svg {
        margin-right: 0;
        color: $primary;
        width: 19px;
        align-self: center;
      }
    }
  }

  .user-info-list ul.matching-block {
    border: none;
    margin: 12px auto;

    li {
      margin-bottom: 13px;
      font-weight: 600;
      font-size: 13px;

      a {
        font-weight: 600;
        font-size: 15px;
        color: $primary;
      }
    }


    ul.list-inline {
      margin: 27px auto;

      div.social-icon {
        border: 2px solid $m-color_3;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        display: flex;
        justify-content: center;
        align-self: center;
      }

      svg {
        margin-right: 0;
        color: $primary;
        width: 19px;
        align-self: center;
      }
    }
  }
}

/*
==============
    Skills
==============
*/

.skills {
  border-radius: 6px;

  h3 {
    position: relative;
    font-size: 21px;
    font-weight: 600;
    color: $dark;
    margin: 6px 0px 40px 0;

    &:after {
      position: absolute;
      content: '';
      height: 2px;
      width: 120px;
      background: $primary;
      border-radius: 50%;
      bottom: -2px;
      left: 15px;
    }
  }

  p.skill-item {
    margin-bottom: 5px;
    font-size: 15px;
    color: $m-color_9;
    font-weight: 600;
  }

  .progress {
    height: 20px;
    box-shadow: 1px 3px 20px 3px $m-color_1;
    margin-bottom: 31px;

    .progress-bar {
      background-color: $primary;
      background: linear-gradient(to right, #0081ff 0%, #0045ff 100%);
      border-radius: 1rem;
      box-shadow: 0 2px 4px rgba(0, 69, 255, 0.15), 0 8px 16px rgba(0, 69, 255, 0.2);
      font-size: 12px;
      letter-spacing: 1px;
      font-weight: 100;
    }

    .progress-title {
      display: flex;
      justify-content: space-between;
      padding: 15px;

      span {
        align-self: center;
      }
    }
  }
}

/*
==============
    Bio
==============
*/

.bio {
  border-radius: 6px;
  padding-bottom: 0;

  h3 {
    position: relative;
    font-size: 21px;
    font-weight: 600;
    color: $dark;
    margin: 6px 0px 40px 0;

    &:after {
      position: absolute;
      content: '';
      height: 2px;
      width: 105px;
      background: $primary;
      border-radius: 50%;
      bottom: -2px;
      left: 15px;
    }
  }

  h5 {
    font-size: 16px;
    font-weight: 700;
    color: $dark;
  }

  p {
    color: $m-color_6;
    font-size: 15px;
  }

  .bio-skill-box {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .b-skills {
    padding: 22px 16px;
    box-shadow: 2px 5px 17px 0 rgba(31, 45, 61, 0.17);
    background: #fff;
    border-radius: 10px;
    padding: 30px 30px 24px;

    svg {
      width: 30px;
      height: 30px;
      margin-right: 21px;
      color: $primary;
    }

    h5 {
      margin-bottom: 10px;
      font-size: 16px;
    }

    p {
      font-size: 13px;
    }
  }

  .b-questions {
    padding: 22px 16px;
    box-shadow: 2px 5px 17px 0 rgba(31, 45, 61, 0.17);
    background: #fff;
    border-radius: 10px;
    text-align: center;
    padding: 30px 30px 24px;

    svg {
      width: 30px;
      height: 30px;
      margin-right: 0px;
      color: $primary;
    }

    h5 {
      margin-bottom: 10px;
      font-size: 16px;
    }

    p {
      text-align: center;
      font-size: 15px;
      font-weight: 600;
    }
  }
}

/*
================
    Education
================
*/

.education {
  border-radius: 6px;

  h3 {
    position: relative;
    font-size: 21px;
    font-weight: 600;
    color: $dark;
    margin: 6px 0px 40px 0;

    &:after {
      position: absolute;
      content: '';
      height: 2px;
      width: 140px;
      background: $primary;
      border-radius: 50%;
      bottom: -2px;
      left: 15px;
    }
  }

  .timeline-alter .item-timeline {
    display: flex;

    .t-dot {
      position: relative;
      border-color: $primary;
      padding: 10px;

      &:before {
        content: '';
        position: absolute;
        border: 2px solid $primary;
        border-color: inherit;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &:not(:last-child) .t-dot:after {
      content: '';
      position: absolute;
      border-left: 2px solid $m-color_2;
      transform: translateX(-50%);
      width: 0;
      height: auto;
      top: 25px;
      left: 50%;
      bottom: -15px;
      border-radius: 0;
    }

    .t-dot img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      z-index: 7;
      position: relative;
    }

    .t-meta-date {
      padding: 10px;

      p {
        margin: 0;
        text-align: center;
        min-width: 150px;
        max-width: 250px;
        font-size: 12px;
        font-weight: 700;
        color: $m-color_6;
      }
    }

    .t-text {
      padding: 10px;
      align-self: center;

      p {
        font-size: 13px;
        margin: 0;
        color: $m-color_9;
        font-weight: 600;

        a {
          color: $info;
          font-weight: 600;
        }
      }
    }
  }
}

.my-search {
  border-radius: 6px;

  h3 {
    position: relative;
    font-size: 21px;
    font-weight: 600;
    color: $dark;
    margin: 6px 0px 40px 0;

    &:after {
      position: absolute;
      content: '';
      height: 2px;
      width: 100px;
      background: $primary;
      border-radius: 50%;
      bottom: -2px;
      left: 15px;
    }
  }

  .timeline-alter .item-timeline {
    display: flex;

    .t-dot {
      position: relative;
      border-color: $primary;
      padding: 10px;

      &:before {
        content: '';
        position: absolute;
        border: 2px solid $primary;
        border-color: inherit;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &:not(:last-child) .t-dot:after {
      content: '';
      position: absolute;
      border-left: 2px solid $m-color_2;
      transform: translateX(-50%);
      width: 0;
      height: auto;
      top: 25px;
      left: 50%;
      bottom: -15px;
      border-radius: 0;
    }

    .t-dot img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      z-index: 7;
      position: relative;
    }

    .t-meta-date {
      padding: 10px;

      p {
        margin: 0;
        text-align: center;
        min-width: 150px;
        max-width: 250px;
        font-size: 12px;
        font-weight: 700;
        color: $m-color_6;
      }
    }

    .t-text {
      padding: 10px;
      align-self: center;

      p {
        font-size: 13px;
        margin: 0;
        color: $m-color_9;
        font-weight: 600;

        a {
          color: $info;
          font-weight: 600;
        }
      }
    }
  }
}

.jobineo-questions {
  border-radius: 6px;

  h3 {
    position: relative;
    font-size: 21px;
    font-weight: 600;
    color: $dark;
    margin: 6px 0px 40px 0;

    &:after {
      position: absolute;
      content: '';
      height: 2px;
      width: 160px;
      background: $primary;
      border-radius: 50%;
      bottom: -2px;
      left: 15px;
    }
  }

  .b-questions {
    padding: 22px 16px;
    box-shadow: 2px 5px 17px 0 rgba(31, 45, 61, 0.17);
    background: #fff;
    border-radius: 10px;
    text-align: center;
    padding: 30px 30px 24px;

    svg {
      width: 30px;
      height: 30px;
      margin-right: 0px;
      color: $primary;
    }

    h5 {
      margin-bottom: 10px;
      font-size: 16px;
    }

    p {
      text-align: center;
      font-size: 15px;
      font-weight: 600;
    }
  }

  .timeline-alter .item-timeline {
    display: flex;

    .t-dot {
      position: relative;
      border-color: $primary;
      padding: 10px;

      &:before {
        content: '';
        position: absolute;
        border: 2px solid $primary;
        border-color: inherit;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &:not(:last-child) .t-dot:after {
      content: '';
      position: absolute;
      border-left: 2px solid $m-color_2;
      transform: translateX(-50%);
      width: 0;
      height: auto;
      top: 25px;
      left: 50%;
      bottom: -15px;
      border-radius: 0;
    }

    .t-dot img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      z-index: 7;
      position: relative;
    }

    .t-meta-date {
      padding: 10px;

      p {
        margin: 0;
        text-align: center;
        min-width: 150px;
        max-width: 250px;
        font-size: 12px;
        font-weight: 700;
        color: $m-color_6;
      }
    }

    .t-text {
      padding: 10px;
      align-self: center;

      p {
        font-size: 13px;
        margin: 0;
        color: $m-color_9;
        font-weight: 600;

        a {
          color: $info;
          font-weight: 600;
        }
      }
    }
  }
}

.cv {
  border-radius: 6px;

  h3 {
    position: relative;
    font-size: 21px;
    font-weight: 600;
    color: $dark;
    margin: 6px 0px 40px 0;

    &:after {
      position: absolute;
      content: '';
      height: 2px;
      width: 120px;
      background: $primary;
      border-radius: 50%;
      bottom: -2px;
      left: 15px;
    }
  }

  .timeline-alter .item-timeline {
    display: flex;

    .t-dot {
      position: relative;
      border-color: $primary;
      padding: 10px;

      &:before {
        content: '';
        position: absolute;
        border: 2px solid $primary;
        border-color: inherit;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &:not(:last-child) .t-dot:after {
      content: '';
      position: absolute;
      border-left: 2px solid $m-color_2;
      transform: translateX(-50%);
      width: 0;
      height: auto;
      top: 25px;
      left: 50%;
      bottom: -15px;
      border-radius: 0;
    }

    .t-dot img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      z-index: 7;
      position: relative;
    }

    .t-meta-date {
      padding: 10px;

      p {
        margin: 0;
        text-align: center;
        min-width: 150px;
        max-width: 250px;
        font-size: 12px;
        font-weight: 700;
        color: $m-color_6;
      }
    }

    .t-text {
      padding: 10px;
      align-self: center;

      p {
        font-size: 13px;
        margin: 0;
        color: $m-color_9;
        font-weight: 600;

        a {
          color: $info;
          font-weight: 600;
        }
      }
    }
  }
}


@media (max-width: 575px) {
  .education .timeline-alter .item-timeline {
    display: block;
    text-align: center;

    .t-meta-date p,
    .t-usr-txt p {
      margin: 0 auto;
    }
  }
}

/*
=======================
    Work Experience
=======================
*/

.work-experience {
  border-radius: 6px;

  h3 {
    position: relative;
    font-size: 21px;
    font-weight: 600;
    color: $dark;
    margin: 6px 0px 40px 0;

    &:after {
      position: absolute;
      content: '';
      height: 2px;
      width: 130px;
      background: $primary;
      border-radius: 50%;
      bottom: -2px;
      left: 15px;
    }
  }

  .timeline-alter .item-timeline {
    display: flex;

    .t-dot {
      position: relative;
      border-color: $primary;
      padding: 10px;

      &:before {
        content: '';
        position: absolute;
        border: 2px solid $primary;
        border-color: inherit;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &:not(:last-child) .t-dot:after {
      content: '';
      position: absolute;
      border-left: 2px solid $m-color_2;
      transform: translateX(-50%);
      width: 0;
      height: auto;
      top: 25px;
      left: 50%;
      bottom: -15px;
      border-radius: 0;
    }

    .t-dot img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      z-index: 7;
      position: relative;
    }

    .t-meta-date {
      padding: 10px;

      p {
        margin: 0;
        min-width: 150px;
        max-width: 250px;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        color: $m-color_6;
      }
    }

    .t-text {
      padding: 10px;
      align-self: center;

      p {
        font-size: 13px;
        margin: 0;
        color: $m-color_9;
        font-weight: 600;

        a {
          color: $info;
          font-weight: 600;
        }
      }
    }
  }
}

.knowledge {
  border-radius: 6px;

  h3 {
    position: relative;
    font-size: 21px;
    font-weight: 600;
    color: $dark;
    margin: 6px 0px 40px 0;

    &:after {
      position: absolute;
      content: '';
      height: 2px;
      width: 250px;
      background: $primary;
      border-radius: 50%;
      bottom: -2px;
      left: 15px;
    }
  }

  .timeline-alter .item-timeline {
    display: flex;

    .t-dot {
      position: relative;
      border-color: $primary;
      padding: 10px;

      &:before {
        content: '';
        position: absolute;
        border: 2px solid $primary;
        border-color: inherit;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &:not(:last-child) .t-dot:after {
      content: '';
      position: absolute;
      border-left: 2px solid $m-color_2;
      transform: translateX(-50%);
      width: 0;
      height: auto;
      top: 25px;
      left: 50%;
      bottom: -15px;
      border-radius: 0;
    }

    .t-dot img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      z-index: 7;
      position: relative;
    }

    .t-meta-date {
      padding: 10px;

      p {
        margin: 0;
        min-width: 150px;
        max-width: 250px;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        color: $m-color_6;
      }
    }

    .t-text {
      padding: 10px;
      align-self: center;

      p {
        font-size: 13px;
        margin: 0;
        color: $m-color_9;
        font-weight: 600;

        a {
          color: $info;
          font-weight: 600;
        }
      }
    }
  }
}

.attachments {
  border-radius: 6px;

  h3 {
    position: relative;
    font-size: 21px;
    font-weight: 600;
    color: $dark;
    margin: 6px 0px 40px 0;

    &:after {
      position: absolute;
      content: '';
      height: 2px;
      width: 90px;
      background: $primary;
      border-radius: 50%;
      bottom: -2px;
      left: 15px;
    }
  }

  .timeline-alter .item-timeline {
    display: flex;

    .t-dot {
      position: relative;
      border-color: $primary;
      padding: 10px;

      &:before {
        content: '';
        position: absolute;
        border: 2px solid $primary;
        border-color: inherit;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &:not(:last-child) .t-dot:after {
      content: '';
      position: absolute;
      border-left: 2px solid $m-color_2;
      transform: translateX(-50%);
      width: 0;
      height: auto;
      top: 25px;
      left: 50%;
      bottom: -15px;
      border-radius: 0;
    }

    .t-dot img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      z-index: 7;
      position: relative;
    }

    .t-meta-date {
      padding: 10px;

      p {
        margin: 0;
        min-width: 150px;
        max-width: 250px;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        color: $m-color_6;
      }
    }

    .t-text {
      padding: 10px;
      align-self: center;

      p {
        font-size: 13px;
        margin: 0;
        color: $m-color_9;
        font-weight: 600;

        a {
          color: $info;
          font-weight: 600;
        }
      }
    }
  }
}


@media (max-width: 575px) {
  .work-experience .knowledge .attachments .timeline-alter .item-timeline {
    display: block;
    text-align: center;

    .t-meta-date p,
    .t-usr-txt p {
      margin: 0 auto;
    }
  }
}
