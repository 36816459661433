body.purple {
  background: #0e1726;
  font-family: Poppins;
}

.btn-help {
  box-shadow: 0 10px 20px -10px rgba(92, 26, 195, 0.59);
}


.purple {
  /*placeholder*/
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #e3e4eb;
  }

  input::-ms-input-placeholder,
  textarea::-ms-input-placeholder {
    color: #e3e4eb;
  }

  input::-moz-placeholder,
  textarea::-moz-placeholder {
    color: #e3e4eb;
  }

  ::placeholder {
    opacity: 0.8;
  }

  .message-li {
    color: #ffffff;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #e0e6ed;
    font-family: "Baloo 2", sans-serif;
  }

  hr {
    border-top: 1px solid #191e3a;
  }

  .custom-file-input:focus ~ .custom-file-label {
    border: 1px solid #3b3f5c;
  }

  &::after {
    border-left: 1px solid #3b3f5c;
  }

  .jumbotron {
    background-color: #1b2e4b;
  }

  /*large-boxed-layout*/
  .large-boxed-layout .sub-header-container {
    background: #1a1c2d;
  }

  /*modal popup*/
  .btn-close {
    background: transparent url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e') center/1em auto no-repeat;
    background-size: 12px;
  }

  .modal-backdrop {
    background-color: #1b2e4b;
    opacity: 0.8;
  }

  .modal-header {
    border-color: #191e3a !important;

    h5,
    .close {
      color: #bfc9d4;
    }

    .close {
      text-shadow: none;
    }
  }

  .modal-content {
    background: #0e1726;
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.2);

    .modal-footer {
      button {
        &.btn[data-dismiss='modal'] {
          border: 1px solid #1b2e4b;
          background-color: #1b2e4b;
          color: #bfc9d4;
          font-weight: 500;
        }
      }
    }
  }

  .modal-body {
    .nav-tabs {
      border-bottom: 1px solid #191e3a;

      .nav-link {
        &.active {
          background-color: #191e3a;
          border-color: #191e3a #191e3a #0e1726;
        }

        &:hover {
          border-color: #191e3a #191e3a #191e3a;
        }
      }
    }
  }

  .modal-notification {
    .modal-body {
      .icon-content {
        background: #bfc9d4;

        svg {
          color: #1b2e4b;
        }
      }
    }
  }

  .modal-footer {
    border-top: 1px solid #191e3a !important;
    padding: 1rem 0.75rem;

    .btn-outline-dark {
      background-color: #1b2e4b;
      color: #bfc9d4 !important;
      border: 1px solid #1b2e4b !important;
    }
  }

  .profile-modal {
    .modal-content {
      .modal-footer {
        border: none !important;
      }
    }
  }

  .login-modal,
  .register-modal {
    .modal-dialog {
      max-width: 400px;
    }

    .modal-body {
      .form-group {
        input {
          border-bottom: 1px solid #191e3a;
        }

        svg {
          color: #25d5e4;
        }
      }

      .social {
        a {
          border: 2px solid #191e3a;
        }
      }
    }
  }

  /*blockquote*/
  blockquote {
    &.blockquote {
      color: #009688;
      background-color: #060818;
      border: 1px solid #060818;
      border-left: 2px solid #009688;
    }
  }

  .small,
  footer {
    color: #888ea8;
  }

  a {
    color: #e0e6ed;

    &:hover {
      color: #bfc9d4;
    }
  }

  .card {
    border: 1px solid #191e3a;
    background: #060818;
  }

  /*      Form Group Label       */
  .form-group {
    .col-form-label,
    label {
      color: #e3e4eb;
    }
  }

  .custom-control-input:disabled ~ .custom-control-label {
    cursor: no-drop;
  }

  .form-control {
    &:disabled,
    &[readonly] {
      background-color: #1b2e4b !important;
      cursor: no-drop;
      color: #d3d3d3;
    }
  }

  .custom-control-input {
    &:disabled ~ .custom-control-label::before,
    &[disabled] ~ .custom-control-label::before {
      background-color: #3b3f5c !important;
      cursor: no-drop;
      border: 2px solid #3b3f5c !important;
    }
  }

  .form-control {
    border: 1px solid #1b2e4b;
    color: #009688;
    background: #1b2e4b;

    &[type='range'] {
      padding: 0;
    }

    &:focus {
      box-shadow: none;
      border-color: #3b3f5c;
      color: #25d5e4;
      background-color: #1b2e4b;
    }

    &::-webkit-input-placeholder,
    &::-ms-input-placeholder,
    &::-moz-placeholder {
      color: #e3e4eb;
    }

    &:focus {
      &::-webkit-input-placeholder,
      &::-ms-input-placeholder,
      &::-moz-placeholder {
        color: #d3d3d3;
      }
    }
  }

  @supports (-webkit-overflow-scrolling: touch) {
    /* CSS specific to iOS devices */
    .form-control {
      color: #0e1726;
    }
  }
  /*      Custom Select       */
  .custom-control-label::before {
    background-color: #1b2e4b;
    border-color: #1b2e4b;
  }

  .header-checkbox {
    .custom-control-label::before {
      background-color: #0e1726;
      border-color: #0e1726;
    }
  }

  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    border-color: #1b2e4b;
  }

  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #1b2e4b;
  }

  .radio-classic.radio-default {
    .custom-control-label {
      letter-spacing: 1px;
      margin-right: 1rem;

      &::before {
        border: 2px solid #888ea8;
        background-color: #0e1726;
      }
    }

    .custom-control-input:checked ~ .custom-control-label {
      &::after {
        background-image: none;
      }
    }
  }

  .radio-classic.radio-warning {
    .custom-control-label {
      letter-spacing: 1px;
      margin-right: 1rem;

      &::before {
        border: 2px solid #e2a03f !important;
        background-color: #0e1726 !important;
      }
    }

    .custom-control-input:checked ~ .custom-control-label {
      &::after {
        background-image: none;
      }
    }
  }

  .radio-classic.radio-info {
    .custom-control-label {
      letter-spacing: 1px;
      margin-right: 1rem;

      &::before {
        border: 2px solid #2196f3 !important;
        background-color: #0e1726 !important;
      }
    }

    .custom-control-input:checked ~ .custom-control-label {
      &::after {
        background-image: none;
      }
    }
  }

  .radio-classic.radio-secondary {
    .custom-control-label {
      letter-spacing: 1px;
      margin-right: 1rem;

      &::before {
        border: 2px solid #805dca !important;
        background-color: #0e1726 !important;
      }
    }

    .custom-control-input:checked ~ .custom-control-label {
      &::after {
        background-image: none;
      }
    }
  }

  .radio-classic.radio-dark {
    .custom-control-label {
      letter-spacing: 1px;
      margin-right: 1rem;

      &::before {
        border: 2px solid #3b3f5c !important;
        background-color: #0e1726 !important;
      }
    }

    .custom-control-input:checked ~ .custom-control-label {
      &::after {
        background-image: none;
      }
    }
  }

  .radio-classic.radio-primary {
    .custom-control-label {
      letter-spacing: 1px;
      margin-right: 1rem;

      &::before {
        border: 2px solid #4361ee;
        background-color: #0e1726;
      }
    }

    .custom-control-input:checked ~ .custom-control-label {
      &::after {
        background-image: none;
      }
    }
  }

  .radio-classic.radio-success {
    .custom-control-label {
      letter-spacing: 1px;
      margin-right: 1rem;

      &::before {
        border: 2px solid #1abc9c !important;
        background-color: #0e1726 !important;
      }
    }

    .custom-control-input:checked ~ .custom-control-label {
      &::after {
        background-image: none;
      }
    }
  }

  .radio-classic.radio-danger {
    .custom-control-label {
      letter-spacing: 1px;
      margin-right: 1rem;

      &::before {
        border: 2px solid #e7515a !important;
        background-color: #0e1726 !important;
      }
    }

    .custom-control-input:checked ~ .custom-control-label {
      &::after {
        background-image: none;
      }
    }
  }

  .form-select {
    border: 1px solid #1b2e4b;
    color: #009688;
    background: #1b2e4b url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23009688' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem (center / 8px) 10px;

    &:focus {
      border-color: #3b3f5c;
      color: #25d5e4;
      background-color: #1b2e4b;
      box-shadow: none !important;
    }

    &:disabled {
      background: #1b2e4b url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem (center / 8px) 10px;
    }

    &.is-invalid,
    &:invalid {
      border-color: #dc3545;
    }
  }

  .form-control:disabled,
  .form-control[readonly],
  .form-select:disabled {
    background-color: #1b2e4b !important;
    cursor: no-drop;
    color: #d3d3d3 !important;
  }

  /*      Form Control Custom File       */

  .custom-file-label {
    border: 1px solid #1b2e4b;
    color: #009688;
    background-color: #1b2e4b;

    &::after {
      color: #bfc9d4;
      background-color: #191e3a;
    }
  }

  .dropdown-menu {
    border: none;
    background-color: #1b2e4b;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);

    a.dropdown-item {
      color: #bfc9d4;

      &:hover {
        color: #2196f3;
      }
    }

    .dropdown-item:hover {
      color: #2196f3;
    }
  }

  .dropdown-divider {
    border-top: 1px solid #0e1726;
  }

  .input-group-text {
    border: 1px solid #1b2e4b;
    background-color: #191e3a;
    color: #888ea8;
  }

  .input-group-append .input-group-text {
    border: 1px solid #1b2e4b;
    background-color: #191e3a;
    color: #888ea8;
  }

  /*      Validation Customization      */
  .valid-feedback {
    color: #009688;
  }

  .valid-tooltip {
    background-color: #009688;
  }

  .form-select.is-valid,
  .form-control.is-valid {
    border-color: #009688;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23009688' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3e%3cpolyline points='20 6 9 17 4 12'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  .form-control.is-invalid,
  .was-validated .form-control:invalid {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23e7515a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3e%3cline x1='18' y1='6' x2='6' y2='18'%3e%3c/line%3e%3cline x1='6' y1='6' x2='18' y2='18'%3e%3c/line%3e%3c/svg%3e");
    background-repeat: no-repeat;
  }

  .form-control.is-invalid,
  .was-validated .form-control:invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  .was-validated {
    .form-select:valid,
    .form-control:valid {
      border-color: #009688;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23009688' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3e%3cpolyline points='20 6 9 17 4 12'%3e%3c/polyline%3e%3c/svg%3e");
    }
  }

  .custom-control-input.is-valid ~ .custom-control-label,
  .was-validated .custom-control-input:valid ~ .custom-control-label {
    color: #009688;
  }

  .custom-checkbox .custom-control-input.is-valid ~ .custom-control-label {
    color: #e3e4eb;
  }

  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    background-color: #1b2e4b;
    border-color: #1b2e4b;
  }

  .btn {
    &:hover,
    &:focus {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }

  .btn-group {
    .dropdown-menu {
      border: none;
      background-color: #1b2e4b;
    }
  }

  .dropdown-divider {
    border-top: 1px solid #0e1726;
  }

  .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu {
    border: none;
    box-shadow: none;
    background: #1b2e4b;

    a.dropdown-item {
      &:hover {
        background: transparent !important;
      }
    }
  }

  /*      Default Buttons       */
  .btn-primary {
    box-shadow: 0 10px 20px -10px rgba(27, 85, 226, 0.59);
  }

  .btn-secondary {
    box-shadow: 0 10px 20px -10px rgba(92, 26, 195, 0.59);
  }

  .btn-info {
    box-shadow: 0 10px 20px -10px rgba(33, 150, 243, 0.59);
  }

  .btn-warning {
    box-shadow: 0 10px 20px -10px rgba(226, 160, 63, 0.59);
  }

  .btn-danger {
    box-shadow: 0 10px 20px -10px rgba(231, 81, 90, 0.59);
  }

  .btn-dark {
    box-shadow: 0 10px 20px -10px rgba(59, 63, 92, 0.59);
  }

  .btn-success {
    box-shadow: 0 10px 20px -10px rgba(141, 191, 66, 0.59);
  }

  .btn-outline-primary:hover {
    box-shadow: 0 10px 20px -10px rgba(27, 85, 226, 0.59);
  }

  .btn-outline-info:hover {
    box-shadow: 0 10px 20px -10px rgba(33, 150, 243, 0.588);
  }

  .btn-outline-warning:hover {
    box-shadow: 0 10px 20px -10px rgba(226, 160, 63, 0.588);
  }

  .btn-outline-success:hover {
    box-shadow: 0 10px 20px -10px rgba(141, 191, 66, 0.59);
  }

  .btn-outline-danger:hover {
    box-shadow: 0 10px 20px -10px rgba(231, 81, 90, 0.588);
  }

  .btn-outline-secondary:hover {
    box-shadow: 0 10px 20px -10px rgba(92, 26, 195, 0.59);
  }

  .btn-outline-dark:hover {
    box-shadow: 0 10px 20px -10px rgba(59, 63, 92, 0.59);
  }

  .badge-collapsed-img {
    border: 2px solid #515365;
    box-shadow: none;
  }

  p {
    color: #ebedf2;
  }

  .page-title {
    h3 {
      color: #bfc9d4;
    }
  }

  .row [class*='col-'] .widget .widget-heading h4 {
    color: #ebedf2;
  }

  .alert-light-info {
    color: #ebedf2;
    background-color: #101928;
    border-color: #374856;

    a {
      color: #ebedf2 !important;
    }
  }

  .widget.box {
    .widget-heading {
      background: #0e1726;
    }
  }

  .widget-content {
    background-color: #0e1726;
    box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%);
    border: 1px solid #0e1726;
  }

  /*
    =====================
        Navigation Bar
    =====================
    */

  .header-container {
    border-bottom: 1px solid #060818;
  }

  .navbar {
    background: #060818;
  }

  .header-container .navbar {
    padding: 5px 0;

    .navbar-item .nav-item {
      &.dropdown {
        .dropdown-menu {
          border: none;
          -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
          box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
          background: #1b2e4b;

          a.dropdown-item:hover,
          a.dropdown-item.active {
            background: transparent !important;
            color: #fff !important;

            svg {
              color: #fff !important;
            }
          }
        }
      }
    }

    .language-dropdown {
      .dropdown-menu {
        .dropdown-item {
          &:hover {
            background: transparent !important;
          }

          &.active,
          &:active {
            background: transparent;

            span {
              color: #fff;
            }
          }
        }

        a {
          span {
            color: #bfc9d4;
            font-weight: 500;
          }

          &.dropdown-item:hover span {
            color: #fff !important;
          }
        }
      }
    }
  }

  /*   Language   */
  /*   Language Dropdown  */
  /*Message Dropdown*/

  .navbar .navbar-item .nav-item {
    &.dropdown {
      &.message-dropdown {
        .nav-link {
          svg {
            color: #e0e6ed;
            stroke-width: 1.6;
          }
        }

        .dropdown-menu {
          padding: 10px !important;

          .media {
            .media-body h5.usr-name {
              color: #bfc9d4;
              font-weight: 500;
            }
          }

          .dropdown-item:hover .media-body h5.usr-name {
            color: #fff;
          }
        }
      }

      &.notification-dropdown {
        .nav-link {
          svg {
            color: #888ea8;
            stroke-width: 1.6;
          }
        }

        .dropdown-menu {
          .dropdown-item {
            &:not(:last-child) {
              border-bottom: 1px solid rgba(59, 63, 92, 0.25);
            }
          }

          .data-info {
            h6 {
              font-weight: 500;
              color: #bfc9d4;
            }
          }

          .dropdown-item:hover .data-info h6 {
            color: #fff;
          }
        }
      }
    }

    form.form-inline input.search-form-control {
      background-color: #0e1726;
    }
  }

  /*Notification Dropdown*/

  .navbar .navbar-item .nav-item {
    &.user-profile-dropdown {
      .dropdown-menu {
        .dropdown-item {
          &:hover svg {
            color: #fff !important;
          }
        }
      }
    }
  }

  .sub-header-container {
    .navbar {
      -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
      background: rgb(26, 28, 45);
      border: none;

      .sidebarCollapse {
        svg {
          color: #e0e6ed;
          stroke-width: 1.6;
        }
      }
    }

    .breadcrumb-one {
      .breadcrumb-item {
        a {
          color: #e0e6ed;

          svg {
            fill: #e0e6ed;
          }
        }

        &.active {
          color: #fff;
        }

        + .breadcrumb-item {
          &::before {
            color: #e0e6ed;
          }
        }
      }
    }

    .more-dropdown .custom-dropdown-icon {
      a.dropdown-toggle {
        border: 1px solid #3b3f5c;
        background-color: rgb(26, 28, 45);
        color: #e0e6ed;

        svg {
          color: #e0e6ed;
        }
      }

      .dropdown-menu {
        border: none;
        background-color: #191e3a;
        -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);

        a {
          color: #e0e6ed;

          &:hover {
            background-color: rgba(59, 63, 92, 0.45);
            color: #e0e6ed;
          }
        }
      }
    }
  }

  /*
    ===============
        Sidebar
    ===============
    */
  .shadow-bottom {
    width: 101.6%;
    background: -webkit-linear-gradient(top, #1a233af0 44%, #1a233abf 73%, #2c303c00);
    background: linear-gradient(180deg, #060818 49%, #060818eb 85%, #2c303c00);
  }

  #sidebar {
    ul.menu-categories {
      &.ps {
        border-right: 1px solid #0e1726;
      }
    }

    .ps__thumb-y {
      background-color: #515365;
    }

    .ps__rail-y:hover > .ps__thumb-y {
      background-color: #3b3f5c;
    }

    ul.menu-categories {
      li {
        &.menu {
          > .dropdown-toggle {
            color: #506690;

            &.active {
              background: #3b3f5c !important;
              color: #e0e6ed !important;

              svg {
                color: #e0e6ed;
                fill: none;
              }

              span {
                color: #e0e6ed;
              }
            }
          }

          > {
            .dropdown-toggle {
              svg {
                fill: rgba(0, 23, 55, 0.08);
              }

              &[data-active='true'] {
                background: rgba(96, 125, 139, 0.54);
                color: #fafafa;

                svg {
                  color: #e0e6ed;
                  fill: none;
                }

                &:hover svg,
                span {
                  color: #e0e6ed;
                }
              }

              &:hover,
              &.dropdown-toggle:not([data-active='true']):not([aria-expanded='true']):hover {
                background: #3b3f5c;
                color: #e0e6ed;
              }

              &[aria-expanded='true']:not([data-active='true']) {
                background: #3b3f5c;
                color: #e0e6ed;
              }

              &:hover svg {
                color: #e0e6ed;
              }

              &[aria-expanded='true']:not([data-active='true']) svg {
                color: #e0e6ed;
                fill: rgba(0, 23, 55, 0.08);
              }
            }
          }

          &.active > a {
            background: #3b3f5c;
            color: #e0e6ed;

            svg {
              color: #e0e6ed;
              fill: none;
            }

            &:hover svg,
            span {
              color: #e0e6ed;
            }
          }
        }
      }

      ul.submenu > li a {
        color: #888ea8;
      }

      li.menu ul.submenu > li a:before {
        background-color: #888ea8;
      }

      ul.submenu > li a {
        &.active {
          color: #61b6cd;
        }
      }

      li.menu ul.submenu > li.active a:before {
        background-color: #61b6cd;
      }

      ul.submenu {
        > li {
          a {
            &:hover {
              color: #61b6cd;

              &:before {
                background-color: #61b6cd !important;
              }
            }
          }
        }

        li > {
          [data-active='true'] {
            i {
              color: #61b6cd;
            }

            &:before {
              background-color: #61b6cd !important;
            }
          }

          a[data-active='true'] {
            color: #61b6cd;
          }
        }

        > li ul.sub-submenu > li {
          a {
            color: #506690;
          }

          &.active a {
            color: #009688;
          }

          a {
            &:hover {
              color: #009688;
            }
          }

          &.active a:before {
            background-color: #009688;
          }
        }
      }
    }
  }

  /*
    ======================
        Footer-wrapper
    ======================
    */
  .footer-wrapper .footer-section {
    p {
      color: #888ea8;

      a {
        color: #888ea8;
      }
    }
  }

  .hljs {
    background: #191e3a;
  }

  /*
    ======================
        MEDIA QUERIES
    ======================
    */
  @media (max-width: 991px) {
    #sidebar {
      background: #060818;
    }
  }
  /*perfect scrollbar*/
  .ps .ps__rail-x:hover,
  .ps .ps__rail-y:hover,
  .ps .ps__rail-x:focus,
  .ps .ps__rail-y:focus,
  .ps .ps__rail-x.ps--clicking,
  .ps .ps__rail-y.ps--clicking {
    background-color: transparent;
  }

  /*apex chart*/
  .apexcharts-canvas {
    .apexcharts-title-text {
      fill: #e0e6ed;
    }

    .apexcharts-text.apexcharts-xaxis-label,
    .apexcharts-text.apexcharts-yaxis-label {
      fill: #e0e6ed;
    }

    .apexcharts-legend-text {
      color: #e0e6ed !important;
    }

    .apexcharts-radialbar-track.apexcharts-track .apexcharts-radialbar-area {
      stroke: #191e3a;
    }

    .apexcharts-pie-label,
    .apexcharts-datalabel,
    .apexcharts-datalabel-label,
    .apexcharts-datalabel-value {
      fill: #bfc9d4;
    }

    .apexcharts-tooltip.apexcharts-theme-dark {
      box-shadow: none;
    }
  }
}

/*components*/
.purple {
  /*Tabs*/
  .tabs {
    .tab-content {
      .media img {
        border: solid 5px #191e3a;
      }
    }
  }

  .simple-tab {
    .nav-tabs {
      border-bottom: 1px solid #191e3a;

      li {
        a {
          color: #888ea8;
        }
      }

      .nav-item {
        .nav-link {
          &:hover {
            border-color: #191e3a #191e3a #191e3a;
          }

          &.active {
            color: #25d5e4;
            background-color: #0e1726;
            border-color: #191e3a #191e3a #0e1726;
          }

          &.disabled {
            color: #6c757d;
            background-color: transparent;
            border-color: transparent;
          }
        }
      }
    }
  }

  .simple-pills {
    .nav-pills {
      li {
        a {
          color: #888ea8;
        }
      }

      .nav-link {
        &.active {
          background-color: #191e3a;
          color: #25d5e4;
          border-color: transparent;
        }
      }
    }
  }

  .icon-tab {
    .nav-tabs {
      border-bottom: 1px solid #191e3a;

      li {
        a {
          color: #888ea8;
        }
      }

      .nav-link {
        &.active {
          color: #e95f2b;
          background-color: #191e3a;
          border-color: #191e3a #191e3a #0e1726;
        }

        &:hover {
          border-color: #191e3a #191e3a #191e3a;
        }
      }
    }
  }

  .icon-pill {
    .nav-pills {
      li {
        a {
          color: #888ea8;
        }
      }

      .nav-link {
        &.active {
          background-color: #191e3a;
          color: #e2a03f;
          border-color: transparent;
        }
      }
    }
  }

  .vertical-border-pill {
    .nav-pills {
      .nav-link {
        border: 1px solid #191e3a;
        color: #888ea8;

        &.active {
          color: #1abc9c;
          background-color: #191e3a;
        }

        &:last-child {
          border-bottom: 1px solid #191e3a;
        }
      }
    }
  }

  .border-tab {
    .nav-tabs {
      border-bottom: 1px solid #191e3a;

      li {
        a {
          color: #e0e6ed;
        }
      }

      .nav-link {
        &.active {
          color: #e95f2b;
          background-color: #191e3a;
          border-color: #191e3a #191e3a #0e1726;
        }

        &:hover {
          border-color: #191e3a #191e3a #191e3a;
        }
      }
    }

    .tab-content {
      border: 1px solid #191e3a;
      border-top: none;
    }
  }

  .border-top-tab {
    .nav-tabs {
      li {
        a {
          color: #888ea8;
          border-right: 1px solid transparent;
          background: none;
        }
      }

      .nav-link {
        &.active {
          color: #e0e6ed;
          background: #191e3a;
          border: 1px solid transparent;
          border-top: 2px solid #e0e6ed;
        }

        &:hover {
          border: 1px solid transparent;
          border-top: 2px solid #e0e6ed;
        }
      }
    }
  }

  .underline-content {
    .nav-tabs {
      border-bottom: 1px solid #191e3a;

      li {
        a {
          color: #e0e6ed;
          outline: none;
        }
      }

      .nav-link {
        &.active {
          border-bottom: 1px solid #888ea8;
          color: #888ea8;
          background-color: transparent;
        }

        &:hover {
          border-color: transparent;
        }
      }
    }

    .tab-content {
      border-top: none;
    }
  }

  .animated-underline-content {
    .nav-tabs {
      border-bottom: 1px solid #191e3a;

      li {
        a {
          color: #e0e6ed;
          outline: none;
        }
      }

      .nav-link {
        &.active {
          border-color: transparent;
          color: #e0e6ed;
          background-color: #191e3a;
        }

        &:hover {
          border-color: transparent;
          color: #bfc9d4;
        }

        &::before {
          background-color: #e0e6ed;
        }
      }
    }

    .tab-content {
      border: 1px solid #191e3a;
      border-top: none;
    }
  }

  .vertical-line-pill {
    .nav-pills {
      border-right: 1px solid #191e3a;

      .nav-link {
        color: #888ea8;

        &.active {
          color: #e0e6ed;
        }

        &::before {
          background-color: #e0e6ed;
        }
      }
    }

    .tab-pane {
      h4 {
        color: #e2a03f;
      }

      p {
        color: #888ea8;
      }
    }
  }

  .justify-tab {
    .nav-tabs {
      border-bottom: 1px solid #191e3a;

      li {
        a {
          color: #888ea8;
        }
      }

      .nav-link {
        &.active {
          color: #25d5e4;
          background-color: #191e3a;
          border-color: #191e3a #191e3a #0e1726;
        }

        &:hover {
          border-color: #191e3a #191e3a #191e3a;
        }
      }
    }
  }

  .justify-pill {
    .nav-pills {
      li {
        a {
          color: #888ea8;
        }
      }

      .nav-link {
        &.active {
          background-color: #191e3a;
          color: #2196f3;
          border-color: transparent;
        }
      }
    }
  }

  .tab-justify-centered {
    .nav-tabs {
      border-bottom: 1px solid #191e3a;

      li {
        a {
          color: #888ea8;
        }
      }

      .nav-link {
        &.active {
          color: #e95f2b;
          background-color: #191e3a;
          border-color: #191e3a #191e3a #0e1726;
        }

        &:hover {
          border-color: #191e3a #191e3a #191e3a;
        }
      }
    }
  }

  .pill-justify-centered {
    .nav-pills {
      li {
        a {
          color: #888ea8;
        }
      }

      .nav-link {
        &.active {
          background-color: #191e3a;
          color: #e2a03f;
        }
      }
    }
  }

  .tab-justify-right {
    .nav-tabs {
      border-bottom: 1px solid #191e3a;

      li {
        a {
          color: #888ea8;
        }
      }

      .nav-link {
        &.active {
          color: #25d5e4;
          background-color: #191e3a;
          border-color: #191e3a #191e3a #0e1726;
        }

        &:hover {
          border-color: #191e3a #191e3a #191e3a;
        }
      }
    }
  }

  .pill-justify-right {
    .nav-pills {
      li {
        a {
          color: #888ea8;
        }
      }

      .nav-link {
        &.active {
          background-color: #191e3a;
          color: #2196f3;
        }
      }
    }
  }

  .rounded-pills-icon,
  .rounded-circle-pills-icon,
  .rounded-vertical-pills-icon,
  .rounded-circle-vertical-pills-icon {
    .nav-pills {
      li {
        a {
          background-color: #191e3a;
          color: #888ea8;
          border: none;
        }
      }

      .nav-link {
        &.active {
          color: #fff;
        }
      }
    }
  }

  .vertical-pill,
  .vertical-pill-right {
    .nav-pills {
      .nav-link {
        color: #888ea8;

        &.active {
          color: #fff;
        }
      }
    }
  }

  /*Accordions*/
  .panel-body {
    .card {
      border: 2px solid #1b2e4b;
      background: #191e3a;
      box-shadow: none;

      .card-body {
        ul {
          li {
            a {
              color: #888ea8;
              font-size: 13px;
              font-weight: 600;
            }
          }
        }
      }
    }

    .no-outer-spacing {
      border: 1px solid #3b3f5c;

      .card {
        &:not(:last-child) {
          border-bottom: 1px solid #3b3f5c;
        }
      }
    }

    .card-header {
      background-color: #1b2e4b;
      border-color: #1b2e4b;

      section {
        > div {
          &:not(.collapsed) {
            color: #25d5e4;
            border-bottom: 2px solid #1b2e4b;
            font-weight: 600;
          }
        }
      }
    }

    .accordion-icons {
      div {
        &:not(.collapsed) {
          .accordion-icon {
            svg {
              color: #25d5e4;
            }
          }
        }
      }
    }
  }

  /*cards*/
  .card {
    &.component-card_1,
    &.component-card_2,
    &.component-card_3,
    &.component-card_4,
    &.component-card_5,
    &.component-card_6,
    &.component-card_7,
    &.component-card_8,
    &.component-card_9 {
      border: 1px solid #1b2e4b;
    }

    &.component-card_3 {
      background: #3b3f5c;
    }

    &.component-card_4 {
      .card-text {
        color: #bfc9d4;
      }
    }

    &.component-card_5 {
      background: rgba(92, 26, 195, 0.26);
      border: none;
    }

    &.component-card_6 {
      border: 1px solid #1b2e4b;

      .card-body {
        .card-text {
          color: #888ea8;
        }

        h5 {
          &.card-user_name {
            color: #bfc9d4;
          }
        }
      }
    }

    &.component-card_7 {
      background: rgba(27, 85, 226, 0.3803921568627451);
    }

    &.component-card_8 {
      border: 1px solid #1b2e4b;

      .progress-order {
        .progress-order-header {
          h6 {
            color: #bfc9d4;
          }

          span {
            &.badge {
              color: #eaf1ff;
              background-color: #4361ee;
            }
          }
        }

        .progress-order-body {
          .badge-collapsed-img {
            border: none;

            img {
              border: 2px solid #515365;
            }
          }

          .notification {
            span {
              &.badge {
                background-color: #0e1726;
                color: #e0e6ed;
                box-shadow: none;
              }
            }
          }

          .p-o-percentage {
            color: #e0e6ed;
          }

          .progress {
            background-color: #0e1726;
            box-shadow: none;
          }
        }
      }
    }

    &.component-card_9 {
      .meta-date {
        color: #009688;
      }

      .meta-info {
        &::before {
          background: #1b2e4b;
        }
      }

      .user-name {
        color: #25d5e4;
      }

      .meta-likes,
      .meta-view {
        color: #009688;

        svg {
          color: #009688;
        }
      }
    }
  }

  /*Timeline*/
  .timeline-simple {
    p {
      &.timeline-title {
        color: #009688 !important;

        &::before {
          background: #009688;
        }
      }
    }

    .timeline-list {
      .timeline-post-content {
        div {
          &.user-profile {
            &::after {
              border-color: #191e3a;
            }
          }

          p.meta-time-date {
            color: #ebedf2 !important;
          }
        }

        h4 {
          color: #009688;
        }

        svg {
          color: #888ea8;
        }

        h6 {
          color: #d3d3d3;
          padding-left: 5px;
        }

        &:hover {
          svg {
            color: #009688;
          }

          h6 {
            color: #888ea8;
          }
        }
      }
    }
  }

  .modern-timeline {
    &::before {
      background: #191e3a;
    }

    .modern-timeline-top,
    .modern-timeline-bottom {
      &::before {
        background: #191e3a;
      }
    }

    > li > {
      .modern-timeline-badge {
        background: #1b2e4b;
        border: 3px solid #191e3a;
      }

      .modern-timeline-panel {
        border: 1px solid #191e3a !important;
        background: #191e3a;
        box-shadow: none;

        &::before {
          background: #191e3a;
        }

        .modern-timeline-body {
          h4 {
            color: #009688;
          }

          p {
            color: #888ea8;
          }
        }
      }
    }
  }

  .timeline-line {
    .item-timeline {
      .t-time {
        color: #888ea8;
      }

      .t-text {
        p {
          color: #888ea8;

          a {
            color: #009688;
          }
        }

        .t-meta-time {
          color: #506690;
        }
      }
    }
  }

  .timeline-alter {
    .item-timeline {
      .t-time,
      .t-text {
        p {
          color: #888ea8;

          a {
            color: #009688;
          }
        }
      }

      .t-img {
        border-color: #191e3a;
      }

      .t-usr-txt {
        border-color: #191e3a;

        p {
          background: #4361ee;
          color: #eaf1ff;
        }
      }

      .t-meta-time {
        p {
          color: #888ea8;
        }
      }
    }
  }

  /* count down */
  #cd-simple {
    .clock-count-container {
      border: 1px solid #191e3a;
      box-shadow: none;
    }

    .clock-val {
      color: #25d5e4;
    }

    .clock-text {
      color: #888ea8;
    }
  }

  #cd-circle {
    .clock-count-container {
      border: 1px solid #191e3a;
      box-shadow: none;
    }

    .clock-val {
      color: #25d5e4;
    }

    .clock-text {
      color: #888ea8;
    }
  }

  /*Counter*/
  /**Simple Counter**/
  .simple--counter-container {
    .counter-container {
      .counter-content {
        border: 1px solid #191e3a;
        box-shadow: none;
      }
    }

    .s-counter {
      color: #25d5e4;
    }

    .s-counter-text {
      color: #888ea8;
    }
  }

  /**With Icon**/
  .icon--counter-container {
    .counter-ico {
      color: #25d5e4;
    }

    .counter-content {
      box-shadow: none;
      border: 1px solid #191e3a;
    }

    .ico-counter {
      color: #888ea8;
    }

    .ico-counter-text {
      color: #888ea8;
    }
  }

  /*Media Object*/
  .media {
    .media-body {
      .media-heading {
        color: #bfc9d4;

        div {
          &.dropdown-list {
            a {
              &.dropdown-item {
                &:hover {
                  color: #2196f3 !important;

                  svg {
                    color: #e0e6ed !important;
                  }
                }
              }
            }
          }
        }
      }

      .media-text {
        color: #888ea8;
      }

      .media-notation {
        a {
          color: #506690 !important;

          svg {
            color: #506690 !important;
          }
        }
      }
    }

    .media-aside {
      .checkbox-dark {
        label {
          &::before {
            background-color: #3b3f5c !important;
          }
        }
      }

      .radio-classic {
        label {
          &::before {
            background-color: #3b3f5c !important;
          }
        }

        .custom-control-input {
          &:checked ~ .custom-control-label {
            &::after {
              background-image: none;
              background-color: #0e1726;
              border: 3px solid #3b3f5c;
              border-radius: 60px;
            }
          }
        }
      }
    }
  }

  /*sweetalert*/
  .swal2-modal {
    background-color: #0e1726 !important;

    .swal2-image {
      border-radius: 6px;
    }
  }

  .swal2-popup {
    .swal2-title {
      color: #bfc9d4 !important;
    }

    .swal2-styled {
      &.swal2-cancel {
        background-color: #1b2e4b !important;
        color: #bfc9d4;
        border: 1px solid #1b2e4b !important;
      }
    }

    &.swal2-toast {
      background: #0e1726;
      box-shadow: none;
    }
  }

  .swal2-icon {
    &.swal2-success {
      .swal2-success-ring {
        border: 5px solid #1b2e4b;
      }
    }

    &.swal2-success [class^='swal2-success-line'] {
      background-color: #009688;
    }

    &.swal2-question {
      color: #506690 !important;
      border: solid 5px #1b2e4b !important;
      box-shadow: none;
    }

    &.swal2-info {
      color: #506690 !important;
      border: solid 5px #1b2e4b !important;
      box-shadow: none;
    }

    &.swal2-warning,
    &.swal2-error {
      border: solid 5px #1b2e4b !important;
      box-shadow: none;
    }
  }

  .swal2-input {
    border: 1px solid #1b2e4b !important;
    background: #1b2e4b;
    color: #25d5e4;
    margin: 1em auto;
    border-radius: 0.1875em;
    width: 100%;
    transition: border-color 0.3s, box-shadow 0.3s;
  }

  .swal2-progress-steps {
    .swal2-progress-step {
      &.swal2-active-progress-step {
        background: #191e3a !important;

        & ~ .swal2-progress-step {
          background: #506690 !important;
        }

        & ~ .swal2-progress-step-line {
          background: #506690 !important;
        }
      }
    }
  }

  /*List Group*/
  .list-group {
    &.list-group-icons-meta,
    &.list-group-media,
    &.task-list-group {
      .list-group-item {
        &.active {
          background-color: #191e3a;
          color: #fff;
        }

        .media {
          margin: 0px;

          svg {
            color: #25d5e4 !important;
          }

          .media-aside {
            margin-right: 1rem !important;
          }

          .media-body {
            h6 {
              color: #888ea8;
            }

            p {
              color: #888ea8;
            }
          }
        }
      }
    }

    .list-group-item {
      border: 1px solid #191e3a;
      background-color: #1b2e4b;
      color: #888ea8;

      &.active {
        background-color: #191e3a;
        border-color: transparent;
        box-shadow: 0 1px 15px 1px rgb(52 40 104 / 15%);
        color: #fff;

        .checkbox-primary {
          label {
            span {
              color: #fff;
            }
          }
        }
      }

      &.disabled {
        background: #506690;
        color: #888ea8;
      }

      .checkbox-primary {
        label {
          span {
            color: #888ea8;

            .badge {
              color: #fff;
            }
          }
        }
      }

      .custom-control-label {
        &::before {
          background-color: #515365;
          border-color: transparent;
        }
      }
    }
  }

  .list-group-item-action {
    &:hover {
      color: #e0e6ed;
      background-color: #3b3f5c;
      box-shadow: 0px 0px 12px 1px rgb(113 106 202 / 8%);
    }
  }

  /*Pricing Tables*/
  .pricing-table {
    .billed-yearly-radio {
      span {
        &.txt-monthly {
          color: #009688;
        }
      }

      .switch {
        &.s-outline[class*='s-outline-'] {
          .slider {
            border: 2px solid #888ea8;

            &::before {
              border: 2px solid #888ea8;
              background-color: #888ea8;
              content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="%23e9ecef" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>') !important;
            }
          }
        }

        &.s-outline-primary {
          input:checked + .slider {
            border: 2px solid #009688;

            &::before {
              border: 2px solid #009688;
              background-color: #009688;
            }
          }
        }
      }

      &.billed-yearly-switch {
        span {
          &.txt-yearly {
            color: #009688;
          }
        }
      }
    }

    .pricing-plan {
      color: #3b3f5c;
      border: 1px solid rgba(25, 30, 58, 0.5411764705882353);
      background-color: #1b2e4b;

      .pricing-plan-label {
        strong {
          color: #bfc9d4;
        }
      }

      .pricing-plan-features {
        strong {
          color: #e0e6ed;
        }
      }

      &.recommended {
        box-shadow: -1px 0 10px 0 rgb(0 0 0 / 14%), -3px 0 18px 0 rgb(0 0 0 / 12%);

        .pricing-plan-label {
          color: #e3e4eb;

          strong {
            color: #009688;
          }
        }

        button {
          background-color: #191e3a !important;
        }
      }

      .recommended-badge {
        background-color: #191e3a;
      }
    }

    #pricingWrapper {
      .stacked {
        background: #1b2e4b;
        border: 1px solid #515365;

        .card-header {
          background: #1b2e4b;
          border-bottom: 1px solid #515365;
          color: #3b3f5c;

          .card-price {
            border: 1px solid #515365;
            background: #191e3a;
            color: #e0e6ed;
            box-shadow: none;
          }

          h3 {
            color: #e0e6ed;
          }

          p {
            color: #888ea8;
          }
        }

        .card-body {
          .list-group-minimal {
            .list-group-item {
              color: #bfc9d4;
            }
          }

          a {
            background-color: #191e3a !important;
            border-color: #191e3a;
          }
        }
      }
    }

    .pricing {
      .pricing__item {
        border: 1px solid #3b3f5c;
        background: #1b2e4b;
        color: #fff;

        &:hover {
          color: #009688;
          border: 1px solid #009688;

          .pricing__title {
            &::after {
              background: #009688;
            }
          }

          .pricing__action {
            color: #e0e6ed;
            background: #009688;
            border: solid 1px #009688;
          }
        }
      }

      .pricing__action {
        color: #888ea8;
        border: solid 1px #888ea8;
      }
    }
  }
}

/*elements*/
.purple {
  /*alerts*/
  .alert {
    &.alert-light-primary {
      color: #4361ee;
      background-color: rgba(67, 97, 238, 0.15);

      .close {
        color: #4361ee;
        text-shadow: none;
      }
    }

    &.alert-light-warning {
      color: #e2a03f;
      background-color: rgba(226, 160, 63, 0.15);

      .close {
        color: #e2a03f;
        text-shadow: none;
      }
    }

    &.alert-light-success {
      color: #1abc9c;
      background-color: rgba(26, 188, 156, 0.15);

      .close {
        color: #1abc9c;
        text-shadow: none;
      }
    }

    &.alert-light-info {
      color: #2196f3;
      background-color: rgba(33, 150, 243, 0.15);

      .close {
        color: #2196f3;
        text-shadow: none;
      }
    }

    &.alert-light-dark {
      color: #bfc9d4;
      background-color: rgba(59, 63, 92, 0.15);

      .close {
        color: #bfc9d4;
        text-shadow: none;
      }
    }

    &.alert-light-danger {
      color: #e7515a;
      background-color: rgba(231, 81, 90, 0.15);

      .close {
        color: #e7515a;
        text-shadow: none;
      }
    }

    &.alert-outline-primary,
    &.alert-outline-danger {
      .close {
        color: #fff;
        text-shadow: none;
      }
    }

    &.custom-alert-1 {
      color: #191e3a;
    }
  }

  /*avatar*/
  .avatar .avatar-title {
    background-color: #506690;
    color: #fff;
  }

  .avatar--group {
    .avatar {
      img,
      .avatar-title {
        border: 2px solid #888ea8;
      }
    }
  }

  /*badges*/
  .outline-badge-primary {
    &:hover,
    &:focus {
      background-color: #4361ee;
      color: #eaf1ff;
    }
  }

  .outline-badge-info {
    &:hover,
    &:focus {
      background-color: #2196f3;
      color: #eaf1ff;
    }
  }

  .outline-badge-success {
    &:hover,
    &:focus {
      background-color: #1abc9c;
      color: #eaf1ff;
    }
  }

  .outline-badge-secondary {
    &:hover,
    &:focus {
      background-color: #805dca;
      color: #eaf1ff;
    }
  }

  .outline-badge-warning {
    &:hover,
    &:focus {
      background-color: #e2a03f;
      color: #eaf1ff;
    }
  }

  .outline-badge-danger {
    &:hover,
    &:focus {
      background-color: #e7515a;
      color: #eaf1ff;
    }
  }

  .outline-badge-dark {
    &:hover,
    &:focus {
      background-color: #3b3f5c;
      color: #e3e4eb;
    }
  }

  /*breadcrumb*/
  .breadcrumb-one {
    .breadcrumb {
      background-color: transparent;
    }

    .breadcrumb-item {
      a {
        color: #888ea8;
        background-color: transparent;

        svg {
          fill: none;
        }
      }

      &.active {
        color: #d3d3d3;
      }
    }
  }

  .breadcrumb-two {
    .breadcrumb {
      li {
        a {
          background: #1b2e4b;
          color: #888ea8;

          &::before,
          &::after {
            border-left: 30px solid #1b2e4b;
          }
        }
      }
    }
  }

  .breadcrumb-five {
    .breadcrumb {
      li {
        a {
          color: #888ea8;
        }

        &.active {
          span {
            a {
              color: #009688 !important;
            }
          }
        }

        &::before {
          background: #009688 !important;
        }
      }
    }
  }

  .breadcrumb-four {
    .breadcrumb {
      li {
        background: #191e3a;
        border: 1px solid #191e3a;

        a {
          color: #888ea8;

          svg {
            color: #888ea8;
          }
        }

        &.active {
          a {
            color: #009688 !important;

            svg {
              color: #009688 !important;
            }
          }
        }

        &::before {
          background: #009688 !important;
        }
      }
    }
  }

  /*color library*/
  .seperator-header {
    h4 {
      background: rgba(0, 150, 136, 0.26);
      color: #009688;
    }
  }

  .color-box {
    border: 1px solid rgba(14, 23, 38, 0.59);
    background: rgba(14, 23, 38, 0.59);

    .cl-title {
      color: #888ea8;
    }

    .cl-info {
      span {
        color: #888ea8;
      }
    }
  }

  /*infobox*/
  .infobox-1,
  .infobox-2,
  .infobox-3 {
    border: 1px solid #191e3a;
    box-shadow: 0px 2px 11px 0px rgb(6 8 24 / 39%);
  }

  /*loader*/
  .loader {
    border: 7px solid #1b2e4b;
    border-top: 7px solid #4361ee;

    &.multi-loader {
      border-bottom: 7px solid #e2a03f;
      border-right: 7px solid #1abc9c;
      border-left: 7px solid #e7515a;
    }
  }

  /*pagination*/
  .pagination-default {
    li {
      border: 2px solid #191e3a;
      color: #888ea8;
    }

    .page-link {
      color: #888ea8;
    }

    .page-item {
      &.active {
        border: 2px solid #009688 !important;
        color: #009688;

        .page-link {
          color: #009688;
        }
      }

      &:hover {
        border: 2px solid #009688 !important;
        color: #009688;

        .page-link {
          color: #009688;
        }
      }
    }

    .page-item {
      &.disabled {
        background: #009688 !important;

        &:hover {
          border-color: #009688 !important;
        }

        .page-link {
          color: #fff;
        }
      }

      .page-link {
        color: #fff;
      }
    }
  }

  .pagination-solid {
    li {
      background-color: #191e3a;
      color: #888ea8;

      &.active {
        background-color: #009688 !important;
        color: #fff;

        &:hover {
          .page-link {
            color: #fff;
          }
        }
      }

      &:hover {
        .page-link {
          color: #009688;
        }
      }
    }

    .page-link {
      color: #888ea8;
    }

    .next,
    .prev,
    .first,
    .last {
      background-color: #191e3a;

      &:hover {
        background-color: #009688;

        .page-link {
          color: #fff;
        }

        a {
          color: #fff;
        }
      }
    }

    .page-item {
      &.disabled {
        background: #009688 !important;

        .page-link {
          color: #fff !important;

          svg {
            color: #fff !important;
          }
        }
      }

      .page-link {
        color: #fff;
      }
    }
  }

  .pagination-no_spacing {
    li {
      background-color: #191e3a;

      &.active {
        .page-link {
          background-color: #009688 !important;
          color: #fff;
        }
      }

      &.prev,
      &.next {
        background-color: #191e3a !important;
      }
    }

    .page-item {
      &.prev,
      &.next,
      &.first,
      &.last {
        .page-link {
          &:hover {
            background-color: #009688 !important;
            color: #fff !important;
          }
        }
      }
    }

    .page-item:not(.active) {
      .page-link {
        &:hover {
          color: #009688 !important;
        }
      }

      &.first,
      &.last {
        .page-link {
          &:hover {
            color: #fff !important;
          }
        }
      }
    }
  }

  .VueTables {
    .VueTables__wrapper {
      .VueTables__table {
        thead > th {
          border-color: #191e3a !important;
        }

        .VueTables__filters-row > th {
          background: #0e1726 !important;
          border-bottom: 1px solid #191e3a;

          .form-control {
            padding: 7px 16px;
            font-size: 13px;
          }
        }
      }
    }

    .table-hover:not(.table-dark) tbody tr:hover,
    .table-striped:not(.table-dark) tbody tr:nth-of-type(odd) {
      background-color: rgba(3, 3, 5, 0.122) !important;
    }

    .table-hover:not(.table-dark) tbody tr:hover td {
      --bs-table-accent-bg: rgba(3, 3, 5, 0.122);
    }

    .table-striped:not(.table-dark) tbody tr:nth-of-type(odd) td {
      --bs-table-accent-bg: rgba(3, 3, 5, 0.122);
    }

    .VueTables__limit {
      .VueTables__limit-field {
        select.form-control {
          background: #1b2e4b url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23009688' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px;
        }
      }
    }

    .VuePagination {
      nav {
        .VuePagination__pagination {
          li.page-item {
            &.disabled .page-link {
              background-color: #009688;
              border-color: #009688;
              color: #fff !important;
            }

            &:not(.disabled):hover .page-link,
            &:hover .page-link {
              color: #009688;
            }

            &:not(.disabled).VuePagination__pagination-item-prev-page,
            &:not(.disabled).VuePagination__pagination-item-next-page {
              &:hover .page-link {
                background-color: #009688;
                border-color: #009688;
              }
            }

            .page-link {
              background-color: #191e3a;
              border-color: #191e3a;
              color: #fff;

              &.active {
                background-color: #009688 !important;
                border-color: #009688;
              }
            }
          }
        }

        .VuePagination__count {
          color: #61b6cd;
          border: 1px solid #1b2e4b;
        }
      }
    }

    .VueTables__sortable {
      .sort-icon-none {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='%23bfc9d4' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
      }

      .sort-icon-asc {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='%23bfc9d4' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='%23bfc9d4' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
      }

      .sort-icon-desc {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='%23bfc9d4' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='%23bfc9d4' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
      }
    }
  }

  /*popovers*/
  .popover {
    border: 1px solid #060818 !important;
    background-color: #060818 !important;

    &.bs-popover-top .popover-arrow {
      &:after,
      &:before {
        border-top-color: #060818 !important;
      }
    }

    &.bs-popover-bottom .popover-arrow {
      &:after,
      &:before {
        border-bottom-color: #060818 !important;
      }
    }

    &.bs-popover-start .popover-arrow {
      &:after,
      &:before {
        border-left-color: #060818 !important;
      }
    }

    &.bs-popover-end .popover-arrow {
      &:after,
      &:before {
        border-right-color: #060818 !important;
      }
    }

    .popover-body {
      background-color: #060818;
      color: #bfc9d4;
    }

    .popover-header {
      background-color: #060818;
      color: #bfc9d4;
      border-bottom-color: #060818;
    }
  }

  .popover-primary {
    .popover-header {
      background-color: #060818;
      border: none;
      color: #4361ee;
    }

    .popover-body {
      background-color: #060818;
      color: #4361ee;
    }
  }

  .popover-success {
    .popover-header {
      background-color: #060818;
      border: none;
      color: #1abc9c;
    }

    .popover-body {
      background-color: #060818;
      color: #1abc9c;
    }
  }

  .b-popover-info {
    .popover-header {
      background-color: #060818;
      border: none;
      color: #2196f3;
    }

    .popover-body {
      background-color: #060818;
      color: #2196f3;
    }
  }

  .popover-danger {
    .popover-header {
      background-color: #060818;
      border: none;
      color: #e7515a;
    }

    .popover-body {
      background-color: #060818;
      color: #e7515a;
    }
  }

  .popover-warning {
    .popover-header {
      background-color: #060818;
      border: none;
      color: #e2a03f;
    }

    .popover-body {
      background-color: #060818;
      color: #e2a03f;
    }
  }

  .popover-secondary {
    .popover-header {
      background-color: #060818;
      border: none;
      color: #805dca;
    }

    .popover-body {
      background-color: #060818;
      color: #805dca;
    }
  }

  .popover-dark {
    .popover-header {
      background-color: #060818;
      border: none;
      color: #3b3f5c;
    }

    .popover-body {
      background-color: #060818;
      color: #3b3f5c;
    }
  }

  .bs-popover-left > {
    .arrow {
      &::after,
      &::before {
        border-left-color: #060818;
      }
    }
  }

  .bs-popover-right > {
    .arrow {
      &::after,
      &::before {
        border-right-color: #060818;
      }
    }
  }

  .bs-popover-top > {
    .arrow {
      &::after,
      &::before {
        border-top-color: #060818 !important;
      }
    }
  }

  .bs-popover-bottom > {
    .arrow {
      &::after,
      &::before {
        border-bottom-color: #060818;
      }
    }
  }

  /*progress bar*/
  .progress {
    box-shadow: none;
    background-color: #191e3a;
  }

  /*search*/
  #searchLive {
    .searchable-items {
      border: 1px solid #1b2e4b;
      padding: 13px;
      border-radius: 10px;

      .items {
        color: #0e1726;
        background: #1b2e4b;

        &.items-header-section {
          background: none;
        }

        &:hover {
          .user-name,
          .user-email,
          .action-btn {
            p {
              color: #607d8b;
            }
          }
        }

        .user-name,
        .user-email {
          p {
            color: #d3d3d3;
          }
        }
      }
    }
  }

  .full-search {
    &.search-form-overlay {
      border: 1px solid #1b2e4b;

      form {
        &.form-inline {
          input {
            &.search-form-control {
              color: #25d5e4;
            }
          }
        }
      }
    }
  }

  .search-form-overlay {
    &.input-focused {
      .form-inline {
        &.search {
          background: #1b2e4b;
        }
      }
    }
  }

  .demo-search-overlay.show {
    opacity: 0;
  }

  .search-input-group-style {
    &.input-group {
      box-shadow: none;

      .input-group-text {
        border: 1px solid #1b2e4b;
        background-color: #191e3a;
      }
    }
  }

  /*tooltips*/
  .tooltip-primary,
  .tooltip-success,
  .tooltip-info,
  .tooltip-danger,
  .tooltip-warning,
  .tooltip-secondary,
  .tooltip-dark {
    .tooltip-arrow {
      &::before {
        border-top-color: #060818;
      }
    }

    .tooltip-inner {
      background-color: #060818;
    }
  }

  .tooltip-dark {
    .tooltip-inner {
      color: #bfc9d4;
    }
  }

  /*treeview*/
  .file-tree {
    .file-tree-folder {
      &::after {
        content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="%23009688" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>');
      }
    }

    li {
      &::before {
        content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="%23009688" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>');
      }

      color: #bfc9d4;
      font-weight: 500;

      &.file-tree-folder {
        &::before {
          content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="%23009688" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-folder"><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>');
        }
      }
    }
  }

  .caret {
    color: #bfc9d4;

    &::before {
      content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="none" stroke="%23009688" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-folder-minus"><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path><line x1="9" y1="14" x2="15" y2="14"></line></svg>');
    }

    &.caret-down {
      /*  content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="none" stroke="%23009688" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-folder-plus"><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path><line x1="12" y1="11" x2="12" y2="17"></line><line x1="9" y1="14" x2="15" y2="14"></line></svg>');*/
      &::before {
        content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="none" stroke="%23009688" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-folder-plus"><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path><line x1="12" y1="11" x2="12" y2="17"></line><line x1="9" y1="14" x2="15" y2="14"></line></svg>');
      }
    }
  }

  .nested {
    li {
      color: #bfc9d4;
    }
  }

  /*typography*/
  .font-family-showcase {
    background: #191e3a;
    border: 1px solid #191e3a;

    .font-family-text {
      border-bottom: 1px solid #0e1726;

      p {
        color: #fff !important;
      }
    }

    .font-family-info {
      background-color: #191e3a;

      a {
        color: #bfc9d4;
      }
    }
  }

  .element-background {
    border: 1px solid #191e3a;
  }
}

/*font-icon*/
.purple {
  .feather-icon,
  .fontawesome {
    .icon-section {
      h4 {
        color: #bfc9d4 !important;
      }
    }

    .icon-link {
      color: #888ea8 !important;
    }

    .icon-content-container {
      border: 1px solid #191e3a !important;
    }

    .icon-container {
      svg {
        color: #bfc9d4 !important;
      }

      i {
        color: #bfc9d4 !important;
      }

      &:hover {
        i {
          color: #888ea8 !important;
        }
      }
    }
  }
}

/*Tables*/
.purple {
  .table-hover > tbody > tr:hover > * {
    --bs-table-accent-bg: #13192f;
  }

  .table {
    td,
    th,
    thead th {
      border-color: #191e3a !important;
    }

    background-color: #0e1726;

    thead {
      tr {
        th {
          color: #d3d3d3;
          background: transparent;
        }
      }
    }

    tbody {
      tr {
        td {
          color: #bfc9d4;
        }
      }
    }

    &.table-hover {
      tbody {
        tr {
          background-color: transparent;

          &:hover {
            background-color: rgba(25, 30, 58, 0.6313725490196078) !important;
          }
        }
      }
    }
  }

  .table-striped {
    tbody {
      tr {
        &:nth-of-type(odd) {
          background-color: rgba(25, 30, 58, 0.6313725490196078) !important;
        }
      }
    }
  }

  .table-light {
    .table {
      background-color: #3b3f5c !important;

      thead {
        tr {
          th {
            color: #d3d3d3;
            border-bottom: 1px solid rgba(136, 142, 168, 0.3137254901960784) !important;
          }
        }
      }

      &.table-hover {
        tbody {
          tr {
            background-color: #3b3f5c;

            td {
              color: #fff;
              border-bottom: 1px solid rgba(136, 142, 168, 0.3137254901960784) !important;
            }
          }
        }
      }
    }
  }

  .table-default {
    background-color: rgba(136, 142, 168, 0.1) !important;
    color: #bfc9d4 !important;

    td,
    th {
      background-color: rgba(136, 142, 168, 0.1) !important;
      color: #bfc9d4 !important;
    }
  }

  .table-primary {
    background-color: rgba(27, 85, 226, 0.18) !important;
    color: #bfc9d4 !important;

    td,
    th {
      background-color: rgba(27, 85, 226, 0.18) !important;
      color: #bfc9d4 !important;
    }
  }

  .table-secondary {
    background-color: rgba(92, 26, 195, 0.11) !important;
    color: #bfc9d4 !important;

    td,
    th {
      background-color: rgba(92, 26, 195, 0.11) !important;
      color: #bfc9d4 !important;
    }
  }

  .table-success {
    background-color: rgba(141, 191, 66, 0.11) !important;
    color: #bfc9d4 !important;

    td,
    th {
      background-color: rgba(141, 191, 66, 0.11) !important;
      color: #bfc9d4 !important;
    }
  }

  .table-dark {
    background-color: rgba(27, 46, 75, 0.11) !important;
    color: #bfc9d4 !important;

    td,
    th {
      background-color: rgba(27, 46, 75, 0.11) !important;
      color: #bfc9d4 !important;
    }
  }

  .table-danger {
    background-color: rgba(231, 81, 90, 0.11) !important;
    color: #bfc9d4 !important;

    td,
    th {
      background-color: rgba(231, 81, 90, 0.11) !important;
      color: #bfc9d4 !important;
    }
  }

  .table-info {
    background-color: rgba(33, 150, 243, 0.11) !important;
    color: #bfc9d4 !important;

    td,
    th {
      background-color: rgba(33, 150, 243, 0.11) !important;
      color: #bfc9d4 !important;
    }
  }

  .table-warning {
    background-color: rgba(226, 160, 63, 0.11) !important;
    color: #bfc9d4 !important;

    td,
    th {
      background-color: rgba(226, 160, 63, 0.11) !important;
      color: #bfc9d4 !important;
    }
  }

  .custom-dropdown {
    .dropdown-item {
      &:hover {
        color: #888ea8;
        background-color: transparent;
      }
    }
  }
}

/*data Tables*/
.purple {
  .custom-table {
    .table {
      th {
        background: #1b2e4b !important;
      }
    }

    .table-footer {
      .dataTables_info {
        color: #61b6cd;
        border: 1px solid #1b2e4b;
      }
    }

    .table-hover {
      &:not(.table-dark) {
        tbody {
          tr {
            &:hover td {
              background: rgba(3, 3, 5, 0.122) !important;
            }
          }
        }
      }
    }

    .table-striped {
      &:not(.table-dark) {
        tbody {
          tr {
            &:nth-of-type(odd) td {
              background-color: rgba(3, 3, 5, 0.122) !important;
            }
          }
        }
      }
    }

    label {
      color: #bfc9d4;
    }
  }

  .dropdown-menu {
    .custom-checkbox {
      .custom-control-label {
        &::before {
          background-color: #30486c;
          border-color: #30486c;
        }
      }
    }
  }
}

/*Forms*/
.purple {
  /*select2*/
  .multiselect__tags {
    background: #1b2e4b;
    border-color: #3b3f5c;

    .multiselect__tags-wrap {
      .multiselect__tag {
        background-color: #009688;
        border: 1px solid #009688;
        color: #1b2e4b;
        font-weight: bold;
      }
    }
  }

  .multiselect__input,
  .multiselect__single {
    background: #1b2e4b;
    color: #009688;
    font-weight: 400 !important;
    font-size: 15px !important;
  }

  .multiselect__content-wrapper {
    background: #1b2e4b;
    border-color: #3b3f5c;
    color: #888ea8;
  }

  .select2 .multiselect__option--selected {
    background-color: rgba(0, 150, 136, 0.10980392156862745);
    color: #009688;
  }

  .select2 .multiselect__option {
    font-weight: 400 !important;
    font-size: 15px !important;
  }

  .select2 .multiselect__option--highlight {
    color: #009688;
    background: none;
  }

  /*touchspin*/
  .touchspin .form-control.focus {
    box-shadow: none;
    background-color: #1b2e4b;
    border-color: #3b3f5c;
    color: #25d5e4;
  }

  /*Switches*/
  .switch.s-outline .slider {
    border: 2px solid #888ea8;
  }

  .switch.s-outline-default input:checked + .slider {
    border: 2px solid #888ea8;
  }

  .switch.s-outline-default input:checked + .slider:before {
    border: 2px solid #888ea8;
    background-color: #888ea8;
  }

  .switch .slider {
    background-color: #1b2e4b;
  }

  /**Wizards**/
  .vue-form-wizard {
    .wizard-icon-circle {
      &.tab_shape {
        background-color: #191e3a;
        color: #009688 !important;
      }
    }

    &.pills {
      &.text-only {
        .wizard-nav {
          li {
            .stepTitle {
              color: #009688 !important;

              &.active {
                color: #009688 !important;
              }
            }

            &.active {
              .wizard-icon-circle > {
                div {
                  background-color: #060818 !important;
                }
              }
            }
          }
        }
      }

      .wizard-nav {
        li {
          .stepTitle {
            color: #009688 !important;

            &.active {
              color: #009688 !important;
            }
          }

          &.active {
            .wizard-icon-circle > {
              div {
                background-color: #060818 !important;
                color: #009688 !important;
              }
            }

            & > a {
              .wizard-icon {
                color: #009688 !important;
              }
            }
          }
        }
      }
    }

    .wizard-btn {
      background-color: #515365 !important;
      border-color: #515365 !important;
    }

    &.md {
      .wizard-icon-circle {
        background-color: #191e3a;
        border-color: #191e3a !important;

        .wizard-icon-container {
          border-radius: 100%;
          border-color: #009688 !important;
          background: #060818 !important;
        }
      }

      .square_shape {
        .wizard-icon-container {
          border-radius: 0%;
          border-color: #009688 !important;
          background: #060818 !important;
        }
      }

      .wizard-nav-pills > li.active > a {
        .wizard-icon {
          color: #009688 !important;
        }

        span {
          color: #009688 !important;
        }
      }
    }

    .wizard-nav-pills > li > a {
      color: #009688;

      &:hover {
        color: #009688;
      }
    }

    .wizard-navigation {
      .wizard-progress-with-circle {
        .wizard-progress-bar {
          background-color: #060818 !important;
        }
      }
    }
  }

  /*file-upload*/
  .custom-file-container {
    label {
      color: #009688;

      .custom-file-container__image-clear {
        color: #e0e6ed;
      }
    }
  }

  .custom-file-container__custom-file__custom-file-control {
    border: 1px solid #1b2e4b;
    color: #009688;
    background-color: #1b2e4b;
  }

  .custom-file-container__custom-file__custom-file-control__button {
    background-color: rgba(0, 150, 136, 0.1);
    color: #009688;
    border-left: 0px;
  }

  .custom-file-container__image-preview {
    background-color: transparent;
  }

  /***quill-editor***/
  .ql-toolbar {
    &.ql-snow {
      border: 1px solid #1b2e4b;

      .ql-picker-label {
        border: 1px solid transparent;
        color: #009688;

        &:hover {
          color: #bfc9d4;

          .ql-stroke {
            stroke: #bfc9d4;
          }
        }
      }

      .ql-stroke {
        stroke: #009688;
      }

      .ql-fill {
        fill: #009688;
      }

      button {
        &:hover {
          .ql-stroke {
            stroke: #bfc9d4;
          }

          .ql-fill {
            fill: #bfc9d4;
          }
        }
      }
    }
  }

  .ql-snow {
    &.ql-toolbar {
      button {
        &.ql-active {
          .ql-stroke {
            stroke: #bfc9d4;
          }

          .ql-fill {
            fill: #bfc9d4;
          }
        }
      }

      .ql-picker-label {
        &.ql-active {
          color: #bfc9d4;

          .ql-stroke {
            stroke: #bfc9d4;
          }
        }
      }
    }
  }

  .ql-container {
    &.ql-snow {
      border: 1px solid #1b2e4b !important;
    }
  }

  /**markdown-editor**/
  .markdown {
    .editor-toolbar {
      border: 1px solid #1b2e4b;

      a {
        color: #009688 !important;

        &:hover {
          background: #191e3a;
          border-color: #191e3a !important;
          opacity: 1;
        }

        &.active {
          background: #191e3a;
          border-color: #191e3a !important;
          opacity: 1;
        }
      }

      i {
        &.separator {
          border-left: 1px solid #1b2e4b;
          border-right: 1px solid #1b2e4b;
        }
      }

      &.disabled-for-preview {
        a {
          &:not(.no-disable) {
            pointer-events: none;
            background: #060818;
            border-color: #060818;
            text-shadow: inherit;
          }
        }
      }
    }
  }

  .editor-preview,
  .editor-preview-side {
    background: #0e1726;
  }

  .EasyMDEContainer .CodeMirror {
    border: 1px solid #1b2e4b;
    background: transparent;
    color: #bfc9d4;
  }

  .editor-statusbar {
    color: #888ea8;
  }

  /**date-picker**/
  .range-count {
    .range-count-number {
      background: #1b2e4b;
      color: #009688;
      border: 1px solid #1b2e4b;
    }

    .range-count-unit {
      color: #009688;
    }
  }

  .flatpickr-time {
    input {
      &:focus {
        background: #1b2e4b;
      }
    }
  }

  .noUi-connect {
    background: #009688;
  }

  .noUi-tooltip {
    background: #191e3a;
    color: #009688;
    border: 1px solid #191e3a;
  }

  .noUi-target {
    background: #191e3a;
    border: 1px solid #191e3a;
    box-shadow: none;
  }

  .noUi-horizontal {
    .noUi-handle {
      border: 1px solid #506690;
      background: #506690;
      box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%);
    }
  }

  .example-val {
    color: #009688;

    span {
      &.precentage-val {
        background: #191e3a;
        color: #009688;
        border: 1px solid #191e3a;
      }
    }
  }

  .progress-range-counter::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: #009688;
    cursor: pointer;
    height: 16px;
    width: 16px;
    margin-top: -4px;
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
  }

  .progress-range-counter:active::-webkit-slider-thumb {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  .progress-range-counter:focus::-webkit-slider-thumb {
    background: #009688;
    cursor: pointer;
    height: 16px;
    width: 16px;
    margin-top: -4px;
    box-shadow: none;
  }

  .progress-range-counter::-moz-range-thumb {
    background: #009688;
    cursor: pointer;
    height: 16px;
    width: 16px;
    margin-top: -4px;
  }

  input[type='range']::-webkit-slider-runnable-track {
    background: #191e3a;
  }

  input[type='range']::-moz-range-track {
    background: #191e3a;
  }

  input[type='range']::-ms-track {
    background: #191e3a;
  }

  /**clipboard**/
  .clipboard {
    background-color: #060818;

    &.copy-txt {
      background-color: #060818;

      .otp-pass {
        span {
          color: #009688;
        }
      }
    }
  }
}

/*user*/
.purple {
  /**profile**/
  .user-profile {
    h3 {
      color: #bfc9d4;

      &::after {
        background: #009688;
      }
    }

    .edit-profile {
      background-color: #9acccb;

      svg {
        color: #060818;
      }
    }

    .edit-profile-done {
      background-color: #fdb82a;

      svg {
        color: #060818;
      }
    }

    .edit-profile-error {
      background-color: #ce6f74;

      svg {
        color: #060818;
      }
    }

    .user-info {
      p {
        color: #009688;
      }
    }

    .user-info-list {
      ul {
        &.contacts-block {
          li {
            a {
              color: #009688;
            }

            &:hover {
              svg {
                color: #009688;
              }
            }
          }

          ul {
            &.list-inline {
              div {
                &.social-icon {
                  border: 2px solid #3b3f5c;
                }
              }

              svg {
                color: #bfc9d4;
              }
            }
          }
        }

        &.matching-block {
          li {
            a {
              color: #009688;
            }

            &:hover {

            }
          }

          ul {
            &.list-inline {
              div {
                &.social-icon {
                  border: 2px solid #3b3f5c;
                }
              }

            }
          }
        }
      }
    }
  }

  .education {
    h3 {
      color: #bfc9d4;

      &::after {
        background: #009688;
      }
    }

    .timeline-alter {
      .item-timeline {
        .t-meta-date {
          p {
            color: #888ea8;
          }
        }

        .t-text {
          p {
            color: #bfc9d4;
          }
        }

        .t-dot {
          border-color: #009688;
        }

        &:not(:last-child) {
          .t-dot {
            &::after {
              border-color: #1b2e4b;
            }
          }
        }
      }
    }
  }

  .jobineo-questions {
    h3 {
      color: #bfc9d4;

      &::after {
        background: #009688;
      }
    }


    .b-questions {
      box-shadow: 2px 5px 17px 0 rgb(31 45 61 / 10%);
      background: #009688;
      border: 1px solid #009688;

      p {
        color: #bfc9d4;
      }

      svg {
        color: #bfc9d4;
      }

      h5 {
        color: #bfc9d4;
      }
    }


    .timeline-alter {
      .item-timeline {
        .t-meta-date {
          p {
            color: #888ea8;
          }
        }

        .t-text {
          p {
            color: #bfc9d4;
          }
        }

        .t-dot {
          border-color: #009688;
        }

        &:not(:last-child) {
          .t-dot {
            &::after {
              border-color: #1b2e4b;
            }
          }
        }
      }
    }
  }

  .my-search {
    h3 {
      color: #bfc9d4;

      &::after {
        background: #009688;
      }
    }

    .timeline-alter {
      .item-timeline {
        .t-meta-date {
          p {
            color: #888ea8;
          }
        }

        .t-text {
          p {
            color: #bfc9d4;
          }
        }

        .t-dot {
          border-color: #009688;
        }

        &:not(:last-child) {
          .t-dot {
            &::after {
              border-color: #1b2e4b;
            }
          }
        }
      }
    }
  }

  .cv {
    h3 {
      color: #bfc9d4;

      &::after {
        background: #009688;
      }
    }

    .timeline-alter {
      .item-timeline {
        .t-meta-date {
          p {
            color: #888ea8;
          }
        }

        .t-text {
          p {
            color: #bfc9d4;
          }
        }

        .t-dot {
          border-color: #009688;
        }

        &:not(:last-child) {
          .t-dot {
            &::after {
              border-color: #1b2e4b;
            }
          }
        }
      }
    }
  }

  .work-experience {
    h3 {
      color: #bfc9d4;

      &::after {
        background: #009688;
      }
    }

    .timeline-alter {
      .item-timeline {
        .t-meta-date {
          p {
            color: #888ea8;
          }
        }

        .t-text {
          p {
            color: #bfc9d4;
          }
        }

        .t-dot {
          border-color: #009688;
        }

        &:not(:last-child) {
          .t-dot {
            &::after {
              border-color: #1b2e4b;
            }
          }
        }
      }
    }
  }

  .knowledge {
    h3 {
      color: #bfc9d4;

      &::after {
        background: #009688;
      }
    }

    .timeline-alter {
      .item-timeline {
        .t-meta-date {
          p {
            color: #888ea8;
          }
        }

        .t-text {
          p {
            color: #bfc9d4;
          }
        }

        .t-dot {
          border-color: #009688;
        }

        &:not(:last-child) {
          .t-dot {
            &::after {
              border-color: #1b2e4b;
            }
          }
        }
      }
    }
  }

  .attachments {
    h3 {
      color: #bfc9d4;

      &::after {
        background: #009688;
      }
    }

    .timeline-alter {
      .item-timeline {
        .t-meta-date {
          p {
            color: #888ea8;
          }
        }

        .t-text {
          p {
            color: #bfc9d4;
          }
        }

        .t-dot {
          border-color: #009688;
        }

        &:not(:last-child) {
          .t-dot {
            &::after {
              border-color: #1b2e4b;
            }
          }
        }
      }
    }
  }


  .skills {
    h3 {
      color: #bfc9d4;

      &::after {
        background: #009688;
      }
    }

    .progress {
      background-color: #191e3a;
      box-shadow: none;

      .progress-bar {
        background: linear-gradient(to right, #2b5876 0%, #4e4376 100%);
      }
    }
  }

  .bio {
    h3 {
      color: #bfc9d4;

      &::after {
        background: #009688;
      }
    }

    p {
      color: #bfc9d4;
    }

    .b-skills {
      box-shadow: 2px 5px 17px 0 rgb(31 45 61 / 10%);
      background: #191e3a;

      h5 {
        color: #888ea8;
      }
    }
  }

  /**account-setting**/
  .general-info {
    background-color: #0e1726;
    border: none;

    .info {
      h6 {
        color: #bfc9d4;
      }

      .upload {
        border-right: 1px solid #191e3a;

        p {
          color: #009688;
        }
      }
    }
  }

  .about {
    background-color: #0e1726;
    border: none;

    .info {
      h5 {
        color: #bfc9d4;
      }
    }
  }

  .work-platforms {
    background-color: #0e1726;
    border: none;

    .info {
      h5 {
        color: #bfc9d4;
      }
    }
  }

  .contact {
    background-color: #0e1726;
    border: none;

    .info {
      h5 {
        color: #bfc9d4;
      }
    }
  }

  .social {
    background-color: #0e1726;
    border: none;

    .info {
      h5 {
        color: #bfc9d4;
      }
    }
  }

  .input-group .input-group-text svg {
    color: #009688;
  }

  .skill {
    background-color: #0e1726;
    border: none;

    .info {
      h5 {
        color: #bfc9d4;
      }
    }

    .range-count {
      .range-count-number {
        background: #1b2e4b;
        color: #009688;
        border: 1px solid #1b2e4b;
      }
    }
  }

  .edu-experience {
    background-color: #0e1726;
    border: none;

    .info {
      h5 {
        color: #bfc9d4;
      }
    }
  }

  .work-experience {
    background-color: #0e1726;
    border: none;

    .info {
      h5 {
        color: #bfc9d4;
      }
    }
  }

  .account-settings-footer {
    background: #191e3a;
    border-top: 1px solid #191e3a;
    box-shadow: 0 -6px 10px 0 rgb(0 0 0 / 14%), 0 -1px 18px 0 rgb(0 0 0 / 12%), 0 -3px 5px -1px rgb(0 0 0 / 20%);
  }
}

/*pages*/
.purple {
  /**helpdesk**/
  .helpdesk {
    &::before {
      background-image: none;
      background-color: #060818;
    }

    .hd-header-wrapper {
      h4 {
        color: #bfc9d4;
      }

      p {
        color: #bfc9d4;
      }

      .form-control {
        border: 1px solid #1b2e4b;
        color: #009688;

        &:focus {
          box-shadow: none;
          border-color: #1b2e4b;
          color: #888ea8;
          background: #1b2e4b;
        }
      }

      .input-group {
        &:hover {
          span {
            &.input-group-text {
              svg {
                color: #009688;
              }
            }
          }
        }
      }
    }

    .input-group {
      .input-group-text {
        border: 1px solid #1b2e4b;
        background-color: #191e3a;
        color: #888ea8;

        svg {
          color: #888ea8;
        }
      }
    }

    .hd-tab-section {
      .accordion {
        .card {
          &:hover {
            .card-header {
              div {
                > div {
                  svg {
                    color: #009688;
                  }
                }
              }
            }
          }

          .card-header {
            div {
              > div.not-collapsed {
                svg {
                  color: #009688;
                }
              }

              > div[aria-expanded='true'] {
                svg {
                  color: #29afa2;
                }
              }
            }
          }

          .card-header {
            background-color: #0e1726;
            color: #888ea8;
            border-color: transparent;

            div {
              > div {
                background: #0e1726;
                color: #888ea8;

                svg {
                  color: #888ea8;
                }
              }
            }
          }

          .card-body {
            p {
              color: #888ea8;
            }
          }
        }
      }
    }

    .hd-contact-section {
      .hd-slide-header {
        color: #bfc9d4;
      }

      .community-help,
      .news-updates {
        .media {
          border: 1px solid #0e1726;
          background: #0e1726;

          svg {
            color: #bfc9d4;
          }

          h5 {
            color: #bfc9d4;
          }

          p {
            color: #888ea8;
          }
        }
      }
    }

    .carousel-control-next,
    .carousel-control-prev {
      background: #060818;
    }

    .carousel-control-prev-icon {
      svg {
        color: #fff;
      }
    }

    .carousel-control-next-icon {
      svg {
        color: #fff;
      }
    }

    .carousel-indicators {
      li {
        background-color: #506690 !important;

        &.active {
          background-color: #888ea8 !important;
        }
      }
    }
  }

  /**contact-us**/
  .cu-contact-section {
    .contact-form {
      form {
        h4 {
          color: #fff;
        }

        p {
          color: #fff;
        }

        .input-fields {
          svg {
            color: #009688;
          }
        }

        input,
        textarea {
          color: #009688;

          &:focus {
            background: transparent;
          }
        }

        button {
          background-color: #009688 !important;
        }

        .radio-primary .custom-control-input:checked ~ .custom-control-label::before {
          background-color: #009688 !important;
        }
      }
    }
  }

  /**faq**/
  .fq-header-wrapper {
    &::before {
      background-image: none;
      background-color: #060818;

      h1 {
        color: #bfc9d4;
      }

      p {
        color: #bfc9d4;
      }
    }

    button {
      color: #fff !important;
      background-color: #3b3f5c;
      border-color: #3b3f5c;
      box-shadow: 0 10px 20px -10px rgb(59 63 92 / 59%);
      text-shadow: none;
      font-size: 14px;
      letter-spacing: 0px;
    }
  }

  .faq {
    .faq-layouting {
      .fq-comman-question-wrapper {
        background: #0e1726;

        h3 {
          color: #bfc9d4;
        }

        ul {
          li {
            color: #888ea8;
          }
        }
      }

      .fq-tab-section {
        background: none;
        border: none;

        h2 {
          color: #bfc9d4;

          span {
            color: #009688;
          }
        }

        .accordion {
          .card {
            .card-header {
              div[aria-expanded='true'] {
                svg {
                  &.feather-code {
                    color: #009688;
                  }

                  &.feather-thumbs-up {
                    color: #009688;
                  }
                }

                span {
                  &.faq-like-count {
                    color: #009688;
                  }
                }
              }
            }

            &:hover {
              .card-header {
                div {
                  svg {
                    &.feather-code {
                      color: #009688;
                    }

                    &.feather-thumbs-up {
                      color: #009688;
                    }
                  }

                  span {
                    &.faq-like-count {
                      color: #009688;
                    }
                  }
                }
              }
            }

            .card-header {
              > div {
                color: #009688;

                .faq-q-title {
                  color: #888ea8;
                }
              }
            }

            .card-body {
              p {
                color: #bfc9d4;
              }
            }
          }
        }
      }

      .fq-article-section {
        h2 {
          color: #bfc9d4;
        }

        .card {
          .card-body {
            h5 {
              &.card-title {
                color: #e0e6ed;
              }
            }

            p {
              &.meta-text {
                color: #009688;
              }
            }
          }
        }
      }

      .fq-tab-section {
        .accordion {
          .card {
            border: 2px solid #1b2e4b;
            border-radius: 6px;
            margin-bottom: 4px;
            background: #1b2e4b;

            .card-header {
              > div[aria-expanded='true'] {
                border-bottom: 0px;

                .faq-q-title {
                  color: #009688;
                }

                .icons {
                  svg {
                    color: #009688;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /**privacy-policy**/
  #headerWrapper {
    &::before {
      background-image: none;
      background-color: #060818;
    }

    .main-heading {
      color: #bfc9d4;
    }
  }

  #privacyWrapper {
    .privacyContent {
      background: #0e1726;

      p {
        color: #888ea8;
      }

      .privacy-head {
        border-bottom: 1px solid #1b2e4b;
      }
    }

    h5 {
      &.policy-info-ques {
        color: #bfc9d4;
      }
    }
  }

  /**coming-soon**/
  .coming-soon-cont {
    .coming-soon-content {
      > h4 {
        color: #bfc9d4;
      }
    }

    #timer {
      .days,
      .hours,
      .min,
      .sec {
        background: #009688;
        color: #fff;
      }
    }

    .coming-soon-wrap {
      form {
        .field-wrapper {
          svg {
            color: #009688;
            fill: none;
          }

          button {
            &.btn {
              background-color: #009688 !important;
              border-color: #009688;
              box-shadow: none;
            }
          }

          input {
            &:focus {
              border-bottom: 1px solid #3b3f5c;
            }
          }
        }
      }
    }

    .social {
      background-color: #060818;

      svg {
        color: #009688;
      }

      li {
        &:not(:last-child) {
          border-right: 2px solid #3b3f5c;
        }
      }
    }

    .terms-conditions {
      color: #bfc9d4;

      a {
        color: #009688;
      }
    }
  }

  /**error page**/
  .error404,
  .error500,
  .error503,
  .maintanence {
    background-image: none;
    background-color: #060818;

    .error-number {
      color: #e0e6ed;
    }

    .mini-text {
      color: #bfc9d4;
    }

    .error-text {
      color: #e0e6ed;
    }

    .text {
      color: #e0e6ed;
    }

    a {
      &.btn {
        background-image: linear-gradient(229deg, #517281 0%, #3b5d70 27%, #4d5c82 72%, #5d647f 100%);
        border: none;
      }
    }
  }
}

/*Authentication*/
.purple {
  /**login-boxed**/
  .form-container {
    &.outer {
      background-color: #0e1726;

      .form-form {
        .form-form-wrap {
          form {
            .social {
              background-color: transparent;

              a {
                border: 1px solid #1b2e4b;
                color: #888ea8;

                &.social-fb {
                  svg,
                  .brand-name {
                    color: #2196f3;
                  }
                }
              }
            }

            .field-wrapper {
              label {
                color: #bfc9d4;
              }
            }
          }

          p {
            &.signup-link {
              color: #bfc9d4;

              a {
                color: #009688;
              }

              &.recovery {
                color: #bfc9d4;
              }
            }
          }

          .user-meta {
            p {
              color: #bfc9d4;
            }
          }
        }

        .form-container {
          .form-content {
            background: #060818;
            border: none;
            box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%);

            h1 {
              color: #bfc9d4;
            }

            > p {
              color: #888ea8;
            }
          }
        }
      }
    }
  }

  .full-form {
    .form-container {
      .form-form {
        margin: 0;

        .form-form-wrap {
          form {
            .social {
              background-color: transparent;

              a {
                border: 1px solid #1b2e4b;
                color: #888ea8;

                &.social-fb {
                  svg,
                  .brand-name {
                    color: #2196f3;
                  }
                }
              }
            }

            .field-wrapper {
              label {
                color: #bfc9d4;
              }

              input {
                background-color: transparent;
                border-bottom: 1px solid #191e3a;
                color: #009688;
              }

              svg {
                color: #009688;
              }

              &.toggle-pass {
                p {
                  color: #bfc9d4;
                }
              }

              a {
                &.forgot-pass-link {
                  color: #009688;
                }
              }
            }
          }

          p {
            &.signup-link {
              color: #bfc9d4;

              a {
                color: #009688;
              }

              &.recovery {
                color: #bfc9d4;
              }
            }
          }

          .user-meta {
            p {
              color: #bfc9d4;
            }
          }
        }

        .form-container {
          .form-content {
            background: #060818;
            border: none;
            box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%);

            h1 {
              color: #bfc9d4;

              .brand-name {
                color: #009688;
              }
            }

            > p {
              color: #888ea8;
            }
          }
        }

        .terms-conditions {
          a {
            color: #009688;
          }
        }
      }
    }
  }

  .auth-boxed {
    .form-form {
      .form-form-wrap {
        form {
          .field-wrapper {
            input {
              border: 1px solid #1b2e4b;
              color: #009688;
              background: #1b2e4b;

              &::placeholder {
                color: #d3d3d3;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

/*dragndrop*/
.purple {
  .parent {
    &.ex-1,
    &.ex-2,
    &.ex-3 {
      .drag-drop {
        .media {
          background-color: #1b2e4b !important;
          border: 1px solid #3b3f5c;

          .media-body {
            h6 {
              color: #bfc9d4;
            }

            h5 {
              color: #bfc9d4;

              span {
                color: #bfc9d4 !important;

                &.comment-topic {
                  color: #2196f3 !important;
                }
              }
            }
          }
        }
      }
    }

    &.ex-4 {
      .drag-drop {
        background-color: #0e1726;

        .card {
          &.post {
            &.text-post {
              background-color: #1b2e4b;
              border: 1px solid #3b3f5c;

              .card-body {
                .post-content {
                  border-bottom: 1px solid #3b3f5c;

                  p {
                    color: #888ea8;
                  }
                }
              }
            }

            .media {
              &.user-meta {
                .media-body {
                  h5 {
                    color: #bfc9d4;
                  }
                }
              }
            }

            div {
              &.people-liked-post {
                ul {
                  li {
                    img {
                      border: 2px solid rgba(59, 63, 92, 0.25);
                      box-shadow: 0px 0px 15px 1px rgb(113 106 202 / 30%);
                    }
                  }
                }

                .people-liked-post-name {
                  span {
                    a {
                      color: #2196f3;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.ex-5 {
      .drag-drop {
        background-color: #0e1726;

        > div {
          background-color: #1b2e4b !important;
          border: 1px solid #3b3f5c;
        }

        .media {
          .media-body {
            h5 {
              color: #bfc9d4;
            }
          }
        }
      }

      .media {
        ul {
          li {
            img {
              border: 2px solid rgba(59, 63, 92, 0.25);
              box-shadow: 0px 0px 15px 1px rgb(113 106 202 / 20%);
            }
          }
        }
      }

      .handle {
        background-color: #0e1726;
        color: #fff;
      }
    }
  }
}

/*scrollspy*/
.purple {
  .sidenav {
    height: auto;
    border-left: 1px solid #191e3a;

    .sidenav-header {
      p {
        background: #4361ee;
        background-image: linear-gradient(to right, #4361ee 0%, #805dca 100%);
      }
    }

    .sidenav-content {
      a {
        color: #bfc9d4;

        &.active,
        &:hover {
          color: #25d5e4;
          border-left: 1px solid #25d5e4;
        }
      }
    }
  }
}

/*flatpickr*/
.purple {
  .flatpickr-calendar {
    border: 1px solid #1b2e4b;
    background: #1b2e4b;

    .flatpickr-months {
      .flatpickr-prev-month,
      .flatpickr-next-month {
        background: #1b2e4b;

        svg {
          fill: #bfc9d4;
        }
      }

      .flatpickr-monthDropdown-months {
        border: 1px solid #3b3f5c;
        color: #bfc9d4;

        .flatpickr-monthDropdown-month {
          background-color: #1b2e4b;
        }
      }

      input.cur-year {
        border: 1px solid #3b3f5c;
        color: #bfc9d4;
      }

      .numInputWrapper {
        span {
          height: 26%;

          &.arrowUp {
            top: 10px;

            &:after {
              border-bottom-color: #bfc9d4;
            }
          }

          .arrowDown {
            top: 34%;

            &:after {
              border-top-color: #bfc9d4;
            }
          }
        }
      }
    }

    .flatpickr-day {
      color: #888ea8;
      font-weight: 500;

      &:hover {
        background: #191e3a;
        border-color: #191e3a;
      }

      &.flatpickr-disabled {
        color: rgba(136, 142, 168, 0.22);
      }
    }

    .flatpickr-day.prevMonthDay,
    .flatpickr-day.nextMonthDay {
      color: rgba(136, 142, 168, 0.22) !important;
    }

    .flatpickr-day.selected {
      background: #009688;
      color: #0e1726;
      border-color: #009688;
      font-weight: 700;
    }

    .flatpickr-day.today {
      border-color: #009688;

      &:hover {
        border-color: #0e1726;
        background: #0e1726;
        color: #fff;
      }
    }

    .flatpickr-time {
      border-top: 1px solid #243146;

      input,
      .flatpickr-time-separator,
      .flatpickr-am-pm {
        color: #bfc9d4;
      }

      input,
      .flatpickr-am-pm {
        &:hover {
          background-color: #0e1726;
        }
      }

      .numInputWrapper {
        span.arrowUp {
          &::after {
            border-bottom-color: #009688;
          }
        }

        span.arrowDown {
          &::after {
            border-top-color: #009688;
          }
        }
      }
    }
  }

  .flatpickr-calendar.arrowTop {
    &:before {
      border-bottom-color: #1b2e4b;
    }
  }

  .flatpickr-calendar.arrowBottom {
    &::before {
      border-top-color: #1b2e4b;
    }
  }

  .numInputWrapper {
    span {
      height: 26%;

      &.arrowUp {
        top: 10px;

        &:after {
          border-bottom-color: #bfc9d4;
        }
      }

      .arrowDown {
        top: 34%;

        &:after {
          border-top-color: #bfc9d4;
        }
      }
    }
  }
}

/** App Settings**/
.purple {
  .app-settings {
    &.active {
      .sidbarchat {
        margin-right: 0px;
        border-left: 1px solid rgb(72 94 144 / 16%);
      }
    }

    .setting {
      background: #4361ee;
      color: #fff;

      h5 {
        color: #fff;
      }
    }

    .sidbarchat {
      background: #0e1726;

      .btn-close {
        color: #fff;
      }

      h5 {
        color: #fff;
      }

      p {
        color: #fff;

        &.text-muted {
          color: #6e84a3 !important;
        }
      }

      h6 {
        color: #fff;
      }

      .custom-control {
        .custom-control-input {
          &:checked {
            ~ .custom-control-label {
              background-color: #1e3a5c;
              border-color: #2c7be5;
              color: #2c7be5;
            }
          }
        }

        .custom-control-label {
          background-color: #152e4d;
          border: 1px solid #244166;
          color: #fff;

          &:hover {
            background-color: #1e3a5c;
            border-color: #2c7be5;
            color: #2c7be5;
          }
        }
      }
    }
  }
}

/*all pages*/
.purple {
  .panel {
    background: #0e1726;
    border-color: #0e1726;

    .panel-heading {
      h4 {
        color: #ebedf2;
      }
    }
  }

  .widget {
    background: #0e1726 !important;
    border: none;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);

    h5 {
      color: #e0e6ed;
    }

    /*dashboard 1*/
    &.widget-total-order {
      .widget-heading {
        .w-icon {
          background: #1abc9c;

          svg {
            color: #ddf5f0;
            fill: rgba(26, 188, 156, 0.49);
          }
        }
      }
    }

    &.widget-dailysales {
      background: #0e1726;
      border: none;

      .widget-heading {
        .w-icon {
          background: #e2a03f;

          svg {
            color: #0e1726;
          }
        }
      }
    }

    &.widget-summary {
      .widget-content {
        .summary-list:nth-child(1) .w-icon {
          background: #5c1ac3;

          svg {
            color: #dccff7;
          }
        }

        .summary-list:nth-child(2) .w-icon {
          background: #009688;

          svg {
            color: #e6ffbf;
          }
        }

        .summary-list:nth-child(3) .w-icon {
          background: #e2a03f;

          svg {
            color: #ffeccb;
          }
        }

        .w-summary-stats {
          .progress {
            box-shadow: none !important;

            .progress-bar.bg-gradient-secondary {
              background-image: linear-gradient(to right, #7579ff 0%, #b224ef 100%);
            }

            .progress-bar.bg-gradient-success {
              background-image: linear-gradient(to right, #3cba92 0%, #0ba360 100%);
            }

            .progress-bar.bg-gradient-warning {
              background-image: linear-gradient(to right, #f09819 0%, #ff5858 100%);
            }
          }
        }
      }
    }

    &.widget-recent-activity {
      .widget-content {
        .timeline-line .item-timeline .t-text p {
          color: #bfc9d4;
        }

        .tm-action-btn button {
          color: #888ea8;
        }
      }
    }

    &.widget-transaction .transactions-list {
      > div .t-name h4 {
        color: #bfc9d4;
      }

      .b-avatar span {
        color: #bfc9d4;
      }

      .icon-fill-info {
        background-color: rgb(33 150 243 / 0.388);
      }

      .icon-fill-secondary {
        background-color: rgb(128 93 202 / 0.388);
      }

      .icon-fill-danger {
        background-color: rgb(231 81 90 / 0.388);
      }

      .icon-fill-warning {
        background-color: rgb(226 160 63 / 0.388);
      }
    }

    &.widget-active-log {
      .widget-content {
        .mt-container {
          .timeline-line {
            .item-timeline {
              .t-dot {
                div {
                  &.t-secondary,
                  &.t-success,
                  &.t-primary,
                  &.t-warning,
                  &.t-dark {
                    box-shadow: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    .apexcharts-legend-text {
      color: #bfc9d4 !important;
    }

    .apexcharts-tooltip.apexcharts-theme-dark {
      background: #191e3a !important;
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
    }

    .apexcharts-tooltipapexcharts-theme-dark .apexcharts-tooltip-title {
      background: #191e3a !important;
      border-bottom: 1px solid #191e3a;
    }

    &.widget-revenue .chart-title {
      color: #bfc9d4;
    }

    &.widget-revenue .widget-content .apexcharts-canvas {
      transition: 0.5s;
    }

    &.widget-revenue .widget-content .apexcharts-canvas svg {
      transition: 0.5s;
    }

    &.widget-revenue .apexcharts-legend-marker {
      left: -5px !important;
    }

    &.widget-revenue .apexcharts-yaxis-title {
      font-weight: 600;
      fill: #bfc9d4;
    }

    &.widget-revenue .apexcharts-xaxis-title {
      font-weight: 600;
      fill: #bfc9d4;
    }

    &.widget-wallet-balance {
      .widget-heading {
        background-color: #191e3a;
        background-image: linear-gradient(-255deg, #154875 30%, #009688 120%);
      }

      .widget-amount {
        .w-a-info {
          background: #060818;
        }

        span {
          color: #fff;
        }

        p {
          background: #0e1726;
          color: #bfc9d4;
        }
      }

      .invoice-list [class*='info-detail-']:not(.info-sub) p span.bill-amount {
        color: #fff;
      }

      .invoice-list .inv-action a {
        &.view-details {
          color: #2196f3 !important;
          background: rgba(33, 150, 243, 0.169);
        }

        &.pay-now {
          color: #009688 !important;
          background: rgba(0, 150, 136, 0.188);
        }

        &:hover {
          box-shadow: 0px 5px 20px 0 rgb(0 0 0 / 10%);
        }
      }
    }

    &.widget-recent-orders,
    &.widget-top-selling-products {
      .table-responsive {
        margin-bottom: 0;
      }

      .table {
        > thead > tr > th {
          background: #191e3a;

          .th-content {
            color: #bfc9d4;
          }
        }

        > tbody > tr {
          > td .td-content {
            color: #888ea8;
          }

          &:hover {
            > td .td-content {
              color: #bfc9d4;
            }

            .td-content .badge {
              background: transparent;
              transform: none;
            }
          }
        }
      }
    }

    /*dashboard 2*/
    .apexcharts-tooltip.apexcharts-theme-dark {
      background: #191e3a !important;
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);

      .apexcharts-tooltip-title {
        background: #191e3a !important;
        border-bottom: 1px solid #0e1726;
      }
    }

    .apexcharts-xaxis text,
    .apexcharts-yaxis text {
      fill: #888ea8;
    }

    .apexcharts-legend-text {
      color: #bfc9d4 !important;
    }

    &.widget-visitor-by-browser {
      border: none;

      .widget-content {
        .browser-list:nth-child(1) .w-icon svg,
        .browser-list:nth-child(2) .w-icon svg,
        .browser-list:nth-child(3) .w-icon svg {
          color: #eaf1ff;
        }

        .browser-list:nth-child(1) .w-icon {
          background: #4361ee;
        }

        .browser-list:nth-child(2) .w-icon {
          background: #e7515a;
        }

        .browser-list:nth-child(3) .w-icon {
          background: #e2a03f;
        }

        .browser-list .w-browser-details .progress {
          box-shadow: none;

          .progress-bar {
            &.bg-gradient-primary {
              background: linear-gradient(to right, #0081ff 0%, #0045ff 100%);
            }

            &.bg-gradient-danger {
              background-image: linear-gradient(to right, #d09693 0%, #c71d6f 100%);
            }

            &.bg-gradient-warning {
              background-image: linear-gradient(to right, #f09819 0%, #ff5858 100%);
            }
          }

          .progress-bar:before {
            background: #0e1726 !important;
          }
        }
      }
    }

    &.widget-card-one {
      .widget-heading {
        border-bottom: 1px dashed #3b3f5c;

        .media .w-img img {
          border-color: #3b3f5c;
        }
      }

      .widget-content {
        .w-action,
        .w-action {
          .read-more a {
            &:hover {
              box-shadow: none;
            }

            svg {
              color: #009688;
            }
          }

          svg,
          span {
            color: #25d5e4;
            fill: transparent;
          }
        }
      }
    }

    &.widget-card-two {
      .widget-heading {
        border-bottom: 1px dashed #3b3f5c;

        .media .w-img img {
          border-color: #3b3f5c;
        }
      }

      .widget-content {
        .img-group img {
          border-color: #3b3f5c;
        }

        a {
          background: linear-gradient(229deg, #517281 0%, #3b5d70 27%, #4d5c82 72%, #5d647f 100%);
          box-shadow: none;
        }
      }
    }

    &.widget-card-three {
      .widget-heading {
        .task-info {
          .w-title {
            h5,
            span {
              color: #e0e6ed;
            }
          }
        }
      }

      .widget-content {
        .progress-data .progress-info {
          .task-count {
            svg {
              fill: transparent;
            }

            p {
              color: #888ea8;
            }
          }
        }

        .meta-info {
          .due-time p {
            background: #3b3f5c;
            color: #bfc9d4;
          }

          .avatar--group .avatar {
            background: initial;

            img {
              border-color: #3b3f5c;
            }

            .avatar-title {
              color: #3b3f5c;
            }
          }
        }
      }
    }

    &.widget-expenses {
      .widget-content {
        > p span {
          color: #e0e6ed;
        }

        .w-progress-stats {
          .progress {
            background-color: rgb(246 112 98 / 14%);
            box-shadow: none;

            .progress-bar.bg-gradient-secondary {
              background-image: linear-gradient(315deg, #fc5296 0%, #f67062 74%);
            }
          }

          .w-icon {
            color: #e95f2b;
          }
        }
      }
    }

    &.widget-total-balance {
      .widget-content {
        background-image: linear-gradient(to top, #00c6fb -227%, #005bea 100%);

        .account-box .info .inv-balance-info .inv-stats {
          background: #1a3283;
        }
      }
    }

    &.widget-active-log {
      .widget-heading {
        border-bottom: 1px dashed #3b3f5c;
      }

      .widget-content {
        .w-shadow-top,
        .w-shadow-bottom {
          background: linear-gradient(180deg, #0e1726 44%, #0e1726d1 73%, #2c303c00);
        }

        .mt-container .timeline-line .item-timeline:not(:last-child) .t-dot:after {
          border-color: #3b3f5c;
        }
      }
    }

    &.widget-unique-visitors {
      .widget-heading {
        border-bottom: 1px dashed #3b3f5c !important;
      }
    }

    &.widget-summary-1 {
      .widget-content {
        .summary-list {
          background-color: #1b2e4b !important;

          .summery-info {
            .w-icon svg {
              stroke-width: 1.5px;
              fill: transparent !important;
            }

            h6,
            h6 span {
              color: #e0e6ed;
            }
          }
        }
      }
    }
  }

  .widget-statistic {
    .widget-heading {
      .icon-fill-primary {
        color: #eaf1ff;
        background-color: #4361ee;
      }

      .icon-fill-danger {
        color: #fff5f5;
        background-color: #e7515a;
      }

      .icon-fill-success {
        background-color: #009688;
        color: #ddf5f0;
      }
    }
  }

  /*apps*/
  /*chat*/
  .chat-system {
    box-shadow: rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px;

    .user-list-box {
      border-color: #191e3a;
      background: #0e1726;

      .search svg {
        color: #e3e4eb;
      }

      input {
        background: #191e3a;
        border-color: #3b3f5c;
      }

      .people .person {
        border-color: #191e3a;

        &.active {
          background: transparent;
        }

        &:hover .user-info .f-body .meta-info,
        &.active .user-info .f-body .meta-info {
          .user-name,
          .user-meta-time {
            color: #25d5e4 !important;
          }
        }

        .user-info {
          .f-head img {
            border-color: #3b3f5c;
            box-shadow: none;
          }

          .f-body .meta-info .user-name {
            color: #bfc9d4;
          }
        }
      }
    }

    .chat-box {
      .chat-not-selected p {
        background: #0e1726;
        color: #e0e6ed;
        box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%);
      }

      .chat-box-inner {
        .chat-meta-user {
          &.chat-active {
            background-color: #0e1726;
            box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%);

            .chat-action-btn {
              svg:hover {
                color: #25d5e4;
              }

              .dropdown-menu {
                background: #060818;
                box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%);

                a {
                  color: #888ea8 !important;

                  &:hover {
                    background-color: #191e3a !important;

                    svg {
                      color: #25d5e4;
                    }
                  }
                }
              }
            }
          }

          .current-chat-user-name span .name {
            color: #bfc9d4;
          }
        }
      }

      .conversation-start span {
        background: #0e1726;
        color: #e0e6ed;
      }

      .bubble.you {
        color: #e0e6ed;
        background-color: #0e1726;
        box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%);

        &:before {
          background-color: #0e1726;
        }
      }

      .bubble.me {
        box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%);
      }

      .chat-footer {
        &.chat-active {
          background: #0e1726;
          box-shadow: 0 -6px 10px 0 rgb(0 0 0 / 14%), 0 -1px 18px 0 rgb(0 0 0 / 12%), 0 -3px 5px -1px rgb(0 0 0 / 20%);
        }

        .chat-input {
          svg {
            fill: rgba(0, 23, 55, 0.08);
          }

          input {
            color: #25d5e4;
            border-color: #3b3f5c;
            background: #0e1726;
          }
        }
      }
    }
  }

  /*mailbox*/
  .mailbox .mail-box-container {
    background-color: #0e1726;
    box-shadow: 5px 5px 14px #02030a, -5px -5px 14px #0a0d26;

    .g-dot-primary {
      &:before {
        border: 2px solid #2196f3 !important;
        background: rgba(33, 150, 243, 0.47058823529411764);
      }

      &.active:before {
        background: #2196f3;
      }
    }

    .g-dot-warning {
      &:before {
        border: 2px solid #e2a03f !important;
        background: rgba(226, 160, 63, 0.5098039215686274);
      }

      &.active:before {
        background: #e2a03f;
      }
    }

    .g-dot-success {
      &:before {
        border: 2px solid #009688 !important;
        background: rgba(0, 150, 136, 0.34901960784313724);
      }

      &.active:before {
        background: #009688;
      }
    }

    .g-dot-danger {
      &:before {
        border: 2px solid #e7515a !important;
        background: rgba(231, 81, 90, 0.4392156862745098);
      }

      &.active:before {
        background: #e7515a;
      }
    }

    .tab-title {
      border-color: #191e3a;

      #btn-compose-mail {
        background: #009688;
        color: #191e3a !important;
      }

      .nav-pills {
        &:nth-child(1) .nav-item:first-child a.nav-link {
          border-color: #191e3a;
        }

        a.nav-link {
          color: #888ea8;
          border-color: #191e3a;

          &.active {
            color: #25d5e4;

            svg {
              color: #25d5e4;
            }
          }

          .mail-badge {
            background: #009688;
            color: #191e3a;
          }
        }

        &.group-list .nav-item a {
          color: #888ea8;
        }
      }

      .group-section {
        color: #009688;

        svg {
          color: #009688;
          fill: transparent;
        }
      }
    }

    .mailbox-inbox {
      background: #060818;

      .search {
        border-bottom: 1px solid #191e3a;
        background: #0e1726;
      }

      .search input {
        background-color: #0e1726;
        color: #e0e6ed;
        box-shadow: none;
        border-left: 1px solid #060818;
      }

      .new-control {
        .custom-control-label {
          color: #e0e6ed;

          &:before {
            background-color: #515365 !important;
          }
        }

        .custom-control-input:checked ~ .custom-control-label::before {
          background: #4361ee !important;
        }
      }

      .action-center {
        border-bottom: 1px solid #0e1726;

        svg {
          fill: transparent;
        }

        .dropdown-menu {
          &.d-icon-menu {
            box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%);
            border-color: #1b2e4b;

            a {
              color: #888ea8;

              &:hover {
                color: #2196f3;
              }
            }
          }

          a.dropdown-item:hover svg {
            color: #2196f3;
          }
        }
      }

      .mail-item {
        div.mail-item-heading {
          background: #0e1726;
          border-color: #0e1726;

          .mail-item-inner .f-body .mail-title {
            color: #bfc9d4;
          }

          .attachments span {
            color: #0e1726;
            background: #009688;
            border-color: #009688;
          }

          &:hover {
            background: #1b2e4b;
          }
        }

        &.unread div.mail-item-heading .mail-item-inner {
          .f-body .user-email {
            color: #009688;
          }

          .mail-content-excerpt {
            color: #607d8b;
          }
        }
      }
    }

    .content-box {
      background-color: #0e1726;

      .msg-close {
        background: #0e1726;
        border-color: #191e3a;
        box-shadow: none;

        svg.close-message {
          color: #bfc9d4;
        }

        .mail-title {
          color: #25d5e4;
        }
      }

      .mail-content-container {
        .user-info img {
          border-color: #0e1726;
        }

        p {
          color: #bfc9d4;
        }

        .action-btns svg {
          color: #e3e4eb;
        }

        p.mail-content {
          border-color: #191e3a;
        }

        .attachments {
          .attachments-section-title {
            color: #888ea8;
            border-color: #191e3a;
          }

          svg {
            color: #009688;
          }
        }
      }
    }

    @media screen and (max-width: 991px) {
      .tab-title {
        background: #0e1726;
      }
    }
  }

  .mailbox-popup.modal-content {
    .modal-body {
      svg.close {
        background: #1b2e4b;
        color: #009688;
      }

      .compose-box {
        background-color: transparent;

        .compose-content {
          form {
            svg {
              color: #009688;
            }

            .mail-form {
              p {
                color: #888ea8;
              }

              select {
                color: #009688;
                background: #1b2e4b;
                border-color: #3b3f5c;
                box-shadow: none !important;
              }

              .form-select {
                background: #1b2e4b url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23009688' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
              }
            }
          }

          .form-control-file {
            color: #bfc9d4;

            &::-webkit-file-upload-button {
              background-color: #009688;
            }

            &::-ms-file-upload-button {
              background-color: #009688;
            }

            &.form-control-file-rounded::-webkit-file-upload-button {
              background-color: #009688;
            }
          }

          .ql-toolbar {
            &.ql-snow {
              border-color: #1b2e4b;

              .ql-picker-label {
                color: #009688;
              }

              .ql-stroke {
                stroke: #009688;
              }

              .ql-fill,
              .ql-stroke.ql-fill {
                fill: #009688;
              }

              + .ql-container.ql-snow {
                border-color: #1b2e4b !important;
              }
            }
          }

          .ql-editor.ql-blank::before {
            color: #bfc9d4;
          }
        }
      }
    }

    .modal-footer {
      #btn-reply-save,
      #btn-save,
      #btn-fwd-save {
        border-color: #009688 !important;
      }

      .btn[data-dismiss='modal'] {
        background-color: #1b2e4b !important;
        border-color: #1b2e4b !important;
        color: #bfc9d4 !important;
      }
    }
  }

  /*todolist*/
  .todo-list .mail-box-container {
    background: #0e1726;
    box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%);

    .tab-title {
      border-color: #191e3a;
      background: #0e1726;

      h5 {
        color: #888ea8;
      }

      #addTask {
        background-color: #61b6cd;
        color: #191e3a;
      }

      svg.feather-clipboard {
        color: #0e1726;
        fill: #61b6cd;
      }

      .nav-pills {
        .nav-link {
          color: #888ea8;

          svg {
            color: #888ea8;
          }

          &:hover svg,
          &.active svg {
            fill: rgba(136, 142, 168, 0.25882352941176473);
          }

          &.active {
            color: #191e3a;
            background: #61b6cd;

            svg,
            .badge {
              color: #191e3a !important;
            }
          }

          &#all-list .badge {
            color: #607d8b;
            border-color: #607d8b;
          }

          &#todo-task-done .badge {
            border-color: #2196f3;
          }

          &#todo-task-important .badge {
            color: #e2a03f;
            border-color: #e2a03f;
          }
        }
      }
    }

    .todo-inbox {
      .search {
        input {
          border-color: #191e3a;
          box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%);
        }
      }

      .todo-item {
        &:not(:last-child) {
          border-color: #191e3a;
        }

        .todo-item-inner {
          .todo-heading {
            color: #bfc9d4;
          }

          p {
            &.meta-date {
              color: #888ea8;
            }

            &.todo-text {
              color: #e3e4eb;
            }
          }

          .new-control {
            .custom-control-label {
              color: #e0e6ed;

              &:before {
                background-color: #515365 !important;
              }
            }

            .custom-control-input:checked ~ .custom-control-label::before {
              background: #4361ee !important;
            }
          }

          .action-dropdown .show .dropdown-toggle svg {
            color: #bfc9d4;
          }
        }
      }

      .mail-menu {
        background: transparent;
      }
    }

    @media (max-width: 991px) {
      .tab-title {
        position: absolute;
      }

      .todo-inbox .search {
        border-color: #191e3a !important;

        input,
        .mail-menu {
          border-bottom: none !important;
        }
      }
    }
  }

  .todolist-popup.modal-content {
    background: #0e1726;

    .modal-body {
      p {
        color: #ebedf2;
      }

      .compose-box {
        background-color: transparent;

        .compose-content {
          form {
            svg {
              color: #009688;
            }

            .mail-form {
              p {
                color: #888ea8;
              }

              select {
                color: #009688;
                background: #1b2e4b;
                border-color: #3b3f5c;
                box-shadow: none !important;
              }
            }
          }

          .form-control-file {
            color: #bfc9d4;

            &::-webkit-file-upload-button {
              background-color: #009688;
            }

            &::-ms-file-upload-button {
              background-color: #009688;
            }

            &.form-control-file-rounded::-webkit-file-upload-button {
              background-color: #009688;
            }
          }

          .ql-toolbar {
            &.ql-snow {
              border-color: #1b2e4b;

              .ql-picker-label {
                color: #009688;
              }

              .ql-stroke {
                stroke: #009688;
              }

              .ql-fill,
              .ql-stroke.ql-fill {
                fill: #009688;
              }

              + .ql-container.ql-snow {
                border-color: #1b2e4b !important;
              }
            }
          }

          .ql-editor.ql-blank::before {
            color: #bfc9d4;
          }

          h5 {
            border-color: #191e3a !important;
            color: #bfc9d4 !important;
          }
        }
      }
    }

    .modal-footer {
      border-color: #191e3a !important;

      #btn-reply-save,
      #btn-save,
      #btn-fwd-save {
        border-color: #009688 !important;
      }

      .btn[data-dismiss='modal'] {
        background-color: #1b2e4b !important;
        border-color: #1b2e4b !important;
        color: #bfc9d4 !important;
      }

      .btn.add-tsk {
        background-color: #009688 !important;
        border-color: #009688 !important;
      }
    }
  }

  /*app-notes*/
  .app-notes {
    .g-dot-primary {
      &:before {
        background: rgba(67, 98, 238, 0.53) !important;
        border: 1px solid #4361ee !important;
      }
    }

    .g-dot-warning {
      &:before {
        background: rgba(226, 160, 63, 0.53) !important;
        border: 1px solid #e2a03f !important;
      }
    }

    .g-dot-success {
      &:before {
        background: rgba(92, 26, 195, 0.53) !important;
        border: 1px solid #805dca !important;
      }
    }

    .g-dot-danger {
      &:before {
        background: rgba(231, 81, 90, 0.53) !important;
        border: 1px solid #e7515a !important;
      }
    }

    .tab-title {
      .nav-pills {
        .nav-link {
          color: #888ea8;

          &.active {
            background-color: #191e3a;
          }
        }
      }
    }

    .note-container.note-grid {
      .note-item {
        .note-inner-content {
          background: #0e1726;
          border: none;
          border-bottom: 3px solid #506690;

          .note-title {
            color: #61b6cd;
          }

          .meta-time {
            color: #888ea8;
          }

          .note-description {
            color: #bfc9d4;
          }

          .note-footer {
            .tags {
              .g-dot-personal {
                background: rgba(67, 98, 238, 0.53);
                border-color: #4361ee;
              }

              .g-dot-work {
                background: rgba(226, 160, 63, 0.53);
                border-color: #e2a03f;
              }

              .g-dot-social {
                background: rgba(92, 26, 195, 0.53);
                border-color: #805dca;
              }

              .g-dot-important {
                background: rgba(231, 81, 90, 0.53);
                border-color: #e7515a;
              }
            }

            .nav-link .feather-more-vertical {
              color: #607d8b;

              &:hover {
                color: #e3e4eb;
              }
            }

            .tags-selector .dropdown-menu a {
              color: #bfc9d4;

              &.g-dot-personal:before {
                background: rgba(67, 98, 238, 0.53);
                border-color: #4361ee;
              }

              &.g-dot-work:before {
                background: rgba(226, 160, 63, 0.53);
                border-color: #e2a03f;
              }

              &.g-dot-social:before {
                background: rgba(92, 26, 195, 0.53);
                border-color: #805dca;
              }

              &.g-dot-important:before {
                background: rgba(231, 81, 90, 0.53);
                border-color: #e7515a;
              }
            }
          }

          .note-action {
            svg {
              fill: transparent;
            }

            .fav-note:hover {
              color: #e2a03f;
            }
          }
        }

        &.note-personal .note-inner-content {
          border-bottom: 3px solid #4461ed;
        }

        &.note-work .note-inner-content {
          border-bottom: 3px solid #e2a03f;
        }

        &.note-social .note-inner-content {
          border-bottom: 3px solid #805dca;
        }

        &.note-important .note-inner-content {
          border-bottom: 3px solid #e7515a;
        }

        &.note-fav {
          .note-inner-content .note-action .fav-note {
            fill: rgb(255 187 68 / 46%);
            color: #ffbb44;
          }
        }
      }
    }

    @media (max-width: 991px) {
      .tab-title.note-menu-show {
        background: #0e1726;
        height: 100%;
        border-radius: 0;
      }
    }
  }

  /*scrumboard*/
  .scrumboard {
    .connect-sorting {
      background: #0e1726;
      box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%);
      border-color: transparent;

      .task-container-header {
        h6 {
          color: #bfc9d4;
        }
      }

      .card {
        background: #191e3a;
        box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%);

        .task-header {
          h4 {
            color: #bfc9d4;
          }
        }

        .card-body {
          .task-body {
            .task-content {
              p {
                color: #bfc9d4;
              }
            }

            svg.feather-edit-2:hover {
              color: #009688 !important;
              fill: rgba(0, 150, 136, 0.41) !important;
            }

            .task-bottom div.tb-section-1 span:hover {
              color: #009688;

              svg {
                color: #009688;
                fill: rgba(27, 85, 226, 0.23921568627450981);
              }
            }
          }
        }

        &.ui-state-highlight {
          border-color: #009688 !important;
          background-color: rgba(0, 150, 136, 0.1) !important;

          &:before {
            color: #009688;
          }
        }
      }

      .add-s-task .addTask {
        color: #bfc9d4;

        &:hover {
          color: #ffffff;
        }
      }
    }
  }

  #addListModal .modal-content {
    .compose-box .list-title {
      background: #0e1726;

      svg {
        color: #009688;
      }
    }
  }

  #addTaskModal {
    .compose-box {
      background: #0e1726;
    }

    .modal-content {
      .card {
        background: #0e1726;
        border-color: #0e1726;
      }

      .modal-body svg {
        color: #009688;
      }
    }

    .ql-toolbar.ql-snow {
      border-color: #1b2e4b;
    }
  }

  /*contacts*/
  .app-contacts {
    .searchable-container {
      .filtered-list-search .form-control {
        border: 1px solid #1b2e4b;
        color: #009688;
        background: #1b2e4b;

        &:focus {
          box-shadow: none;
          border-color: #3b3f5c;
          color: #25d5e4;
          background-color: #1b2e4b;
        }
      }

      #btn-add-contact {
        background: #1b2e4b;
        color: #009688 !important;
        fill: rgba(27, 85, 226, 0.23921568627450981) !important;
        box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%);
        border: none;
      }

      .switch {
        .view-list,
        .view-grid {
          border: none;
          box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%);
          background: #1b2e4b;
          fill: rgba(0, 23, 55, 0.08);
          color: #888ea8;

          &:hover {
            fill: rgba(0, 23, 55, 0.08);
            color: #888ea8;
          }
        }

        .active-view {
          color: #009688 !important;
          fill: rgba(27, 85, 226, 0.23921568627450981) !important;
        }
      }

      .searchable-items.list {
        .items {
          .item-content {
            border: none;
            background: #0e1726;
            box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%);

            .action-btn .delete-multiple {
              color: #bfc9d4;
              fill: rgba(27, 85, 226, 0.23921568627450981);

              &:hover {
                color: #009688;
              }
            }

            .action-btn {
              .edit,
              .delete {
                color: #bfc9d4;

                &:hover {
                  color: #009688;
                }
              }
            }

            .user-meta-info {
              .user-name {
                color: #bfc9d4;
              }
            }

            &:hover .user-meta-info .user-name,
            &:hover .user-email p,
            &:hover .user-location p,
            &:hover .user-phone p {
              color: #2196f3;
            }
          }

          &.items-header-section h4 {
            color: #bfc9d4;
          }

          .custom-control-label::before {
            background-color: #515365 !important;
          }

          .custom-control-input:checked ~ .custom-control-label::before {
            background-color: #4361ee !important;
          }
        }
      }

      .searchable-items.grid {
        .items {
          .item-content {
            background-color: #0e1726;
            padding: 13px 18px;
            border: 1px solid #0e1726;
            box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%);

            .user-name {
              color: #009688;
            }

            .info-title {
              color: #888ea8;
            }

            .usr-email-addr,
            .usr-location,
            .usr-ph-no {
              color: #bfc9d4;
            }

            .action-btn .edit,
            .action-btn .delete {
              color: #bfc9d4;

              &:hover {
                color: #009688;
              }
            }
          }
        }
      }
    }
  }

  /*invoice*/
  /*invoice-list*/
  .apps-invoice-list {
    .custom-table {
      .table-header {
        span {
          color: #888ea8;

          .form-select {
            background: #0e1726 url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 24 24' fill='none' stroke='%23d3d3d3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e") right 0.25rem center/15px 54px no-repeat;
            color: #f1f2f3;
            font-size: 12px;

            &:focus {
              box-shadow: none !important;
            }
          }
        }

        .header-search {
          .form-control {
            background-color: #0e1726;
          }
        }
      }

      .table {
        th {
          background-color: #1b2e4b;
          color: #bfc9d4;
          border-top: 1px solid #191e3a;
        }

        td {
          border-top: 1px solid #191e3a;
        }

        tbody {
          tr {
            &:hover {
              background: none !important;
            }
          }
        }

        .custom-control-label::before {
          background-color: #515365 !important;
        }

        .custom-control-input:checked ~ .custom-control-label::before {
          background-color: #4361ee !important;
        }

        .custom-control-input:checked ~ .custom-control-label::after {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e") !important;
        }

        .inv-number {
          color: #61b6cd;
        }

        .admin-name {
          color: #d3d3d3;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.14px;
          overflow: hidden;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-wrap: break-word;
        }

        .inv-email,
        .inv-date,
        .inv-amount {
          color: #888ea8;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.14px;
          margin-bottom: 0;
          margin-top: 0;
          overflow: hidden;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-wrap: break-word;

          svg {
            fill: rgba(127, 93, 202, 0.098);
          }
        }

        .inv-status.badge-success {
          background-color: rgb(26, 188, 156, 0.1);
          color: #1abc9c;
        }

        .inv-status.badge-danger {
          color: #e7515a;
          background-color: rgb(231, 81, 90, 0.09);
        }

        .actions {
          .dropdown {
            a.dropdown-item.action-edit {
              background: rgb(33, 150, 243, 0.12) !important;
            }

            a.dropdown-item.action-delete {
              background: rgb(231, 81, 90, 0.12) !important;
            }
          }
        }
      }

      .table-footer {
        .dataTables_info {
          white-space: normal;
          color: #61b6cd;
          font-weight: 600;
          border: 1px solid #1b2e4b;
          display: inline-block;
          padding: 10px 16px;
          border-radius: 6px;
          font-size: 13px;
        }

        .pagination {
          .page-item {
            background-color: rgba(0, 23, 55, 0.08);
          }

          .page-item.disabled {
            background-color: #1b2e4b;
            color: #e3e4eb;
          }

          .page-link {
            color: #888ea8;
          }

          .page-item.active {
            .page-link {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .table.b-table > thead > tr > [aria-sort='ascending'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='%23bfc9d4' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='%23bfc9d4' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
  }

  .table.b-table > thead > tr > [aria-sort='descending'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='%23bfc9d4' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='%23bfc9d4' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
  }

  .table.b-table > thead > tr > [aria-sort='none'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='%23bfc9d4' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
  }

  /*.invoice-preview*/
  .apps-invoice {
    .invoice-inbox,
    .invoice-actions-btn {
      background-color: #0e1726;
      border: 1px solid #0e1726;
    }

    .content-section {
      .inv--head-section {
        border: 1px solid #191e3a;

        h3.in-heading {
          color: #e0e6ed;
        }
      }

      .inv--detail-section {
        .inv-email-address,
        .inv-street-addr {
          color: #888ea8;
        }

        .inv-list-number {
          .inv-number {
            color: #61b6cd;
          }
        }

        .inv-customer-name,
        .inv-title {
          color: #61b6cd;
        }

        .inv-due-date,
        .inv-created-date {
          color: #888ea8;
        }
      }

      .inv--payment-info {
        p {
          color: #888ea8;
        }
      }

      .inv--product-table-section {
        table {
          background-color: #0e1726;
        }

        tbody {
          tr:nth-of-type(even) {
            td {
              background-color: rgba(3, 3, 5, 0.122) !important;
            }
          }

          tr {
            &:hover {
              background-color: rgba(3, 3, 5, 0) !important;
            }
          }
        }

        th {
          border-top: 1px solid #191e3a;
          border-bottom: 1px solid #191e3a;
          color: #bfc9d4 !important;
        }

        td {
          color: #888ea8;
        }
      }

      .inv--total-amounts {
        border-bottom: 1px solid #191e3a;
      }
    }
  }

  /*invoice-add or invoice-edit */
  .apps-invoice-add {
    .invoice-detail-body,
    .invoice-actions,
    .invoice-actions-btn {
      background-color: #0e1726;
      border: 1px solid #0e1726;
    }

    .invoice-content {
      .profile-preview.upload-preview {
        border: 1px solid #1b2e4b;
        background: #1b2e4b;
        color: #fff;
      }

      .invoice-detail-header {
        .invoice-address-company-fields,
        .invoice-address-client-fields {
          label {
            color: #888ea8;
          }
        }
      }

      .invoice-detail-terms {
        border-top: 1px solid #191e3a;

        label {
          color: #888ea8;
        }
      }

      .invoice-detail-items {
        background: #0e1726;

        .table {
          &.table-bordered {
            border-color: #191e3a !important;
          }

          th {
            border-color: #191e3a !important;
            color: #888ea8 !important;
          }
        }

        tbody {
          tr {
            background: #0e1726;
          }

          ul {
            li {
              svg {
                color: #888ea8;
              }
            }
          }

          td.tax {
            .custom-checkbox {
              .custom-control-label::before {
                background-color: #515365 !important;
              }

              .custom-control-input:checked ~ .custom-control-label::before {
                background-color: #4361ee !important;
              }

              .custom-control-input:checked ~ .custom-control-label::after {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e") !important;
              }
            }
          }
        }

        .btn-secondary {
          color: #fff !important;
          background-color: #3b3f5c;
          border-color: #3b3f5c;
          box-shadow: 0 10px 20px -10px rgb(59 63 92 / 59%);
        }
      }

      .editable-amount {
        color: #bfc9d4;
      }

      .invoice-detail-total {
        label {
          color: #888ea8;
        }
      }

      .total-amount,
      .subtotal-amount,
      .tax-amount,
      .balance-due-amount {
        color: #888ea8;
      }

      .invoice-totals-row.invoice-summary-balance-due {
        border-top: 1px solid #191e3a;
      }

      .invoice-detail-note {
        border-top: 1px solid #191e3a;

        label {
          color: #888ea8;
        }
      }

      .invoice-logo {
        .profile-preview {
          padding: 7px;
          border: 1px solid #1b2e4b !important;
          background-color: #1b2e4b;
        }
      }
    }

    .invoice-actions {
      .invoice-action-currency {
        label {
          color: #e0e6ed;
          border-bottom: 1px solid #191e3a;
        }

        .selectable-dropdown {
          a.dropdown-toggle {
            background-color: #1b2e4b;
            color: #bfc9d4;
            border: 1px solid #1b2e4b;

            .selectable-arrow {
              background: #1b2e4b;
            }
          }
        }
      }

      .invoice-action-tax,
      .invoice-action-discount {
        h5 {
          color: #e0e6ed;
          border-bottom: 1px solid #191e3a;
        }

        .input-rate {
          background-color: #1b2e4b;
          border: 1px solid #1b2e4b;
          color: #bfc9d4;
        }

        .invoice-action-tax-fields,
        .invoice-action-discount-fields {
          label {
            color: #bfc9d4;
          }

          .selectable-dropdown {
            a.dropdown-toggle {
              background-color: #1b2e4b;
              color: #bfc9d4;
              border: 1px solid #1b2e4b;

              .selectable-arrow {
                background: #1b2e4b;
              }
            }
          }
        }
      }
    }
  }

  /*calendar*/
  .apps-calendar {
    .panel-body {
      padding: 20px;

      .calendar-upper-section {
        p {
          color: #bfc9d4;
        }
      }

      .fc-theme-standard {
        .fc-button.fc-next-button,
        .fc-button.fc-prev-button {
          .fc-icon {
            color: #bfc9d4;
          }

          &:hover {
            background-color: #515365 !important;
          }
        }

        .fc-popover {
          border: 1px solid #0e1726;
          background: #1b2e4b;
        }

        .fc-popover-header {
          background: rgb(27 46 75);
        }
      }

      .fc-today-button {
        background-color: #1b2e4b !important;
        border: 1px solid #1b2e4b !important;
        color: #fff !important;
        opacity: 1;
      }

      .fc-toolbar-title {
        margin: 0;
        font-size: 18px;
        color: #bfc9d4;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 2px;
      }

      .fc-scrollgrid,
      .fc-theme-standard td,
      .fc-theme-standard th {
        border-color: #3b3f5c;
      }

      .fc-button-group {
        .fc-button-primary {
          letter-spacing: 1px !important;
          color: #e0e6ed !important;
        }

        .fc-button-active {
          background-color: #607d8b !important;
        }

        .fc-button {
          &:hover {
            background-color: #515365 !important;
          }
        }
      }

      .fc-daygrid-day.fc-day-today {
        background-color: #0e1726;
      }

      .fc-daygrid-event.bg-danger,
      .fc-timegrid-event.bg-danger {
        background-color: rgba(231, 80, 90, 0.38) !important;
        border: none !important;

        &:hover {
          background-color: inherit !important;
        }
      }

      .fc-daygrid-event.bg-primary,
      .fc-timegrid-event.bg-primary {
        background-color: rgba(27, 85, 226, 0.27) !important;
        border: none !important;

        &:hover {
          background-color: inherit !important;
        }
      }

      .fc-daygrid-event.bg-warning,
      .fc-timegrid-event.bg-warning {
        background-color: rgba(226, 160, 63, 0.42) !important;
        border: none !important;

        &:hover {
          background-color: inherit !important;
        }
      }

      .fc-daygrid-event.bg-success,
      .fc-timegrid-event.bg-success {
        background-color: rgba(0, 150, 136, 0.28) !important;
        border: none !important;

        &:hover {
          background-color: inherit !important;
        }
      }

      .fc-event-main,
      .fc-event-main-frame {
        color: #000000 !important;
      }

      .calendar-tlp,
      .bg-dark {
        background-color: #060818 !important;
        border-color: #060818 !important;
        color: #bfc9d4 !important;
        font-size: 13px;
        letter-spacing: 1px;
      }
    }
  }
}

/*collapsible-vertical*/
.purple {
  .collapsible-vertical {
    .sidebar-theme {
      background-color: #191e3a;
    }

    .sidebar-wrapper {
      #sidebar {
        ul {
          &.menu-categories {
            li {
              &.menu {
                &.active {
                  a {
                    background: transparent;

                    span {
                      color: #61b6cd;
                    }

                    svg {
                      color: #61b6cd;
                    }
                  }
                }

                > .dropdown-toggle {
                  &[aria-expanded='true']:not([data-active='true']) {
                    color: #61b6cd;

                    svg {
                      color: #61b6cd;
                    }
                  }

                  &:hover {
                    color: #61b6cd;

                    svg {
                      color: #61b6cd;
                    }
                  }
                }
              }
            }

            ul {
              &.submenu {
                > li {
                  &.active {
                    a {
                      color: #fff;
                    }

                    &::before {
                      content: '';
                      position: absolute;
                      background-color: transparent;
                      width: 15px;
                      height: 40px;
                      width: 100%;
                      margin: 0 21px;
                      border-radius: 6px;
                      width: 84%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    #sidebar {
      ul {
        &.menu-categories {
          li {
            &.menu {
              > .dropdown-toggle {
                color: #888ea8;

                svg {
                  color: #888ea8;
                }

                &:hover {
                  color: #61b6cd;
                }

                &.dropdown-toggle:not([data-active='true']):not([aria-expanded='true']):hover {
                  color: #61b6cd;
                }
              }
            }
          }

          ul {
            &.submenu {
              > li {
                a {
                  color: #bfc9d4;

                  &.not-collapsed {
                    color: #009688;
                  }

                  &:hover {
                    color: #e3e4eb;
                  }
                }

                ul {
                  &.sub-submenu {
                    > li {
                      &.active {
                        a {
                          color: #009688;
                        }
                      }

                      a {
                        color: #bfc9d4;

                        &:hover {
                          color: #009688;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .sidebar-closed {
      > .sidebar-wrapper {
        background-color: #191e3a;
      }

      .sidebar-wrapper {
        -webkit-box-shadow: 18px 20px 10.3px -23px rgb(0, 0, 0, 0.15), -18px 20px 26px -23px rgba(0, 0, 0, 0.1), -17px 20px 53px -23px rgba(0, 0, 0, 0.124),
        -19px 20px 109.1px -23px rgba(0, 0, 0, 0.152), -17px 20px 299px -23px rgba(0, 0, 0, 0.22);
        -moz-box-shadow: 18px 20px 10.3px -23px rgb(0, 0, 0, 0.15), -18px 20px 26px -23px rgba(0, 0, 0, 0.1), -17px 20px 53px -23px rgba(0, 0, 0, 0.124),
        -19px 20px 109.1px -23px rgba(0, 0, 0, 0.152), -17px 20px 299px -23px rgba(0, 0, 0, 0.22);
        box-shadow: 18px 20px 10.3px -23px rgb(0, 0, 0, 0.15), -18px 20px 26px -23px rgba(0, 0, 0, 0.1), -17px 20px 53px -23px rgba(0, 0, 0, 0.124),
        -19px 20px 109.1px -23px rgba(0, 0, 0, 0.152), -17px 20px 299px -23px rgba(0, 0, 0, 0.22);
      }
    }
  }
}

/*Topbar menu*/
@media (min-width: 992px) {
  /*
    ====================
        Layout Topbar
    ====================
    */
  .purple {
    .horizontal {
      .topbar-nav.header {
        background: #191e3a;
        box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%);

        nav.topbar {
          ul.menu-categories {
            li.menu {
              > a {
                svg.feather.feather-chevron-down {
                  color: #888ea8;
                  align-self: center;
                }
              }

              &:hover {
                a {
                  > div {
                    span {
                      color: #61b6cd;
                    }

                    svg {
                      &:not(.feather-chevron-down) {
                        color: #61b6cd;
                      }
                    }
                  }
                }

                > a {
                  svg.feather.feather-chevron-down {
                    color: #61b6cd;
                  }
                }
              }

              a {
                > div {
                  svg {
                    &:not(.feather-chevron-down) {
                      color: #d3d3d3;
                    }
                  }

                  span {
                    color: #d3d3d3;
                  }
                }
              }

              .submenu {
                background-color: #1b2e4b;
                color: #0e1726;
                box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15);
                border: 1px solid #1b2e4b;

                a {
                  > div {
                    svg {
                      &:not(.feather-chevron-down) {
                        color: #ffffff;
                      }
                    }

                    span {
                      color: #ffffff;
                    }
                  }
                }

                &:hover {
                  a {
                    > div {
                      span {
                        color: #ffffff;
                      }

                      svg {
                        &:not(.feather-chevron-down) {
                          color: #ffffff;
                        }
                      }
                    }
                  }

                  > a {
                    svg.feather.feather-chevron-down {
                      color: #9acccb;
                    }
                  }
                }

                li {
                  a {
                    color: #ffffff !important;
                  }

                  &:hover {
                    a {
                      color: #61b6cd !important;
                      background: transparent;

                      div {
                        svg {
                          color: #61b6cd !important;
                        }

                        span {
                          color: #61b6cd !important;
                        }
                      }
                    }
                  }
                }

                li.active {
                  a {
                    color: #61b6cd;
                  }
                }

                .sub-sub-submenu-list {
                  .sub-submenu {
                    background-color: #1b2e4b;
                    color: #888ea8;
                    box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%);
                    border: 1px solid #1b2e4b;
                  }
                }

                li.sub-sub-submenu-list {
                  .sub-submenu {
                    li {
                      a {
                        background: transparent;
                        color: #888ea8;
                      }

                      &:hover {
                        a {
                          background: #191e3a;
                          color: #d3d3d3;
                        }
                      }
                    }

                    li.active {
                      a {
                        color: #d3d3d3;
                      }
                    }
                  }
                }
              }
            }

            li.menu.active {
              > a {
                svg.feather.feather-chevron-down {
                  color: #61b6cd;
                }

                > div {
                  svg {
                    &:not(.feather-chevron-down) {
                      color: #61b6cd;
                    }
                  }

                  svg.feather.feather-chevron-down {
                    color: #e0e6ed;
                  }

                  span {
                    color: #61b6cd;
                  }
                }
              }
            }
          }
        }
      }

      .topbar-nav.header.fixed-top {
        background-color: #0e1726;

        nav.topbar {
          ul.menu-categories {
            li.menu {
              a {
                > div {
                  svg {
                    &:not(.feather-chevron-down) {
                      color: #fff;
                    }
                  }

                  span {
                    color: #fff;
                  }

                  svg.feather.feather-chevron-down {
                    color: #fff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
